import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Arbre béhémoth`,

    types: [Monsters.Type.PLANT],
    health: `290`,
    armor: `10`,
    speed: `4 m/s`,
    damage: `50 + 5x1d10`,
    hitClose: `80 %`,
    hitMedium: `80 %`,
    initiative: `+2`,
    actions: `+1`,
    show: [
      `Effraie les personnes proches`,
    ],
    abilities: [
      Monsters.Skills.Tremblement,
      `Spores toxiques : libère un nuage qui applique Poison III aux personnes proches pendant 6 tours, type H et M-`
    ],
    passives: [Monsters.Skills.Piétinement]
  },
  {
    name: `Clampignon`,

    types: [Monsters.Type.PLANT],
    health: `30`,
    speed: `2 m/s`,
    damage: `6 + 2x1d6`,
    hitClose: `80 %`,
    initiative: `+2`,
    attacks: [
      `Applique Poison V pour 6 tours, catégorie D+`
    ],
    abilities: [
      `Nuage de poison : libère un vaste nuage qui applique Poison V pour 10 tours, type D+`,
      `Groupe : se trouve généralement avec un groupe de 4 à 9 Mycognons [3 + 1d6]`,
      Monsters.Skills.Chef('Mycognons', 6),
    ],
    death: [
      `Spores empoisonnées : des spores transparentes sont libérées qui applique poison H à chaque tour`,
    ]
  },
  {
    name: `Neothelid`,

    types: [Monsters.Type.PLANT],
    health: `215`,
    armor: `2`,
    damage: `32 + 3x1d8`,
    hitClose: `80 %`,
    hitMedium: `60 %`,
    initiative: `+6`,
    actions: `+1`,
    abilities: [
      `Jet de poison : 80 % de chances d'appliquer Poison V à la cible pour 6 tours, type M-`,
      `Barrage d'épines : inflige 5x1d4 aux personnes proches et leur applique Poison III pour 10 tours, type M-, rechargement de 10 tours`
    ]
  },
  {
    name: `Cactopus`,

    types: [Monsters.Type.PLANT],
    health: `190`,
    armor: `10`,
    damage: `20 + 10x1d4`,
    hitClose: `75 %`,
    hitMedium: `50 %`,
    actions: `+1`,
    attacks: [
      `Applique Poison, type H`
    ],
    abilities: [
      `Étreinte douloureuse : tente d'attraper une cible et de la serrer, ce qui lui inflige 15x1d6 blessures`
    ]
  },
];

export default data;
