import fire_earth from './fire_earth';
import light_life from './light_life';
import mind from './mind';
// import multi from './multi';
import plans from './plans';
import shadow_death from './shadow_death';
import special from './special';
import time from './time';
import water_nature from './water_nature';
import wind_thunder from './wind_thunder';

const all = [
  ...fire_earth,
  ...light_life,
  ...mind,
  // ...multi,
  ...plans,
  ...shadow_death,
  ...special,
  ...time,
  ...water_nature,
  ...wind_thunder,
];

export default all;

export const Items = all;
