import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';

import gifts from 'data/gifts';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { Gift } from 'components/afterworld/data/Gift';
import { PageHeading } from 'components/elements';

const giftsLevels = [...new Set(gifts.map((gift) => (gift.level)))];

export function GiftPage() {
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const filteredData = gifts
    .filter((datum) =>
      (!filter || datum.level === filter)
      && (!search || datum.name.toLowerCase().includes(search))
    );

  return (
    <>
      <PageHeading>
        Aptitudes
      </PageHeading>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <InputLabel id="afterworld-gifts__list-filter__label">
          Niveau
        </InputLabel>

        <Select
          labelId="afterworld-gifts__list-filter__label"
          id="afterworld-gifts__list-filter"
          value={filter}
          label="Niveau"
          onChange={(e) => { setFilter(e.target.value); }}
        >
          <MenuItem value=""><em>Aucun filtre</em></MenuItem>
          {giftsLevels.map((filterItem) => (
            <MenuItem key={filterItem} value={filterItem}>{filterItem}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-gifts__list-search"
          label="Rechercher l'aptitude"
          onChange={(e) => { setSearch(e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        density="comfortable"
        mobileComponent={Gift}
      />
    </>
  );
}
