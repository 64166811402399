import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Parasite`,

    types: [Monsters.Type.MUTANT],
    health: `35`,
    speed: `4 m/s`,
    hitClose: `55 %`,
    initiative: `+4`,
    attacks: [
      `Applique Radiations IV`
    ],
    abilities: [
      `Contrôle mental : se place sur la tête d'une cible et envahit son cerveau afin de diriger ses actions`
    ]
  },
  {
    name: `Goule errante`,

    types: [Monsters.Type.MUTANT],
    health: `80`,
    armor: `3`,
    speed: `3 m/s`,
    damage: `24 + 2x1d6`,
    hitClose: `65 %`,
    initiative: `+2`,
    attacks: [
      `Applique Radiations II`,
      `50 % : renverse la cible au sol`,
    ],
    items: [
      `Armure de cuir partielle`
    ],
    passives: [Monsters.Skills.MouvementsErratiques(30)]
  },
  {
    name: `Fangeux`,

    types: [Monsters.Type.MUTANT],
    health: `75`,
    armor: `25 partout sauf la tête`,
    speed: `2 m/s`,
    damage: `18 + 2x1d6`,
    hitClose: `70 %`,
    attacks: [
      `Applique Radiations II`
    ]
  },
  {
    name: `Visionnaire`,

    types: [Monsters.Type.MUTANT],
    health: `75`,
    armor: `1`,
    speed: `2 m/s`,
    damage: `12 + 2x1d6`,
    hitClose: `60 %`,
    passives: [
      `Champ de vision large : impossible d'être pris par surprise`,
      `Vision lointaine : capable de voir extrêmement loin et peut maintenir un pistage à la vue sur des kilomètres`,
    ],
    abilities: [
      `Étranglement : maintient la cible et tente de l'étouffer. Si elle ne parvient pas à se libérer avant de perdre son souffle, elle tombe inconsciente`,
    ]
  },
  {
    name: `Porteur de peste`,

    types: [Monsters.Type.MUTANT],
    health: `60`,
    armor: `1`,
    speed: `3 m/s`,
    damage: `9 + 2x1d6`,
    hitClose: `65 %`,
    initiative: `+2`,
    attacks: [
      `Applique Radiations IV`,
      `70% : transmets le Petit Mal`,
      `60% : applique Poison III pendant 10 tours, type D+`,
      `40% : transmets la Putride`,
      `20% : transmets la Peste bubonique`,
    ]
  },
  {
    name: `Abysse putride`,

    types: [Monsters.Type.MUTANT],
    health: `40`,
    speed: `3 m/s`,
    damage: `8 + 2x1d8`,
    hitClose: `50 %`,
    initiative: `+4`,
    attacks: [
      `75% : applique Saignements I permanent`,
      `60% : applique Poison I permanent, type D`,
    ],
    abilities: [
      `Crachat toxique : crache un bézoard purulent vers une cible avec 25% de chances de l'atteindre, transmets un cocktail de 3 maladies`,
    ]
  },
];

export default data;
