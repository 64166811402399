import PropTypes from 'prop-types';
import React from 'react';

import { DataCard } from 'components/afterworld/data/card/DataCard';

import './Gift.scss';

export function Gift({
  effect,
  level,
  name,
  requirements = [],
  usable = 1,
}) {
  return (
    <DataCard
      className="gift"
      content={effect.map((e, i) => (
        <div className="giftBody" key={i}>
          {e}
        </div>
      ))}
      description={!!requirements.length && requirements.map((e, i) => (
        <div key={i}>
          {e}
        </div>
      ))}
      title={name}
      pinLeft={{
        tooltip: 'Niveau nécessaire',
        value: `Niveau ${level}`,
      }}
      pinRight={usable > 1 ? {
        tooltip: `Nombre d'utilisation maximum`,
        value: `${usable}x`,
      } : {}}
    />
  );
}

Gift.propTypes = {
  effect: PropTypes.arrayOf(PropTypes.string).isRequired,
  level: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.string),
  usable: PropTypes.number,
};
