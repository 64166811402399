import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';

import { usePrevious } from 'utils/usePrevious';

let destination = null;

export function ManualMenu() {
  const navigate = useNavigate();

  const [menuCharacterAnchor, setMenuCharacterAnchor] = useState(false);
  const previousMenuCharacterAnchor = usePrevious(menuCharacterAnchor);
  const menuCharacterOpened = Boolean(menuCharacterAnchor);

  const handleOpenMenuCharacter = (event) => {
    setMenuCharacterAnchor(event.currentTarget);
  };

  const [menuFightAnchor, setMenuFightAnchor] = useState(false);
  const previousMenuFightAnchor = usePrevious(menuFightAnchor);
  const menuFightOpened = Boolean(menuFightAnchor);

  const handleOpenMenuFight = (event) => {
    setMenuFightAnchor(event.currentTarget);
  };

  const [menuOtherAnchor, setMenuOtherAnchor] = useState(false);
  const previousMenuOtherAnchor = usePrevious(menuOtherAnchor);
  const menuOtherOpened = Boolean(menuOtherAnchor);

  const handleOpenMenuOther = (event) => {
    setMenuOtherAnchor(event.currentTarget);
  };

  const handleCloseAll = () => {
    if (menuCharacterOpened) {
      setMenuCharacterAnchor(null);
    }
    if (menuFightOpened) {
      setMenuFightAnchor(null);
    }
    if (menuOtherOpened) {
      setMenuOtherAnchor(null);
    }
  };

  const navigateTo = (dest) => {
    destination = dest;
    handleCloseAll();
  };

  /* Delaying navigation so the menu can close, avoiding the "lagging" effect */
  useEffect(() => {
    if (!menuCharacterAnchor && previousMenuCharacterAnchor && destination) {
      navigate(destination);
      destination = null;
    }
    if (!menuFightAnchor && previousMenuFightAnchor && destination) {
      navigate(destination);
      destination = null;
    }
    if (!menuOtherAnchor && previousMenuOtherAnchor && destination) {
      navigate(destination);
      destination = null;
    }
  }, [navigate, menuCharacterAnchor, previousMenuCharacterAnchor, menuFightAnchor, previousMenuFightAnchor, menuOtherAnchor, previousMenuOtherAnchor]);

  return (
    <>
      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'ellipsis']} />}
        to="/manual"
      >
        Introduction
      </Button>

      <Button
        endIcon={<FontAwesomeIcon icon={['fa', 'user-secret']} />}
        onClick={handleOpenMenuCharacter}
      >
        Personnage
      </Button>

      <Menu
        anchorEl={menuCharacterAnchor}
        open={menuCharacterOpened}
        onClose={handleCloseAll}
        className="header__menu"
      >
        <MenuItem onClick={() => navigateTo('/manual/character/creation')}>
          <FontAwesomeIcon icon={['fa', 'user-plus']} />
          Le personnage
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/character/skills')}>
          <FontAwesomeIcon icon={['fa', 'brain']} />
          Competences
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/character/race')}>
          <FontAwesomeIcon icon={['fa', 'user-astronaut']} />
          Race
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/character/passives')}>
          <FontAwesomeIcon icon={['fa', 'heart-circle-bolt']} />
          Passifs
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/character/statistics')}>
          <FontAwesomeIcon icon={['fa', 'heart-pulse']} />
          Statistiques
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/character/magic')}>
          <FontAwesomeIcon icon={['fa', 'wand-magic-sparkles']} />
          La magie
        </MenuItem>
      </Menu>

      <Button
        endIcon={<FontAwesomeIcon icon={['fa', 'hand-fist']} />}
        onClick={handleOpenMenuFight}
      >
        Combat
      </Button>

      <Menu
        anchorEl={menuFightAnchor}
        open={menuFightOpened}
        onClose={handleCloseAll}
        className="header__menu"
      >
        <MenuItem onClick={() => navigateTo('/manual/fight/round')}>
          <FontAwesomeIcon icon={['fa', 'person-running']} />
          Deroulement
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/damage')}>
          <FontAwesomeIcon icon={['fa', 'skull-crossbones']} />
          Attaquer
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/melee')}>
          <FontAwesomeIcon icon={['fa', 'hand-back-fist']} />
          En melee
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/ranged')}>
          <FontAwesomeIcon icon={['fa', 'person-rifle']} />
          A distance
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/special')}>
          <FontAwesomeIcon icon={['fa', 'hourglass-half']} />
          Speciales
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/modifiers')}>
          <FontAwesomeIcon icon={['fa', 'eye-low-vision']} />
          Modificateurs
        </MenuItem>
      </Menu>

      <Button
        endIcon={<FontAwesomeIcon icon={['fa', 'book']} />}
        onClick={handleOpenMenuOther}
      >
        Manuel
      </Button>

      <Menu
        anchorEl={menuOtherAnchor}
        open={menuOtherOpened}
        onClose={handleCloseAll}
        className="header__menu"
      >
        <MenuItem onClick={() => navigateTo('/manual/other/environment')}>
          <FontAwesomeIcon icon={['fa', 'braille']} />
          Environnement
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/other/drugs')}>
          <FontAwesomeIcon icon={['fa', 'book-open']} />
          Drogues
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/other/states')}>
          <FontAwesomeIcon icon={['fa', 'hand-dots']} />
          Etat de sante
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/other/madness')}>
          <FontAwesomeIcon icon={['fa', 'face-angry']} />
          Folie
        </MenuItem>
      </Menu>

    </>
  );
}
