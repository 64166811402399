import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import replaceAccentedCharacters from 'lib/normalizeString';

const InnerSubtitle = styled.h3`
  border-radius: 8px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  display: block;
  font-family: DemonSker;
  font-size: 1.2em;
  font-weight: 400;
  letter-spacing: 0.2em;
  margin: 0px auto 4px;
  padding: 6px 10px 4px 20px;
`;

export function Subtitle({
  children,
}) {
  return <InnerSubtitle>{replaceAccentedCharacters(children)}</InnerSubtitle>;
}

Subtitle.propTypes = {
  children: PropTypes.string.isRequired,
};
