import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    effect: `Provoque l'état "Alcool fort"
    Déshydrate
    Dépendance 35%`,
    name: `Alcool blanc`,
    note: `De l'alcool d'avant-guerre, Rhum, Tequila, Vodka, Liqueur, ...
    Dans une bouteille en plastique ou en verre`,
    value: 42,
    weight: 1,
  },
  {
    effect: `Provoque l'état "Alcool fort"
    Déshydrate
    Dépendance 30%`,
    name: `Alcool brun`,
    note: `De l'alcool d'avant-guerre, Whisky, Scotch, Porto, ...
    Dans une bouteille en plastique ou en verre`,
    value: 45,
    weight: 1,
  },
  {
    effect: `Provoque l'état "Alcool léger"
    Déshydrate`,
    name: `Bière`,
    note: `Dans une bouteille en verre ou une canette en aluminium`,
    value: 4,
    weight: 0.5,
  },
  {
    effect: `Après 15 minutes, ignore l'effet de 2 points de Fatigue et ce pendant plusieurs heures`,
    name: `Café en poudre`,
    note: `Doit être dilué dans de l'eau, de préférence chaude`,
    value: 6,
    weight: 0.25,
  },
  {
    effect: `Provoque l'état "Alcool fort"
    Déshydrate
    Dépendance 25%`,
    name: `Cocktail`,
    note: `Mort-trop-tôt, Plante-minute ou El pino del col, ...
    Servi dans un verre, généralement dans des bars
    Les cocktails un peu plus uppés peuvent valoir 2 ou 3 fois le prix classique`,
    value: 18,
    weight: 0.3,
  },
  {
    effect: `Provoque l'état "Alcool fort" et Radiations +80
    Déshydrate
    Dépendance 80%`,
    name: `Eau de vie de fruit mutant`,
    note: `Dans une bouteille en plastique ou en verre
    Boisson contemporaine et connue pour son côté addictif`,
    value: 14,
    weight: 1,
  },
  {
    effect: `Provoque l'état "Alcool fort" et Radiations +50
    Déshydrate
    Test de Volonté toutes les 5 minutes pour rester éveillé`,
    name: `Eau de vie de cactus irradié`,
    note: `Dans une bouteille en plastique ou en verre
    Boisson contemporaine mais assez rare car la recette est méconnue`,
    value: 85,
    weight: 1,
  },
  {
    effect: `Désaltère
    Peut soigner de certaines maladies ou état de santé`,
    name: `Eau pure`,
    note: `Une bouteille en plastique d'eau propre`,
    value: 32,
    weight: 1,
  },
  {
    effect: `Désaltère
    Radiations +10
    Chances de tomber malade`,
    name: `Eau sale`,
    note: `Une bouteille en plastique d'eau sale`,
    value: 12,
    weight: 1,
  },
  {
    effect: `Chances de réussite magique +10% pendant 30 minutes
    Désaltère légèrement
    Radiations +80`,
    name: `Filite`,
    note: `Une bouteille en plastique qui contient une boisson douteuse
    Cette mixture est connue dans les terres dévastées et peut se trouver chez de nombreux commerçants, mais la recette est obscure ...`,
    value: 13,
    weight: 1,
  },
  {
    effect: `Acuité +20%
    Procure des visions fiévreuses
    Chances de gagner des points de Psychose`,
    name: `Flasque de kholmi`,
    note: `Une boisson concentrée dans une petite flasque
    Elle n'est pas très répandue mais la recette reste assez connue`,
    value: 28,
    weight: 0.2,
  },
  {
    effect: `Récupère 4 points de Magie après 15 minutes
    Désaltère légèrement
    Radiations +20`,
    name: `Infusion de Bani`,
    note: `Une tasse avec une infusion de plantes
    Servi dans des tavernes ou endroits posés`,
    value: 8,
    weight: 0.25,
  },
  {
    effect: `Désaltère`,
    name: `Lait`,
    note: `Une bouteille en verre remplie de lait parfois caillé`,
    value: 3,
    weight: 1,
  },
  {
    effect: `Initiative +1 pendant 1 minute
    Désaltère légèrement
    Radiations +10`,
    name: `Soda`,
    note: `Une bouteille en verre avec du soda industriel , Pusp'it, Poknuka ou Nuka-Cola Quantum ...`,
    value: 8,
    weight: 1,
  },
  {
    effect: `Provoque l'état "Alcool léger"
    Déshydrate
    Dépendance 25%`,
    name: `Vin ou champagne`,
    note: `Dans une bouteille en plastique ou en verre
    Blanc, rosé ou rouge, il en fait voir de toutes les couleurs!
    Souvent plus apprécié que la bière malgré que ce soit de la piquette, sauf si la bouteille date d'avant la guerre, auquel cas le prix peut être cent fois plus élevé`,
    value: 8,
    weight: 1,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.General.DRINK,
  type: Type.Item.GENERAL,
}));
