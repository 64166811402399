export default Object.freeze([
  [`Particularité`, `Malus`],
  [`Sans gestuelle`, `-15%`],
  [`Sans messe`, `-15%`],
  [`Sans contact`, `-20%`],
  [`Sans visuel`, `-30%`],
  [`Sans relique`, `-30%`],
  [`En canalisation`, `-25%`],
  [`École supplémentaire`, `-10% / école`],
  [`Joueur supplémentaire`, `-5% / école`],
]);
