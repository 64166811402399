import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Centaure`,

    types: [Monsters.Type.MUTANT],
    health: `45`,
    speed: `1 m/s`,
    damage: `12 + 1d6`,
    hitClose: `70 %`,
    hitMedium: `55 %`,
    hitFar: `40 %`,
    attacks: [
      `Applique Radiations III`
    ],
    death: [
      `Explosion de radiations : applique Radiations IV aux personnes proches`
    ]
  },
  {
    name: `Goule sauvage`,

    types: [Monsters.Type.MUTANT],
    health: `65`,
    speed: `3 m/s`,
    damage: `12 + 1d6`,
    hitClose: `55 %`,
    attacks: [
      `20 % : applique Radiations I`
    ],
    abilities: [Monsters.Skills.MouvementsErratiques(20)]
  },
  {
    name: `Naufragé contaminé`,

    types: [Monsters.Type.MUTANT],
    health: `40`,
    speed: `2 m/s`,
    damage: `6 + 1d6`,
    hitClose: `45 %`,
    attacks: [
      `30 % : transmets la Putride`,
      `15 % : transmets la Peste bubonique`,
    ],
  },
  {
    name: `Affamé de chair`,

    types: [Monsters.Type.MUTANT],
    health: `30`,
    speed: `2 m/s`,
    damage: `6 + 1d4`,
    hitClose: `45 %`,
    initiative: `+2`,
    attacks: [
      `Applique Saignements I permanent`,
    ],
  },
];

export default data;
