const aptitudes = [
  {
    name: `Altruiste`,
    effect: [
      `${CHARACTER.SKILLS.SOCIAL.EMPATHY} +4`,
      `${CHARACTER.SKILLS.SOCIAL.TALKING} +4`,
    ],
  },
  {
    name: `Analytique`,
    effect: [
      `${CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING} +4`,
      `${CHARACTER.SKILLS.DEFENSES.FOCUS} +4`,
    ],
  },
  {
    name: `Autoritaire`,
    effect: [
      `${CHARACTER.SKILLS.SOCIAL.INTIMIDATION} +4`,
      `${CHARACTER.SKILLS.MASTERY.STRENGTH} +4`
    ],
  },
  {
    name: `Brillant`,
    effect: [
      `${CHARACTER.SKILLS.KNOWLEDGE.BIOLOGY} +4`,
      `${CHARACTER.SKILLS.MASTERY.SAGACITY} +4`,
    ],
  },
  {
    name: `Charismatique`,
    effect: [
      `${CHARACTER.SKILLS.SOCIAL.NEGOTIATE} +4`,
      `${CHARACTER.SKILLS.SOCIAL.TALKING} +4`,
    ],
  },
  {
    name: `Compassionnel`,
    effect: [
      `${CHARACTER.SKILLS.KNOWLEDGE.BIOLOGY} +4`,
      `${CHARACTER.SKILLS.SOCIAL.EMPATHY} +4`,
    ],
  },
  {
    name: `Conflictuel`,
    effect: [
      `${CHARACTER.SKILLS.SOCIAL.NEGOTIATE} +4`,
      `${CHARACTER.SKILLS.MASTERY.SAGACITY} +4`,
    ],
  },
  {
    name: `Curieux`,
    effect: [
      `${CHARACTER.SKILLS.KNOWLEDGE.CULTURE} +4`,
      `${CHARACTER.SKILLS.DEFENSES.FOCUS} +4`,
    ],
  },
  {
    name: `Cruel`,
    effect: [
      `${CHARACTER.SKILLS.SOCIAL.INTIMIDATION} +4`,
      `${CHARACTER.SKILLS.DEFENSES.WILL} +4`,
    ],
  },
  {
    name: `Impassible`,
    effect: [
      `${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +4`,
      `${CHARACTER.SKILLS.MASTERY.AGILITY} +4`
    ],
  },
  {
    name: `Intrépide`,
    effect: [
      `${CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING} +4`,
      `${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +4`,
    ],
  },
  {
    name: `Méthodique`,
    effect: [
      `${CHARACTER.SKILLS.KNOWLEDGE.CULTURE} +4`,
      `${CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL} +4`,
    ],
  },
  {
    name: `Observateur`,
    effect: [
      `${CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL} +4`,
      `${CHARACTER.SKILLS.MASTERY.ACUITY} +4`,
    ],
  },
  {
    name: `Résilient`,
    effect: [
      `${CHARACTER.SKILLS.DEFENSES.VIGOR} +4`,
      `${CHARACTER.SKILLS.DEFENSES.WILL} +4`,
    ],
  },
  {
    name: `Robuste`,
    effect: [
      `${CHARACTER.SKILLS.DEFENSES.VIGOR} +4`,
      `${CHARACTER.SKILLS.MASTERY.STRENGTH} +4`,
    ],
  },
  {
    name: `Sensible`,
    effect: [
      `${CHARACTER.SKILLS.MASTERY.ACUITY} +4`,
      `${CHARACTER.SKILLS.MASTERY.SAGACITY} +4`,
    ],
  },
];

export default aptitudes.map((a) => ({ ...a, level: 1 }));
