import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    effect: `Détruit les vents magiques en permanence dans une zone de 100 m² aux alentours
    Consomme 500 PE par heure`,
    name: `Balise anti-magie`,
    note: `Une balise à poser au sol et à alimenter en énergie qui permet de détruire les vents magiques dans une large zone aux alentours, ne peut pas être déplacé en fonctionnement`,
    value: 365,
    weight: 35,
  },
  {
    effect: `Détecte la quantité de Radiations présente dans une zone proche
    Consomme 2 PE par heure`,
    name: `Compteur Geiger-Müller`,
    note: `Appareil qui permet de mesurer les radiations ionisantes aux alentours`,
    value: 165,
    weight: 0.5,
  },
  {
    effect: `Permet d'enregistrer les données vues et entendues par le personnage dans un matériel informatique à proximité
    Chances de perdre la vue et/ou l'ouïe`,
    name: `Implant neuronal`,
    note: `Enregistrer les 30 dernières secondes devient possible !
    Les versions d'avant-guerre ne présentent aucun danger, mais sont très rares`,
    value: 675,
    weight: 0.05,
  },
  {
    effect: `Durée des drogues ÷2
    Effets des drogues x2
    Score de dépendance des drogues consommées x2`,
    name: `Kit d'intraveineuse`,
    note: `Pour s'injecter les substances directement dans le corps, pas de temps à perdre!`,
    value: 75,
    weight: 0.5,
  },
  {
    effect: `Les armes à énergie font des dégâts de zone sur 1 mètre
    Réduit la portée à "Portée courte"`,
    name: `Module à fragmentation d'énergie`,
    note: `Un module à brancher au bout des armes à énergie qui modifie le comportement des projectiles`,
    value: 35,
    weight: 1,
  },
  {
    effect: `Permet de faire des montées ascensionnelles`,
    name: `Pistolet-Grappin`,
    note: `Un pistolet qui tire un embout collant relié par une corde
    Un bouton permet de rembobiner la corde et donc de ramener le pistolet proche de l'embout collant`,
    value: 127,
    weight: 2,
  },
  {
    effect: `Ajoute un pointeur laser pour aider à la visée
    La lumière du pointeur laser peut être réglée pour ne pas être dans la longueur d'onde visible par l'oeil humain`,
    name: `Pointeur laser`,
    note: `Un module à connecter sur une arme qui permet de l'équipe d'un pointeur laser`,
    value: 185,
    weight: 1,
  },
  {
    effect: `Toucher +15%
    BPA réduits de moitié`,
    name: `Silencieux`,
    note: `Installé sur une arme, les tirs sont désormais silencieux`,
    value: 30,
    weight: 0.2,
  },
  {
    effect: `Toucher +10%
    Nécessite une arme avec un BPA égal à 1`,
    name: `Trépied pour arme légère`,
    note: `Généralement installé avec un fusil sniper`,
    value: 21,
    weight: 2,
  },
  {
    effect: `Toucher +10%
    Si l'arme a des malus sans trépied, ce dernier les annule`,
    name: `Trépied pour arme lourde`,
    note: `Généralement installé avec un minigun`,
    value: 34,
    weight: 4,
  },
  {
    effect: `Toucher +20%
    Chances de succès critique +3%
    Efficace seulement en tir de balle unique
    Nécessite une arme avec un BPA supérieur à 1`,
    name: `Viseur pour arme légère`,
    note: `Un embout à installer sur une arme à feu`,
    value: 25,
    weight: 0.2,
  },
  {
    effect: `Permet de se déplacer rapidement
    Consomme 250 PE par heure`,
    name: `Véhicule nucléaire`,
    note: `Une mote ou une voiture classique qui fonctionne avec des piles à fission`,
    value: 3500,
    weight: 2000,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.General.UTILITIES,
  type: Type.Item.GENERAL,
}));
