import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AfterworldButton } from 'components/afterworld/AfterworldButton';
import { Label, PageHeading } from 'components/elements';
import { generateLevel, generateNames, generateRace, generateTraits } from 'data/generators';

export function CharacterGeneratorPage() {
  const [generatedRace, setRace] = useState(generateRace());
  const [generatedLevel, setLevel] = useState(generateLevel());
  const [generatedNames, setNames] = useState(generateNames());
  const [generatedTraits, setTraits] = useState(generateTraits());

  const resetMain = () => {
    setLevel(generateLevel());
    setRace(generateRace());
  };

  const resetNames = () => {
    setNames(generateNames());
  };

  const resetTraits = () => {
    setTraits(generateTraits());
  };

  return (
    <>
      <PageHeading>
        Personnage
      </PageHeading>

      <Box>
        <Grid container>
          <Grid item xs={1}>
            <AfterworldButton onClick={resetMain}>
              <FontAwesomeIcon icon={['fa', 'refresh']} />
            </AfterworldButton>
          </Grid>

          <Grid item xs={10}>
            Race
          </Grid>

          <Grid item xs={1}>
            {' '}
          </Grid>
        </Grid>
      </Box>

      <Label color="lightgreen">{generatedRace}</Label>

      <Label color="lightgreen">{`Niveau ${generatedLevel}`}</Label>

      <Box>
        <Grid container>
          <Grid item xs={1}>
            <AfterworldButton onClick={resetNames}>
              <FontAwesomeIcon icon={['fa', 'refresh']} />
            </AfterworldButton>
          </Grid>

          <Grid item xs={10}>
            Nom
          </Grid>

          <Grid item xs={1}>
            {' '}
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          {' '}
        </Grid>

        <Grid item xs={5}>
          Réel
        </Grid>

        <Grid item xs={5}>
          Fantasy
        </Grid>

        <Grid item xs={2}>
          Masculin
        </Grid>

        <Grid item xs={5}>
          {generatedNames.male.real.map((name) => (
            <Label color="lightgreen" key={name}>{name}</Label>
          ))}
        </Grid>

        <Grid item xs={5}>
          {generatedNames.male.fantasy.map((name) => (
            <Label color="lightgreen" key={name}>{name}</Label>
          ))}
        </Grid>

        <Grid item xs={2}>
          Féminin
        </Grid>

        <Grid item xs={5}>
          {generatedNames.female.real.map((name) => (
            <Label color="lightgreen" key={name}>{name}</Label>
          ))}
        </Grid>

        <Grid item xs={5}>
          {generatedNames.female.fantasy.map((name) => (
            <Label color="lightgreen" key={name}>{name}</Label>
          ))}
        </Grid>
      </Grid>

      <Box>
        <Grid container>
          <Grid item xs={1}>
            <AfterworldButton onClick={resetTraits}>
              <FontAwesomeIcon icon={['fa', 'refresh']} />
            </AfterworldButton>
          </Grid>

          <Grid item xs={10}>
            Caractere
          </Grid>

          <Grid item xs={1}>
            {' '}
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          Positif
        </Grid>

        <Grid item xs={4}>
          Négatif
        </Grid>

        <Grid item xs={4}>
          Autre
        </Grid>

        {generatedTraits.map((trait) => (
          <Grid item xs={4} key={trait.id}>
            <Label color="lightgreen" key={trait.name}>{trait.name}</Label>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
