import React from 'react';

import { Image, List, Title, Text } from 'components/semantic';
import { PageHeading } from 'components/elements';
import racesData from 'data/rules/races';

export function RacePage() {
  return (
    <>
      <PageHeading>
        Race
      </PageHeading>

      <Title>Humain</Title>

      <Text>
        {`Un humain adulte fait généralement entre 150 et 200 centimètres, pèse entre 50 et 100 kilogrammes et vit jusqu'à environ 60 ans.`}
      </Text>

      <List data={racesData.HUMAN}></List>

      <Image size="medium" src="/images/manual/races/human.webp" />

      <Title>Salbeks</Title>

      <Text>
        {`Peu d'humains ont eu la chance de se réfugier pour se protéger des radiations, cela a affecté leur code génétique et a provoqué des mutations irréversibles.`}
      </Text>

      <Text>
        {`Leur apparence montre tout les symptômes d'un empoisonnement par radiations, d'où le nom que ces êtres ont récolté au fil des années : les Salbeks.`}
      </Text>

      <List data={racesData.SALBEK}></List>

      <Image size="medium" src="/images/manual/races/salbek.webp" />

      <Text>
        {`Leur peau a changé comme si elle avait brûlé et varie du brun au vert. Ils sont devenus stériles, mais leur espérance de vie a drastiquement augmenté.`}
      </Text>

      <Text>
        {`Les Salbeks mesurent entre 160 et 220 centimètres, pèsent entre 40 et 80 kilogrammes et peuvent vivre entre 200 et 300 ans.`}
      </Text>

      <Title>Thogs</Title>

      <Text>
        {`La décadence de l'humanité des années passés a réveillé les pires horreurs cachées au tréfonds de la planète, un virus s'est alors propagé dans l'air et a transformé certains êtres sensibles en ces brutes musclées.`}
      </Text>

      <Text>
        {`Quand l'infection est clémente, les Thogs gardent leur humanité, mais dans d'autres cas, ils cèdent à la folie et n'écoutent plus que leur instincts primitifs.`}
      </Text>

      <List data={racesData.THOG}></List>

      <Image size="medium" src="/images/manual/races/thog.webp" />

      <Text>
        {`La peau des Thogs varie du brun au jaunâtre. Ils ont des muscles saillants qui donnent l'impression d'être contractés en permanence.`}
      </Text>

      <Text>
        {`Ils mesurent entre 170 et 250 centimètres, pèsent entre 100 et 180 kilogrammes et peuvent vivre jusqu'à environ 120 ans.`}
      </Text>

      <Title>Cyborgs</Title>

      <Text>
        {`Dans le dégoût de l'humanité, chacun a trouvé sa façon de s'évader. Certains extrêmes ont voulu remplacer leur corps par des machines et s'y sont attelés membre après membre afin de se rapprocher au plus des robots : ce sont les cyborgs.`}
      </Text>

      <List data={racesData.CYBORG}></List>

      <Image size="medium" src="/images/manual/races/cyborg.webp" />

      <Text>
        {`Ils sont de la même taille que les humains et pèsent autant. Leur espérance de vie peut toutefois s'élever jusqu'à dans les 80 ans, en fonction des membres qui ont été remplacés.`}
      </Text>

      <Title>Elementaires</Title>

      <List data={racesData.ELEMENTAL}></List>

      <Text>
        {`Nul ne sait réellement d'où provient la magie dans le monde actuel. Cette dernière s'est immiscée dans la vie courante jusqu'au niveau biologique. Certains êtres qui devaient naître humains se retrouvèrent avec des membres magiques : des bras en glace, des jambes en terre.`}
      </Text>

      <Text>
        {`Rapidement, ces derniers ont découvert une aptitude innée à manier la magie et se sont ainsi éloignés des armes et de la technologie pour exploiter pleinement leur art.`}
      </Text>

      <Image size="medium" src="/images/manual/races/elementaire.webp" />

      <Text>
        {`Ils sont devenus une espèce à part entière connue sous le nom d'élémentaires. Ceux-ci mesurent entre 140 et 180 centimètres, pèsent entre 30 et 60 kilogrammes et vivent jusqu'à environ 100 ans.`}
      </Text>

      <Title>Adrulens</Title>

      <Text>
        {`Les Adrulens sont une race extra-terrestre qui viennent d'une planète qu'ils appellent Lépor. Cette dernière est entièrement colonisée, ne présente plus aucune trace de nature, et se trouve à 150 années-lumières de la Terre, plus ou moins.`}
      </Text>

      <Text>
        {`Après la guerre des bombes, les répercussions se ressentirent jusque dans leur monde, suite à quoi ils apprétêrent un vaisseau spécial dont la mission était de prendre contact avec la planète distante. Le voyage relativement long de ce vaisseau fit qu'il fallut 3 à 4 générations de personnes qui y vécurent avant d'atteindre la destination. Ceux qui partirent de leur planète ne virent donc jamais la Terre.`}
      </Text>

      <Text>
        {`Une fois le vaisseau arrivé à proximité, la génération actuelle décida de ne pas aborder la planète immédiatement. En effet, par rapport à leur monde ordonné et dressé, la Terre leur sembla quelque peu .. farouche et chaotique. C'est ainsi qu'ils passèrent encore plusieurs dizaines d'années dans l'espace à observer ce qui se passait en dessous d'eux. Ils finirent un jour par descendre et prendre contact avec ce qui leur semblait le berceau restant de l'humanité.`}
      </Text>

      <List data={racesData.ADRULEN}></List>

      <Image size="medium" src="/images/manual/races/adrulen.webp" />

      <Text>
        {`Ces êtres vénèrent plusieurs dieux, un pour chaque caste, et sont quasiment tous pratiquants. De part les différends qui existent entre les diverses castes, le conseil qui règne sur Lépor en a profité pour exiler l'une d'entre-elle dans ce périple incertain : les Emelen, ceux qui vénèrent Mayila, la déesse de la vie et des secrets.`}
      </Text>

      <Text>
        {`Très proches de leurs dieux, ils prient et reçoivent parfois des bénédictions.`}
      </Text>
    </>
  );
}
