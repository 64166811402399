import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    cost: 2,
    name: `Contre-sort`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.SAME },
    ],
    requirements: [
      `même école de magie que le sort visé`,
    ],
    additionalEffects: [
      `Surcharge : double la perte de Magie de la personne ciblée`,
    ],
    description: `Contrecarre le sort d'une personne, ce qui interrompt son incantation et lui fait perdre le coût du sort en points de Magie.`,
  },
  {
    cost: 4,
    name: `Désinvocation`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.SAME },
    ],
    damage: `3 + 1d6 (contondants)`,
    requirements: [
      `contact avec l'invocation`,
      `même école de magie que l'invocation visée`,
    ],
    additionalEffects: [
      `Surcharge : l'invocateur tombe inconscient`,
    ],
    description: `Désinvoque une invocation et fait perdre à l'invocateur autant de points de magie que le coût du sort.`,
  },
  {
    cost: 4,
    name: `Redirection`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.SAME },
    ],
    requirements: [
      `même école de magie que le sort visé`,
    ],
    additionalEffects: [
      `Attention : intervenir sur l'incantation d'autrui de manière impromptue peut avoir des effets catastrophiques`,
    ],
    description: `Intervient subtilement dans l'incantation d'une personne afin de tenter d'en changer le sujet, de sorte que ce sort atteigne une autre personne.`,
  },
  {
    cost: 8,
    name: `Brûlure de magie`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.SAME },
    ],
    damage: `9 + 1d6 (contondants)`,
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : impossible d'incanter des sorts de l'école visée dans la zone pendant 2 tours`,
      `Surcharge : augmente les blessures à 13 + 1d8`,
    ],
    description: `Détruit les vents magiques d'une école précise autour d'une personne ce qui l'empêche de lancer des sorts de cette école de magie et lui inflige des dégâts globaux.`,
  },
  {
    cost: 8,
    name: `Changement d'incantation`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.SAME },
    ],
    requirements: [
      `même école de magie que le sort visé`,
    ],
    additionalEffects: [
      `Attention : intervenir sur l'incantation d'autrui de manière impromptue peut avoir des effets catastrophiques`,
    ],
    description: `Intervient dans l'incantation d'une personne et tente d'en changer le sort pour un autre.`,
  },
  {
    cost: 8,
    name: `Désinvocation de masse`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.ALL },
    ],
    requirements: [
      `proximité`,
      `composant (échantillon de chaos magique stable)`,
    ],
    area: `4 m²`,
    damage: `5 + 1d6 (contondants) par invocation`,
    additionalEffects: [
      `Surcharge : double la perte de Magie et augmente les blessures à 7 + 1d8 par invocation`,
    ],
    description: `Désinvoque toutes les invocations environnantes et fait perdre autant de points de magie aux invocateurs que le coût de leur invocation.`,
  },
  {
    cost: 8,
    name: `Destruction du chaos`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.ALL },
    ],
    requirements: [
      `proximité`,
      `composant (échantillon de chaos magique stable)`,
    ],
    damage: `5 + 1d6`,
    additionalEffects: [
      `Effet : impossible d'incanter dans la zone pendant 2 tours`,
      `Surcharge : porte les blessures à 7 + 1d8`,
    ],
    description: `Détruit les vents magiques proches d'un individu, ce qui interrompt les incantations en cours et lui inflige des dégâts pour chaque école de magie qu'il était en train d'incanter.`,
  },
]);

export default data;
