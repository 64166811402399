import React from 'react';

import { PageHeading } from 'components/elements';
import { Table, Text, Title } from 'components/semantic';
import skillsDescription from 'data/rules/skills';

export function SkillsPage() {
  return (
    <>
      <PageHeading>
        Competences
      </PageHeading>

      <Text>
        {`Chaque personnage dispose de compétences qu'il va entraîner au fur et à mesure de son aventure. Elles sont réparties en plusieurs catégories : le Combat, les Savoirs, le Social, les Résistances et la Maîtrise. Leur valeur s'échelonne généralement de 1 à 100 et représente le pourcentage de chances de réussir une action.`}
      </Text>

      <Text>
        {`Par exemple, tirer avec un pistolet relève de la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT}, cela nécessitera de lancer 1d100 et de comparer le résultat au score de la compétence pour savoir si le tir est réussi.`}
      </Text>

      <Text>
        {`Chaque catégorie dispose de 4 compétences qui regroupent un ensemble d'activités.`}
      </Text>

      <Title>Combat</Title>

      <Table data={skillsDescription.WEAPONS} sizes={[1, 3]}></Table>

      <Title>Savoirs</Title>

      <Table data={skillsDescription.KNOWLEDGE} sizes={[1, 3]}></Table>

      <Title>Social</Title>

      <Table data={skillsDescription.SOCIAL} sizes={[1, 3]}></Table>

      <Title>Resistances</Title>

      <Table data={skillsDescription.DEFENSES} sizes={[1, 3]}></Table>

      <Title>Maitrise</Title>

      <Table data={skillsDescription.MASTERY} sizes={[1, 3]}></Table>

    </>
  );
}
