export default Object.freeze([
  [`Type`, `Origine`, `Effet`],
  [`D`, `Rad-scorpion, rad-mouches, ..`, `Constamment essoufflé pendant 24 heures`],
  [`D+`, `Poison D distillé`, `Le moindre effort peut tuer pendant 8 heures`],
  [`H`, `Plantes radioactives, ..`, `Endort 3 heures puis réduit la Perception de 5 pendant 9 heures`],
  [`K`, `Laboratoire`, `Coma pendant 3 jours, test de Rés. Biologique toutes les 12 heures et décès en cas d'échec`],
  [`M-`, `Distillé des eaux radioactives croupies`, `Ne ressent plus la magie pendant 24 heures`],
  [`EN`, `Fabriqué`, `Paralyse 10 minutes`],
  [`IDP`, `Fabriqué`, `Toxine qui attaque les neurones directement, Intelligence -1 définitif après 24 heures`],
  [`SDP`, `Fabriqué`, `Toxine qui attaque les poumons directement, Constitution -1 définitif après 24 heures`],
]);
