import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Écorcheur`,

    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
    health: `190`,
    armor: `8`,
    speed: `6 m/s`,
    damage: `60 + 8x1d6`,
    criticalChances: `+5 %`,
    hitClose: `85 %`,
    initiative: `+8`,
    show: [
      `Effraie les individus proches`,
    ],
    passives: [Monsters.Skills.MouvementsErratiques(40)]
  },
  {
    name: `Marécage ambulant`,

    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
    health: `165`,
    speed: `2 m/s`,
    damage: `24 + 2x1d8`,
    hitClose: `110 %`,
    attacks: [
      `Applique Radiations IV`,
      `75 % : applique Poison IV pour 3 tours, type D`,
      `65 % : rend fou pendant 3 heures, à la suite de quoi le joueur gagne un point de folie`,
      `50 % : transmets une maladie, généralement une infection`
    ],
    death: [
      `Zone radioactive : laisse une flaque au sol qui applique Radiations IV par tour à l'intérieur`,
      `Spores empoisonnées: évacue des spores transparentes qui ont 35 % de chances par tour à les respirer d'appliquer du poison H`
    ]
  },
  {
    name: `Épouvantail de l'ombre`,

    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
    health: `125`,
    speed: `4 m/s`,
    damage: `48 + 8x1d4`,
    hitClose: `120 %`,
    initiative: `+8`,
    actions: `+1`,
    attacks: [
      `Applique Saignements IV permanent`
    ],
    abilities: [
      `Spores toxiques : libère un nuage qui applique Poison IV aux personnes proches pendant 3 tours, type H`,
    ]
  },
  {
    name: `Exécuteur`,

    types: [Monsters.Type.HUMAN, Monsters.Type.ELEMENTAL],
    health: `190`,
    mana: `40`,
    armor: `8`,
    speed: `3 m/s`,
    hitClose: `100 %`,
    hitMedium: `100 %`,
    hitFar: `35 %`,
    initiative: `+6`,
    actions: `+1`,
    magic: [
      {
        school: `Magie de Terre`,
        level: `4`
      },
      {
        school: `Magie de l'Air`,
        level: `6`
      },
      {
        school: `Magie de la Vie`,
        level: `6`
      },
      {
        school: `Magie de la Mort`,
        level: `4`
      }
    ],
    items: [
      `Arme quelconque exotique`,
      `Armure de cuir renforcée ou armure assistée, partielle ou complète`
    ],
    skills: [
      {
        skill: `Survie`,
        value: `70 %`
      },
      {
        skill: `Social`,
        value: `60 %`
      },
      {
        skill: `Maîtrise`,
        value: `60 %`
      },
    ]
  },
  {
    name: `Rad-cornu`,

    types: [Monsters.Type.ELEMENTAL, Monsters.Type.MUTANT],
    health: `175`,
    mana: `40`,
    armor: `18`,
    speed: `2 m/s`,
    damage: `24 + 5x1d6`,
    hitClose: `90 %`,
    initiative: `+8`,
    actions: `+1`,
    magic: [
      {
        school: `Magie de la Terre`,
        level: `10`
      },
      {
        school: `Magie de la Vie`,
        level: `8`
      },
      {
        school: `Magie du Vent`,
        level: `8`
      },
      {
        school: `Magie de l'eau`,
        level: `6`
      }
    ],
    attacks: [
      `Applique Brûlure III pour 3 tours`
    ]
  }
];

export default data;
