import { races } from 'data/character';

export * from './names';
export * from './stats';
export * from './traits';

export const generateLevel = () => {
  return Math.floor(Math.random() * 19) + 1;
};

export const generateRace = () => {
  return races[Math.floor(Math.random() * 6)];
};
