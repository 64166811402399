import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    cost: 2,
    name: `Changement d'intensité lumineuse`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} -20 (vue)`,
      `Effet : Actions -10 % vers cette lumière`,
    ],
    description: `Change une source de lumière afin de gêner une personne. Les effets ne fonctionnent que s'il s'agit d'une intensification dans un endroit sombre ou d'une atténuation dans un endroit lumineux.`,
  },
  {
    cost: 2,
    name: `Changement de taille`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    additionalEffects: [
      `Effet : taille x 2 ou taille ÷ 2`,
      `Surcharge : taille x 3 ou taille ÷ 3`,
    ],
    requirements: [
      `contact`,
    ],
    description: `Augmente ou réduit la taille d'une personne.`,
  },
  {
    cost: 2,
    name: `Détection des radiations`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    description: `Détecte la présence de radioactivité dans un être vivant ou dans une petite zone.`,
  },
  {
    cost: 2,
    name: `Détection du poison`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Surcharge : permet de détecter de quel type de poison est-ce qu'il s'agit`,
    ],
    description: `Détecte la présence de poison dans le corps d'une personne.`,
  },
  {
    cost: 2,
    name: `Irradiance`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    additionalEffects: [
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
    ],
    description: `Fait briller un objet, ce qui lui permet d'éclairer dans l'obscurité. Il est également possible de gêner l'utilisation d'un objet avec ce sort, tel que la lecture d'un livre ou l'utilisation d'un tableau de bord.`,
  },
  {
    cost: 2,
    name: `Peau de lézard`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
      `relique (peau de lézard conservée)`,
    ],
    additionalEffects: [
      `Effet : augmente la résistance au froid et au chaud de 3°`,
      `Surcharge : augmente la résistance au froid et au chaud de 6°`,
    ],
    description: `Adapte la peau d'une personne afin de réduire l'impact de la température sur son organisme.`,
  },
  {
    cost: 2,
    name: `Peau d'écorce`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : blessures infligées à mains nues +2`,
      `Effet : armure naturelle +4`,
      `Surcharge : double le bonus d'armure et de blessures`,
    ],
    description: `Durcit la peau d'une personne afin d'en augmenter sa résistance.`,
  },
  {
    cost: 2,
    name: `Sens accrus`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} +15`,
      `Surcharge : réduit les malus d'obscurité ou de luminosité de moitié`,
      `Surcharge : double la durée du souffle sous l'eau`,
    ],
    description: `Adapte la morphologie d'une personne afin de le doter de sens plus aiguisés.`,
  },
  {
    cost: 4,
    name: `Dissipation du poison`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
      `relique (extrait du poison recherché)`,
    ],
    description: `Dissipe la présence d'un poison précis de d'un être vivant.`,
  },
  {
    cost: 4,
    name: `Illusions oculaires`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    additionalEffects: [
      `Surcharge : Lui faire croire qu'elle est en mouvement alors qu'elle ne l'est pas permet de perturber le sens cinétique de son oreille interne`,
      `Surcharge : ${CHARACTER.SKILLS.MASTERY.AGILITY} -40`,
      `Surcharge : 80% de chances de provoquer la nausée`,
      `Surcharge : 40% de chances d'infliger l'état de santé « vomissements »`,
    ],
    description: `Change la lumière qui arrive dans les yeux d'une personne de sorte de lui faire voir des images illusoires.`,
  },
  {
    cost: 4,
    name: `Flash`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    additionalEffects: [
      `Effet en plein jour : ${CHARACTER.SKILLS.MASTERY.ACUITY} (vue) -25 pour 1d4 tours`,
      `Effet dans un endroit sombre : aveugle pour 1d4 tours`,
      `Surcharge : durée augmentée à 1d12 tours`,
    ],
    description: `Crée un flash lumineux tellement intense qu'il aveugle les personnes qui le voient.`,
  },
  {
    cost: 4,
    name: `Miroir`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    additionalEffects: [
      `Surcharge : crée une deuxième barrière en parallèle, de sorte que la lumière de l'une se transmette à l'autre et vice versa, créant un espace invisible entre les deux barrières`,
    ],
    area: `1 m²`,
    description: `Crée magiquement une barrière intangible qui renvoi la lumière ce qui donne l'effet d'un miroir, pratique pour se cacher derrière.`,
  },
  {
    cost: 4,
    name: `Rayon laser`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    additionalEffects: [
      `Surcharge : blessures augmentées à 7 + 1d6`,
    ],
    damage: `5 + 1d4`,
    description: `Concentre de l'énergie lumineuse et la projette vers une personne afin de l'incinérer. Cet effet peut atteindre de longues distances.`,
  },
  {
    cost: 4,
    name: `Simulation de drogue`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
      `relique (échantillon de la drogue)`,
    ],
    additionalEffects: [
      `Surcharge : double la dose de la drogue en ce qui concerne la gestion des dépendances`,
    ],
    description: `Applique magiquement les effets d'une drogue sur une personne.`,
  },
  {
    cost: 4,
    name: `Stimulation immunitaire`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : réduit les chances de contracter une maladie de moitié`,
      `Effet : Endurance -1 pendant 24 heures`,
      `Surcharge : réduit la durée de certaines maladies dem moitié`,
      `Surcharge : Endurance -3 supplémentaires pendant 24 heures`,
    ],
    description: `Renforce le système immunitaire d'une personne afin de lutter contre les infections et les maladies.`,
  },
  {
    cost: 8,
    name: `Invisibilité`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.AGILITY} (discrétion) +75`,
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
    ],
    description: `Le corps d'un être ne renvoie plus la lumière, l'empêchant ainsi d'être vu.`,
  },
  {
    cost: 8,
    name: `Mirage`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    description: `Déplace le visuel d'une scène à un autre endroit afin de leurrer des personnes.`,
  },
  {
    cost: 8,
    name: `Rémanence restituée`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `lieu clos`,
    ],
    description: `Permet de recréer une scène du passé dans un endroit clos afin de savoir ce qui s'y est déroulé.`,
  },
  {
    cost: 8,
    name: `Ressouder un membre`,
    types: [
      Spells.Types.SUMMON,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : ressoude un membre perdu depuis moins de 15 secondes`,
      `Attention : relâcher l'invocation annule les effets`,
    ],
    description: `Insuffle de la vie à un être vivant afin de lui redonner des forces.`,
  },
  {
    cost: 8,
    name: `Résurrection`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : sur la cible, Mental à 0, Psychose +3, Endurance -1 définitivement, Vigueur -5 définitivement`,
      `Effet : sur le lanceur, Psychose +1, Endurance -1 définitivement, Vigueur -10 définitivement`,
    ],
    description: `Ramène à la vie un être vivant qui est décédé depuis moins de 15 secondes.`,
  },
  {
    cost: 8,
    name: `Sens de la vie`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    area: `1 m²`,
    description: `Repère les formes de vie humanoïdes ou animales dans les alentours.`,
  },
  {
    cost: 8,
    name: `Soins des plaies`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.LIGHT_LIFE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : supprime les saignements sur un membre`,
      `Effet : Mental -1 et Fatigue -1 à la cible`,
    ],
    description: `Cicatrise une plaie et empêche toute hémorragie.`,
  },
]);

export default data;
