import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Fuyard`,

    types: [Monsters.Type.HUMAN],
    health: `110`,
    armor: `1`,
    speed: `4 m/s`,
    hitClose: `40 %`,
    hitMedium: `15 %`,
    initiative: `+2`,
    items: [
      `Arme légère ou de corps à corps simple`,
      `Armure de peau ou de cuir partielle`
    ],
    skills: [
      {
        skill: `Sang froid`,
        value: `5 %`
      }
    ]
  },
  {
    name: `Fermier`,

    types: [Monsters.Type.HUMAN],
    health: `140`,
    armor: `4`,
    speed: `2 m/s`,
    hitClose: `50 %`,
    items: [
      `Arme de corps-à-corps simple`,
      `Armure de peau ou de cuir partielle`
    ]
  },
  {
    name: `Pillard isolé`,

    types: [Monsters.Type.HUMAN],
    health: `125`,
    armor: `1`,
    speed: `3 m/s`,
    hitClose: `55 %`,
    hitMedium: `35 %`,
    hitFar: `15 %`,
    items: [
      `Arme légère simple`,
      `Armure de cuir partielle`
    ],
    skills: [
      {
        skill: `Intimidation`,
        value: `50 %`
      }
    ]
  },
];

export default data;
