import styled from "@emotion/styled";

export const DataCardTitle = styled.div`
  background: linear-gradient(#6F8F8F, #4F6F6F);
  border-bottom: solid 1px rgb(0 0 0 / 10%);
  color: white;
  padding: 8px 40px;
  position: relative;
  text-align: center;
  text-shadow: 0px 0px 1px black;
`;
