import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ArmorData } from 'components/afterworld/data/ArmorData';
import { DataCard } from 'components/afterworld/data/card/DataCard';
import { CardPin } from 'components/afterworld/data/card/CardPin';

export function Armor({
  armor,
  description,
  effects,
  name,
  value,
  weight,
}) {

  return (
    <DataCard
      content={(<><ArmorData armor={armor} border={!!effects.length} />{effects}</>)}
      description={description}
      title={name}
      bottom={[
        <CardPin key={0} tooltip={`Valeur`}>
          <FontAwesomeIcon icon={['fa', 'sack-dollar']} />
          {' '}
          {value}
        </CardPin>,
        <CardPin key={1} tooltip={`Poids`}>
          <FontAwesomeIcon icon={['fa', 'weight-hanging']} />
          {' '}
          {weight}
        </CardPin>
      ]}
    />
  );
}

Armor.propTypes = {
  armor: PropTypes.shape({
    head: PropTypes.string,
    armRight: PropTypes.string,
    armLeft: PropTypes.string,
    chest: PropTypes.string,
    legRight: PropTypes.string,
    legLeft: PropTypes.string,
  }),
  description: PropTypes.string,
  effects: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  weight: PropTypes.number.isRequired,
};

Armor.defaultProps = {
  armor: {},
  description: ``,
  effects: ``,
};
