export default Object.freeze([
  [`Pluie`, `-5%`],
  [`Averse`, `-10%`],
  [`Déluge`, `-15%`],
  [`Neige`, `-20%`],
  [`Grêle`, `-40%`],
  [`Blizzard`, `-60%`],
  [`Bruine`, `-25%`],
  [`Brouillard`, `-50%`],
  [`Brume`, `-75%`],
]);
