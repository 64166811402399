import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Helmut 400 : le tueur d'armée`,

    types: [Monsters.Type.ROBOT],
    health: `90`,
    armor: `12`,
    speed: `2 m/s`,
    damage: `20 + 2x1d8`,
    hitClose: `60 %`,
    hitMedium: `35 %`,
    items: [
      `Arme "Minigun"`
    ],
    attacks: [
      `Transmets une maladie`,
      `(PM) Par balayage`,
    ],
    abilities: [
      Monsters.Skills.Feinte,
      Monsters.Skills.Surcharge,
    ]
  },
  {
    name: `Prototype KT-25 de guerre`,

    types: [Monsters.Type.ROBOT],
    health: `120`,
    armor: `20`,
    speed: `3 m/s (course 12 m/s)`,
    damage: `40 + 4x1d8`,
    hitClose: `80 %`,
    hitMedium: `70 %`,
    initiative: `+2`,
    items: [
      `Arme "Minigun"`,
      `Arme "Lance-grenades"`,
    ],
    abilities: [Monsters.Skills.Surcharge]
  },
  {
    name: `Tourelle Mark V`,

    types: [Monsters.Type.ROBOT],
    health: `80`,
    armor: `15`,
    damage: `32 + 2x1d8`,
    hitClose: `90 %`,
    hitMedium: `70 %`,
    hitFar: `45 %`,
    initiative: `+8`,
    abilities: [
      Monsters.Skills.Feinte,
      `Impulsion EM : désactive tout mécanisme incluant de l'électronique dans la zone touchée et inflige 40 + 3x1d10 aux robots`
    ]
  },
];

export default data;
