import level1beasts from './1-beasts';
import level1elementals from './1-elementals';
import level1humans from './1-humans';
import level1multi from './1-multi';
import level1mutants from './1-mutants';
import level1plants from './1-plants';
import level1robots from './1-robots';

import level2beasts from './2-beasts';
import level2elementals from './2-elementals';
import level2humans from './2-humans';
import level2multi from './2-multi';
import level2mutants from './2-mutants';
import level2plants from './2-plants';
import level2robots from './2-robots';

import level3beasts from './3-beasts';
import level3elementals from './3-elementals';
import level3humans from './3-humans';
import level3multi from './3-multi';
import level3mutants from './3-mutants';
import level3plants from './3-plants';
import level3robots from './3-robots';

import level4beasts from './4-beasts';
import level4elementals from './4-elementals';
import level4humans from './4-humans';
import level4multi from './4-multi';
import level4mutants from './4-mutants';
import level4plants from './4-plants';
import level4robots from './4-robots';

import level5beasts from './5-beasts';
import level5elementals from './5-elementals';
import level5humans from './5-humans';
import level5multi from './5-multi';
import level5mutants from './5-mutants';
import level5plants from './5-plants';
import level5robots from './5-robots';

const all = [
  ...level1beasts,
  ...level1plants,
  ...level1mutants,
  ...level1humans,
  ...level1robots,
  ...level1elementals,
  ...level1multi,

  ...level2beasts,
  ...level2plants,
  ...level2mutants,
  ...level2humans,
  ...level2robots,
  ...level2elementals,
  ...level2multi,

  ...level3beasts,
  ...level3plants,
  ...level3mutants,
  ...level3humans,
  ...level3robots,
  ...level3elementals,
  ...level3multi,

  ...level4beasts,
  ...level4plants,
  ...level4mutants,
  ...level4humans,
  ...level4robots,
  ...level4elementals,
  ...level4multi,

  ...level5beasts,
  ...level5plants,
  ...level5mutants,
  ...level5humans,
  ...level5robots,
  ...level5elementals,
  ...level5multi,
];

export default all;

export const Monsters = all;
