import { Monsters } from 'data/metadata';

const data = [
  {
    name: `M. Gutsy`,

    types: [Monsters.Type.ROBOT],
    health: `140`,
    armor: `28`,
    speed: `2 m/s`,
    damageClose: `48 + 2x1d8`,
    damageMedium: `36 + 3x1d6`,
    hitClose: `80 %`,
    hitMedium: `35 %`,
    attacks: [
      `(PC): applique Feu III pour 6 tours`
    ],
    abilities: [
      Monsters.Skills.Alarme,
    ],
    death: [
      `Explosion : inflige 5x1d20 blessures`,
    ]
  },
  {
    name: `M. Handy`,

    types: [Monsters.Type.ROBOT],
    health: `115`,
    armor: `16`,
    speed: `3 m/s`,
    damage: `40 + 6x1d4`,
    hitClose: `90 %`,
    hitMedium: `80 %`,
    hitFar: `60 %`,
    initiative: `+6`,
    attacks: [
      `Applique Saignements III permanent`
    ],
    abilities: [
      `Cisaille : une attaque précise qui inflige moitié moins de dégâts mais ignore la moitié de l'armure`,
      Monsters.Skills.Alarme,
    ]
  },
  {
    name: `Exo-squelette Y-17`,

    types: [Monsters.Type.ROBOT],
    health: `40, uniquement dans le tronc`,
    speed: `3 m/s`,
    damage: `selon arme`,
    hitClose: `80 %`,
    hitMedium: `50 %`,
    initiative: `+2`,
    items: [
      `Arme de corps à corps ou légère, simple ou exotique`
    ],
    death: [
      `Explosion: provoque une auto-destruction qui inflige 5x1d20 blessures`
    ]
  },
];

export default data;
