import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Cyber-chien`,

    types: [Monsters.Type.BEAST, Monsters.Type.ROBOT],
    health: `70`,
    armor: `10`,
    speed: `4 m/s`,
    damage: `18 + 3x1d6`,
    hitClose: `75 %`,
    initiative: `+8`,
    attacks: [
      `40 % : applique Saignements III permanent`
    ],
    abilities: [Monsters.Skills.Saut(`3x1d6`)],
    death: [
      `Explosion : provoque une détonation à la fin de l'activité cardiaque qui inflige 20 + 2x1d20 aux alentours`
    ]
  },
  {
    name: `Fourmante`,

    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
    health: `80`,
    armor: `20`,
    speed: `2 m/s`,
    damage: `20 + 5x1d4`,
    hitClose: `70 %`,
    initiative: `+2`,
    attacks: [
      `30 % de chances d'appliquer Poison II pour 6 tours (catégorie D + catégorie M-)`
    ],
    abilities: [
      `"Ultrasons": chaque personne aux alentours a 40 % de chances d'être étourdi pendant 1 tour`
    ]
  },
  {
    name: `Vase autonome`,

    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
    health: `40`,
    speed: `2 m/s`,
    hitClose: `60 %`,
    hitMedium: `60 %`,
    attacks: [
      `Fait fondre les armures en peau, tissu ou cuir`,
      `Applique Brûlure III pendant 1 tour au membre du corps touché, puis Saignements III permanent`
    ],
    passives: [
      Monsters.Skills.Intangible,
      Monsters.Skills.AuraRadioactive('II'),
    ]
  },
  {
    name: `Porteur de spores`,

    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
    health: `90`,
    armor: `1`,
    speed: `3 m/s`,
    damage: `12 + 2x1d6`,
    hitClose: `65 %`,
    initiative: `+2`,
    attacks: [
      `Applique Radiations III`
    ],
    passives: [Monsters.Skills.RégénérationRadioactive],
    abilities: [
      `Spores énervantes : libère un nuage de spores qui force les personnes proches à attaquer le Porteur de spores`
    ]
  },
  {
    name: `Prêcheur des profondeurs`,

    types: [Monsters.Type.MUTANT, Monsters.Type.ELEMENTAL],
    health: `130`,
    mana: `40`,
    speed: `2 m/s`,
    attacks: [
      `Applique Radiations III`
    ],
    show: [
      `75% : gagne un point de folie`,
      `60% : rend fou pendant 10 minutes`,
    ],
    abilities: [
      `Psychose généralisée: les personnes proches deviennent folles et gagnent un point de folie`
    ],
    magic: [
      {
        school: `Magie de l'eau`,
        level: `12`
      },
      {
        school: `Magie de la mort`,
        level: `8`
      },
      {
        school: `Magie de l'esprit`,
        level: `6`
      }
    ]
  },
];

export default data;
