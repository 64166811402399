import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Basilisk`,

    types: [Monsters.Type.BEAST],
    health: `130`,
    armor: `10 au dos et 3 partout ailleurs`,
    speed: `4 m/s`,
    damage: `24 + 5x1d6`,
    hitClose: `90 %`,
    initiative: `+4`,
    attacks: [
      `70% : applique Poison IV pour 6 tours, type D+ et M-`,
      `40% : transmets la rage`,
    ],
    passives: [Monsters.Skills.CorpsDePoison(`IV`, 3, `D+ et M-`)]
  },
  {
    name: `Rad-gecko`,

    types: [Monsters.Type.BEAST],
    health: `105`,
    armor: `3`,
    speed: `4 m/s`,
    damage: `24 + 3x1d6`,
    hitClose: `80 %`,
    initiative: `+6`,
    passives: [Monsters.Skills.Group(`5 à 8 [4 + 1d4]`)]
  },
  {
    name: `Rad-poisson`,

    types: [Monsters.Type.BEAST],
    health: `50`,
    speed: `5 m/s`,
    damage: `8 + 4x1d8`,
    hitMedium: `65 % (choc mental)`,
    initiative: `+5`,
    attacks: [
      `Étourdit pendant 2 tours`
    ],
    passives: [
      `Frémissant: réduit les chances d'être touché de 30 %`
    ],
    abilities: [
      `Traînée empoisonnée : libère du poison qui se répand dans les eaux proches et qui, à chaque fin de tour, a 40 % de chances d'appliquer Poison IV pour 6 tours, type D`
    ],
  },
  {
    name: `Chien enragé`,

    types: [Monsters.Type.BEAST],
    health: `80`,
    armor: `2`,
    speed: `4 m/s`,
    damage: `20 + 5x1d4`,
    hitClose: `85 %`,
    initiative: `+6`,
    attacks: [
      `Applique Saignements IV permanent`
    ],
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
    abilities: [Monsters.Skills.Saut(`5x1d6`)]
  },
  {
    name: `Bergaul`,

    types: [Monsters.Type.BEAST],
    health: `140`,
    armor: `4`,
    speed: `5 m/s`,
    damage: `32 + 4x1d8`,
    hitClose: `95 %`,
    initiative: `+2`,
    attacks: [
      `55 % : renverse la cible au sol`
    ],
    abilities: [Monsters.Skills.Piétinement]
  },
];

export default data;
