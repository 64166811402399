import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Chien sauvage`,

    types: [Monsters.Type.BEAST],
    health: `60`,
    speed: `4 m/s`,
    damage: `16 + 1d8`,
    hitClose: `60 %`,
    initiative: `+4`,
    attacks: [
      `25 % : applique Saignements I permanent`,
      `10 % : transmets la Rage`,
    ],
    passives: [Monsters.Skills.Group(`2 à 4 [1 + 1d3]`)]
  },
  {
    name: `Fourmi géante`,

    types: [Monsters.Type.BEAST],
    health: `40`,
    armor: `1`,
    speed: `2 m/s`,
    damage: `12 + 1d6`,
    hitClose: `50 %`,
    attacks: [
      `35 % : applique Poison II pour 10 tours, type M-`
    ]
  },
  {
    name: `Rad-scorpion`,

    types: [Monsters.Type.BEAST],
    health: `70`,
    armor: `12 partout sauf la tête`,
    speed: `3 m/s`,
    damage: `12 + 2x1d6`,
    hitClose: `55 %`,
    initiative: `+2`,
    attacks: [
      `40 % : applique Poison II pour 20 tours, type D`
    ]
  },
  {
    name: `Rad-moustique virulent`,

    types: [Monsters.Type.BEAST],
    health: `15`,
    speed: `2 m/s`,
    damage: `9 + 3x1d6`,
    hitClose: `45 %`,
    initiative: `+6`,
    attacks: [
      `35 % : applique Poison I pour 3 tours, type D`
    ],
    passives: [
      Monsters.Skills.Virevoltant(50),
      Monsters.Skills.Group(`3 à 6 [2 + 1d4]`),
    ]
  },
  {
    name: `Porteur de maladies`,

    types: [Monsters.Type.BEAST],
    health: `25`,
    speed: `4 m/s`,
    damage: `9 + 1d6`,
    hitClose: `60 %`,
    initiative: `+4`,
    attacks: [
      `40 % : applique Radiations III`,
      `35 % : applique Poison II pour 6 tours, type D`,
      `75 % : transmets le Petit Mal`,
      `20 % : transmets la Putride`,
      `10 % : transmets la Rage`,
    ],
    passives: [Monsters.Skills.Virevoltant(40)]
  },
  {
    name: `Infecte rongeur`,

    types: [Monsters.Type.BEAST],
    health: `35`,
    speed: `3 m/s`,
    damage: `12 + 2x1d6`,
    hitClose: `45 %`,
    initiative: `+4`,
    passives: [Monsters.Skills.Group(`2 à 5 [1 + 1d4]`)],
    attacks: [
      `60 % : applique Poison II pour 10 tours, type D+`,
      `30 % : transmets la Putride`,
      `20 % : transmets la Rage`
    ]
  },
  {
    name: `Rad-cerf`,

    types: [Monsters.Type.BEAST],
    health: `110`,
    armor: `2 partout et 4 à la tête`,
    speed: `4 m/s`,
    damage: `12 + 3x1d6`,
    hitClose: `70 %`,
    initiative: `+2`,
    abilities: [Monsters.Skills.Charge(`3`, `6x1d6`)]
  },
];

export default data;
