import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Syla cracheuse de venin`,

    types: [Monsters.Type.PLANT],
    health: `45`,
    damage: `24 + 3x1d6`,
    hitClose: `80 %`,
    hitMedium: `40 %`,
    attacks: [
      `(PC) applique Saignements III permanent`,
      `(PM) 85 % : applique Poison IV pour 6 tours, type M-`,
      `(PM) 35 % : rend fou pendant 12 heures`
    ]
  },
  {
    name: `Fertiliseur`,

    types: [Monsters.Type.PLANT],
    health: `70`,
    armor: `2`,
    speed: `1 m/s`,
    damage: `18 + 2x1d6`,
    hitClose: `80 %`,
    hitMedium: `30 %`,
    attacks: [
      `70% : applique Poison III pour 30 tours, type D+`,
    ],
    passives: [
      `Venin addictif : les personnes touchées par le poison deviennent addict`,
    ]
  },
];

export default data;
