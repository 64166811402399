import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Efrit inférieur`,

    types: [Monsters.Type.ELEMENTAL],
    health: `40`,
    mana: `25`,
    damage: `12 + 1d6`,
    hitClose: `75 %`,
    initiative: `+2`,
    magic: [
      {
        school: `Magie du Feu`,
        level: `8`
      },
    ],
    attacks: [
      `Applique Brûlure II pour 3 tours`
    ],
    passives: [Monsters.Skills.Intangible]
  },
  {
    name: `Golem de glace`,

    types: [Monsters.Type.ELEMENTAL],
    health: `20`,
    armor: `10`,
    mana: `30`,
    damage: `20 + 1d8`,
    hitClose: `80 %`,
    attacks: [
      `Renverse au sol`
    ],
    magic: [
      {
        school: `Magie de l'eau`,
        level: `10`
      },
    ],
  }
];

export default data;
