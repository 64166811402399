import styled from "@emotion/styled";

export const DataCardContainer = styled.div`
  border-radius: 2px;
  box-shadow: 0px 0px 2px DarkSlateGray;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  overflow: hidden;
  text-align: left;
`;
