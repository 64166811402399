import styled from "@emotion/styled";

const Quote = styled.pre`
  animation: quote-movingShadow 5s infinite;
  border-left: solid 2px DarkSlateGray;
  box-shadow: -10px 0 15px -10px DarkSlateGray;
  color: olive;
  letter-spacing: 0.1em;
  margin: 10px 0px;
  padding: 8px 12px;
  text-align: left;
  white-space: pre-line;
`;

export { Quote };
