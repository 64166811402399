import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Rad-ours`,

    types: [Monsters.Type.BEAST],
    health: `380`,
    armor: `6`,
    speed: `4 m/s`,
    damage: `36 + 8x1d6`,
    hitClose: `110 %`,
    attacks: [
      `Applique Saignements II permanent`
    ],
    abilities: [Monsters.Skills.HurlementStrident],
    show: [
      `50% : effraie les personnes proches`,
    ]
  },
  {
    name: `Rad-garou`,

    types: [Monsters.Type.BEAST],
    health: `260`,
    armor: `8`,
    speed: `7 m/s`,
    damage: `40 + 10x1d4`,
    criticalChances: `+5 %`,
    hitClose: `95 %`,
    initiative: `+12`,
    attacks: [
      `Applique Saignements IV permanent`
    ],
    abilities: [
      Monsters.Skills.HurlementStrident,
      `Cri à la lune : les personnes proches doivent faire un test de Sang froid, elles seront effrayées si elles échouent`
    ]
  },
  {
    name: `Araignée géante`,

    types: [Monsters.Type.BEAST],
    health: `190`,
    armor: `4`,
    speed: `5 m/s`,
    damage: `30 + 6x1d6`,
    hitClose: `70 %`,
    initiative: `+4`,
    attacks: [
      `85 % : applique Poison IV pour 6 tours, type D+`
    ],
    show: [`70 % : effraie les individus proches`],
    abilities: [
      `Rets : envoie des rets sur une cible et l'immobilise sur sa position`
    ]
  },
  {
    name: `Rad-serpent`,

    types: [Monsters.Type.BEAST],
    health: `255`,
    armor: `14`,
    speed: `6 m/s`,
    damage: `42 + 5x1d6`,
    hitClose: `80 %`,
    initiative: `+6`,
    attacks: [
      `Applique Poison IV pour 6 tours, type D+`
    ],
    show: [
      `Effraie les individus proches`,
    ],
    abilities: [
      `Constriction : immobilise une cible et augmente sa Fatigue de 1 par tour d'étreinte. La cible ne peut plus respirer quand elle est fatiguée`
    ]
  },
  {
    name: `Rad-crocodile`,

    types: [Monsters.Type.BEAST],
    health: `320`,
    armor: `12`,
    speed: `4 m/s`,
    damage: `48 + 8x1d4`,
    criticalChances: `+3 %`,
    hitClose: `70 %`,
    initiative: `+2`,
    show: [
      `Effraie les individus proches`
    ]
  },
  {
    name: `Rad-Requin`,

    types: [Monsters.Type.BEAST],
    health: `290`,
    armor: `6`,
    speed: `12 m/s`,
    damage: `60 + 5x1d8`,
    criticalChances: `+5 %`,
    hitClose: `60 %`,
    initiative: `+4`,
    show: [
      `Effraie les individus proches`,
    ],
    abilities: [
      `Projections d'encre : applique un malus sur les tests qui nécessite une visibilité dans la zone de 80 %`
    ]
  },
  {
    name: `Ver de vase`,

    types: [Monsters.Type.BEAST],
    health: `115`,
    speed: `2 m/s (6 m/s en souterrain)`,
    initiative: `+4`,
    abilities: [
      `Jet de bave : réduit l'Initiative de 4 et la vitesse de déplacement de la cible de 33 %, cumulable`,
      `Étreinte de poison : s'enroule autour d'une cible immobile et la serre ce qui lui applique trois occurrences de Poison V pour 6 tours (catégorie D+)`,
      Monsters.Skills.Tunnel,
    ],
    passives: [Monsters.Skills.CorpsDePoison(`V`, 6, `D+`)]
  },
];

export default data;
