import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Caravanier`,

    types: [Monsters.Type.HUMAN],
    health: `170`,
    armor: `5`,
    speed: `3 m/s`,
    hitClose: `80 %`,
    hitMedium: `70 %`,
    hitFar: `65 %`,
    initiative: `+6`,
    items: [
      `Arme quelconque, simple ou exotique`,
      `Armure de cuir renforcé complète`
    ],
    skills: [
      {
        skill: `Sciences`,
        value: `65 %`
      },
      {
        skill: `Social`,
        value: `55 %`
      },
      {
        skill: `Défenses`,
        value: `45 %`
      },
      {
        skill: `Maîtrise`,
        value: `40 %`
      }
    ]
  },
  {
    name: `Arbitre`,

    types: [Monsters.Type.HUMAN],
    health: `190`,
    armor: `8`,
    speed: `3 m/s`,
    hitClose: `90 %`,
    items: [
      `Arme de corps-à-corps exotique`,
      `Armure assistée complète`
    ],
    skills: [
      {
        skill: `Défenses`,
        value: `75 %`
      },
      {
        skill: `Maîtrise`,
        value: `60 %`
      }
    ]
  },
  {
    name: `Paladin`,

    types: [Monsters.Type.HUMAN],
    health: `190`,
    armor: `7`,
    speed: `3 m/s`,
    hitClose: `80 %`,
    hitMedium: `85 %`,
    hitFar: `80 %`,
    initiative: `+5`,
    items: [
      `Arme légère à énergie simple ou exotique`,
      `Armure assistée complète`
    ],
    skills: [
      {
        skill: `Sciences`,
        value: `60 %`
      },
      {
        skill: `Social`,
        value: `50 %`
      },
      {
        skill: `Défenses`,
        value: `60 %`
      }
    ]
  },
  {
    name: `Militaire`,

    types: [Monsters.Type.HUMAN],
    health: `220`,
    armor: `8`,
    speed: `3 m/s`,
    criticalChances: `+3 %`,
    hitClose: `60 %`,
    hitMedium: `90 %`,
    hitFar: `90 %`,
    initiative: `+4`,
    items: [
      `Arme légère, simple ou exotique`,
      `Armure assistée complète`
    ],
    skills: [
      {
        skill: `Social`,
        value: `50 %`
      },
      {
        skill: `Défenses`,
        value: `60 %`
      },
      {
        skill: `Maîtrise`,
        value: `40 %`
      }
    ]
  },
  {
    name: `Garde d'élite Adrulen`,

    types: [Monsters.Type.HUMAN],
    health: `180`,
    armor: `6`,
    speed: `3 m/s`,
    hitClose: `100 %`,
    initiative: `+6`,
    items: [
      `Arme de corps-à-corps exotique`,
      `Arme explosive exotique`,
      `Armure assistée exotique complète`
    ],
    skills: [
      {
        skill: `Maîtrise`,
        value: `90 %`
      }
    ]
  },
  {
    name: `Tireur Adrulen`,

    types: [Monsters.Type.HUMAN],
    health: `150`,
    armor: `4`,
    speed: `3 m/s`,
    hitMedium: `100 %`,
    initiative: `+8`,
    items: [
      `Arme légère à énergie exotique`,
      `Arme explosive exotique`,
      `Armure assistée exotique complète`
    ],
    skills: [
      {
        skill: `Maîtrise`,
        value: `90 %`
      },
    ]
  },
];

export default data;
