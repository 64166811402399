import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Mercenaire`,

    types: [Monsters.Type.HUMAN],
    health: `130`,
    armor: `2`,
    speed: `3 m/s`,
    hitClose: `60 %`,
    hitMedium: `45 %`,
    hitFar: `30 %`,
    initiative: `+2`,
    items: [
      `Arme légère ou lourde simple`,
      `Armure de cuir complète`
    ],
    skills: [
      {
        skill: `Armes légères`,
        value: `60 %`
      },
      {
        skill: `Social`,
        value: `40 %`
      }
    ]
  },
  {
    name: `Garde de village`,

    types: [Monsters.Type.HUMAN],
    health: `140`,
    armor: `4`,
    speed: `3 m/s`,
    hitClose: `30 %`,
    hitMedium: `55 %`,
    initiative: `+2`,
    items: [
      `Arme légère simple`,
      `Arme de corps-à-corps simple`,
      `Armure cuir complète`
    ],
  },
  {
    name: `Mécène des forets`,

    types: [Monsters.Type.HUMAN],
    health: `120`,
    armor: `1`,
    speed: `4 m/s`,
    hitClose: `70 %`,
    hitMedium: `15 %`,
    initiative: `+4`,
    items: [
      `Arme de corps à corps simple ou exotique`,
      `Armure de peau ou de cuir complète`
    ],
    skills: [
      {
        skill: `Défense biologique`,
        value: `60 %`
      },
      {
        skill: `Discrétion rurale`,
        value: `70 %`
      },
      {
        skill: `Équilibre`,
        value: `50 %`
      }
    ]
  },
];

export default data;
