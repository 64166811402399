import protection from './protection';
import shield from './shield';
import special from './special';

const all = [
  ...protection,
  ...shield,
  ...special,
];

export default all;

export const Armors = all;
