import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import './AppMenu.scss';

export function AppMenu() {
  return (
    <AppBar className="appMenu" position="fixed" sx={{ bottom: 0, top: 'auto' }}>
      <Toolbar sx={{ justifyContent: 'space-evenly' }}>
        <Outlet />
      </Toolbar>
    </AppBar>
  );
}
