export default Object.freeze([
  ["Dé", "Effet"],
  ["2-", "Pas d’effet"],
  ["3", "Zone sensible, trace de griffure, picotement"],
  ["4", "Contusion légère, éraflure, égratignure"],
  ["5", "Zone touchée douloureuse, entaille superficielle, perforation légère"],
  ["6", "Contusion modérée, coupure légère, perforation modérée"],
  ["7", "Ecchymose, entaille profonde, petite plaie"],
  ["8", "Hématome superficiel, coupure profonde, perforation profonde"],
  ["9", "Grosse ecchymose, balafre, blessure pénétrante"],
  ["10", "Bosse douloureuse, lacération, petite lacération interne"],
  ["11", "Hématome douloureux, lacération sévère, lacération interne modérée"],
  ["12", "Écrasement léger, veines tranchées, lacération interne sévère"],
  ["13", "Contusion musculaire, muscle entaillé, organe légèrement perforé"],
  ["14", "Écrasement musculaire, muscle sévèrement entaillé, organe modérément perforé"],
  ["15", "Fracture simple, artère tranchée, os perforé"],
  ["16", "Fracture compliquée, artère sectionnée, os broyé avec échardes"],
  ["17", "Hémorragie interne légère, os tranché, organe sévèrement perforé"],
  ["18", "Hémorragie interne modérée, membre partiellement sectionné, organe vital perforé"],
  ["19", "Hémorragie interne sévère, membre presque sectionné, organe vital gravement perforé"],
  ["20+", "Rupture d’organe interne, membre totalement sectionné, trou létal"]
]);
