import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppMenu } from 'layout/menus/AppMenu';
import { DataMenu } from 'layout/menus/DataMenu';
import { EmptyMenu } from 'layout/menus/EmptyMenu';
import { GeneratorsMenu } from 'layout/menus/GeneratorsMenu';
import { ManualMenu } from 'layout/menus/ManualMenu';
import { UniverseMenu } from 'layout/menus/UniverseMenu';

export function MenuRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AppMenu />}>
        <Route path="universe/*" element={<UniverseMenu />} />
        <Route path="manual/*" element={<ManualMenu />} />
        <Route path="data/*" element={<DataMenu />} />
        <Route path="generators/*" element={<GeneratorsMenu />} />
        <Route index element={<EmptyMenu />} />
        <Route path="*" element={<EmptyMenu />} />
      </Route>
    </Routes>
  );
}
