import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    name: `Armure assistée militaire`,
    armor: {
      armLeft: `6`,
      armRight: `6`,
      chest: `6`,
      head: `6`,
      legLeft: `6`,
      legRight: `6`,
    },
    weight: 40,
    value: 610,
    effects: `Nécessite de l'énergie (consomme 300 PE par heure)`,
  },
  {
    name: `Armure en acier`,
    armor: {
      armRight: `4`,
      armLeft: `4`,
      chest: `4`,
    },
    weight: 25,
    value: 43,
    effects: `Rouille / Agilité -5`,
  },
  {
    name: `Armure en cuir`,
    armor: {
      armRight: `1`,
      armLeft: `1`,
      chest: `1`,
    },
    weight: 10,
    value: 22,
    effects: `Fragile`,
  },
  {
    name: `Armure en cuir renforcé`,
    armor: {
      armRight: `2`,
      armLeft: `2`,
      chest: `2`,
    },
    weight: 15,
    value: 35,
  },
  {
    name: `Casque en acier`,
    armor: {
      head: `4`,
    },
    weight: 2,
    value: 32,
    effects: `Rouille / Acuité -1`,
  },
  {
    name: `Casque en cuir`,
    armor: {
      head: `1`,
    },
    weight: 1,
    value: 7,
    effects: `Fragile`,
  },
  {
    name: `Jambières en acier`,
    armor: {
      legRight: `4`,
      legLeft: `4`,
    },
    weight: 6,
    value: 29,
    effects: `Rouille`,
  },
  {
    armor: {
      head: `2`,
      armRight: `2`,
      armLeft: `2`,
      chest: `2`,
      legRight: `2`,
      legLeft: `2`,
    },
    description: `Une grande planche de bois taillée qui permet de se cacher entièrement derrière`,
    name: `Protection Vonner`,
    subtype: Subtype.Armor.SHIELD,
    value: 25,
    weight: 8,
  },
  {
    name: `Jambières en cuir renforcé`,
    armor: {
      legRight: `2`,
      legLeft: `2`,
    },
    weight: 4,
    value: 6,
  },
  {
    armor: {
      head: `15`,
      armRight: `15`,
      armLeft: `15`,
      chest: `15`,
      legRight: `15`,
      legLeft: `15`,
    },
    description: `Un bouclier énergétique qui se déclenche lors de mouvements brusques et protège des attaques
    Modèle militaire avec une consommation réduite
    Nécessite de l'énergie (consomme 200 PE par point de blessure bloquée)`,
    name: `Protection Vonner militaire`,
    value: 2950,
    weight: 1,
  },
  {
    armor: {
      head: `8`,
      armRight: `8`,
      armLeft: `8`,
      chest: `8`,
      legRight: `8`,
      legLeft: `8`,
    },
    description: `Un bouclier énergétique qui se déclenche lors de mouvements brusques et protège des attaques
    Il s'agit du modèle "tout public", mais on sait que seuls les plus riches pouvaient se le payer !
    Nécessite de l'énergie (consomme 400 PE par point de blessure bloquée)`,
    name: `Protection Vonner personnelle`,
    value: 1450,
    weight: 1,
  },
  {
    name: `Veste en cuir`,
    armor: {
      armRight: `1`,
      armLeft: `1`,
    },
    weight: 2,
    value: 16,
    effects: `Fragile`,
  },
  {
    armor: {
      legRight: `1`,
      legLeft: `1`,
    },
    effects: `Fragile`,
    name: `Jambières en cuir`,
    value: 9,
    weight: 2,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Armor.PROTECTION,
  type: Type.Item.ARMOR,
}));
