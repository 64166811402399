/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
};

export const displayCards = createSlice({
  name: 'displayCards',
  initialState,
  reducers: {
    toggleDisplay: (state) => {
      state.value = !state.value;
    },
    setDisplayed: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setDisplayed, toggleDisplay } = displayCards.actions;

export default displayCards.reducer;
