import React from 'react';

import { PageHeading } from 'components/elements';

export function ShopGeneratorPage() {
  return (
    <>
      <PageHeading>
        Boutique
      </PageHeading>

      à venir...
    </>
  );
}
