import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Maître de l'air`,

    types: [Monsters.Type.ELEMENTAL],
    health: `130`,
    armor: `4`,
    mana: `40`,
    speed: `3 m/s`,
    damage: `selon arme invoquée`,
    hitClose: `90 %`,
    initiative: `+4`,
    magic: [
      {
        school: `Magie de l'air`,
        level: `12`
      },
      {
        school: `Magie de l'eau`,
        level: `8`
      },
      {
        school: `Magie de la nature`,
        level: `4`
      },
      {
        school: `Magie de l'esprit`,
        level: `4`
      },
    ],
    skills: [
      {
        skill: `Maîtrise`,
        value: `110 %`
      }
    ]
  },
  {
    name: `Ombre vivante`,

    types: [Monsters.Type.ELEMENTAL],
    health: `10`,
    mana: `30`,
    speed: `6 m/s`,
    hitClose: `90 %`,
    initiative: `+6`,
    magic: [
      {
        school: `Magie de la lumière`,
        level: `12`
      },
    ],
    passives: [
      Monsters.Skills.Intangible,
    ],
    show: [
      `40% : effraie les personnes proches`,
    ],
    attacks: [
      `Dématérialise la cible vers le plan des Ombres petit à petit, réduit sa Constitution de 1 pendant 12 tours, cumulable. Arrivé à 0, la cible n'existe plus dans ce plan`,
    ]
  },
  {
    name: `Cauchemar`,

    types: [Monsters.Type.ELEMENTAL],
    health: `90`,
    mana: `30`,
    speed: `4 m/s`,
    damage: `52 + 8x1d6`,
    hitClose: `100 %`,
    initiative: `+4`,
    magic: [
      {
        school: `Magie de la mort`,
        level: `12`
      },
      {
        school: `Magie de l'esprit`,
        level: `6`
      },
    ],
    passives: [
      `Horreur : à la fin de chaque tour, les personnes proches gagnent un point de folie`,
    ],
    show: [
      `Effraie les personnes proches`,
    ]
  }
];

export default data;
