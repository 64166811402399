import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    cost: 2,
    name: `Buée`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
      `composant (de l'eau liquide sur la surface ciblée)`,
    ],
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} -25 (vue)`,
      `Solidification : ${CHARACTER.SKILLS.MASTERY.ACUITY} -25 (vue) supplémentaires`,
      `Surcharge : transforme le sort en brouillard et étend la zone d'effet à 5m²`,
    ],
    description: `Transforme de l'eau en buée sur une surface transparente, ce qui gêne la vision au travers.`,
  },
  {
    cost: 2,
    name: `Changement d'état de l'eau`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
      `relique (de l'eau dans l'état de destination - solide, liquide ou gazeux)`,
    ],
    additionalEffects: [
      `Surcharge : triple la quantité d'eau transformée`,
    ],
    description: `Change l'état de l'eau à raison d'un litre par tour.`,
  },
  {
    cost: 2,
    name: `Courant`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `eau assez profonde et assez calme`,
    ],
    additionalEffects: [
      `Effet : vitesse de nage x 2 ou vitesse de nage ÷ 2`,
      `Surcharge : vitesse de nage x 3 ou vitesse de nage ÷ 3 à la place`,
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
    ],
    description: `Provoque un courant dans un sens précis.`,
  },
  {
    cost: 2,
    name: `Croissance accélérée`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
    ],
    description: `Accélère drastiquement la croissance d'une fleur de telle sorte qu'elle atteigne sa taille maximale en quelques secondes. Ce sort fonctionne aussi pour les champignons.`,
  },
  {
    cost: 2,
    name: `Illusions de vapeur`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `relique (objet de la forme souhaitée)`,
      `composant (de l'eau liquide dans la zone ciblée)`,
    ],
    description: `Crée des formes vaporeuses afin de détourner l'attention d'une personne.`,
  },
  {
    cost: 2,
    name: `Parapluie`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    additionalEffects: [
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
    ],
    description: `Dévie les gouttes de pluie autour d'une personne afin de rester au sec.`,
  },
  {
    cost: 2,
    name: `Passage protégé`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    area: `1 m²`,
    additionalEffects: [
      `Surcharge : triple la zone d'effet et permet de traverser des petites rivières`,
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
    ],
    description: `Crée un passage protégé afin de traverser une ruisseau au sec.`,
  },
  {
    cost: 2,
    name: `Sacoche de racines`,
    types: [
      Spells.Types.SUMMON,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    additionalEffects: [
      `Effet : invoque une sacoche pour transporter des objets`,
      `Surcharge : invoque un sac à dos à la place`,
    ],
    description: `Crée magiquement une petite sacoche de feuilles et de racines qui permet de transporter des objets.`,
  },
  {
    cost: 4,
    name: `Chaînes de glace`,
    types: [
      Spells.Types.SUMMON,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
      `composant (de l'eau liquide au sol dans la zone ciblée)`,
    ],
    additionalEffects: [
      `Effet : immobilise la personne`,
      `Effet si la personne est au sol : inflige l'état de santé « paralysé »`,
      `Surcharge : diminue la température à -5° aux pieds de la personne`,
    ],
    description: `Givre de l'eau au pied d'une personne ce qui l'empêche de se déplacer.`,
  },
  {
    cost: 4,
    name: `Déshydratation`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Effet : inflige l'état de santé « assoiffé »`,
      `Effet : Fatigue +1 sur la personne`,
      `Effet : si la personne devient fatiguée, elle tombe inconsciente`,
      `Surcharge : Fatigue +1 supplémentaire`,
    ],
    description: `Déshydratez une personne.`,
  }, {
    cost: 4,
    name: `Emprise de racines`,
    types: [
      Spells.Types.CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
      `sol friable (terre, sable, boue, …)`,
    ],
    additionalEffects: [
      `Effet : immobilise la personne`,
      `Surcharge : effectue une constriction à chaque tour de canalisation et augmente la Fatigue de la personne de 1. Si elle devient fatiguée alors elle tombe inconsciente`,
    ],
    description: `Invoque des racines dans le sol sous une personne, qui vont la saisir et l'immobiliser.`,
  },
  {
    cost: 4,
    name: `Invocation d'arme`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `relique (arme)`,
      `composant (de l'eau liquide à proximité)`,
    ],
    additionalEffects: [
      `Attention : le malus au combat des élémentaires ne s'applique pas aux armes invoquées`,
      `Surcharge : augmente la solidité des invocations sans qu'elle n'égale une réplique réelle dans le matériau initial`,
    ],
    description: `Invoque magiquement une arme en givre et donc fragile. Il est possible d'invoquer des flèches ou des carreaux avec ce sort, mais pas d'armes à feu.`,
  },
  {
    cost: 4,
    name: `Invocation de tréant`,
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    summon: [
      `Armure : 5`,
      `Taille : 120 cm`,
      `Poids : 60 kg`,
      `Charge transportable : 30 kg`,
    ],
    description: `Invoque magiquement un être vivant fait de bois, un mélange entre un arbre et un humain, capable de porter des choses ou d'attirer l'attention.`,
  },
  {
    cost: 4,
    name: `Patinoire`,
    types: [
      Spells.Types.SUMMON,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
      `composants (de l'eau liquide au sol dans la zone ciblée)`,
    ],
    area: `1 m²`,
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.AGILITY} -40, chances de glisser`,
      `Surcharge : ${CHARACTER.SKILLS.MASTERY.AGILITY} -80`,
    ],
    description: `Crée une zone de gel au sol.`,
  },
  {
    cost: 4,
    name: `Trait de givre`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `composant (de l'eau solide à proximité)`,
    ],
    additionalEffects: [
      `Surcharge : augmente les blessures à 11 + 1d6 à la place`,
    ],
    damage: `7 + 1d4 (perforants)`,
    description: `Transforme de l'eau en un pieu de givre et le projette sur une personne.`,
  },
  {
    cost: 8,
    name: `Aspersion acide`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `Optionnel : relique (extrait d'un poison)`,
    ],
    additionalEffects: [
      `Effet : détruit les armures en tissu, peau ou cuir`,
      `Effet optionnel : inflige le poison à la personne`,
      `Surcharge : endommage les armures en métal ou en acier`,
      `Surcharge : augmente les blessures à 9 + 1d6`,
    ],
    damage: `5 + 1d4`,
    description: `Asperge une personne d'un acide corrosif qui va disparaître après impact. Avec utilisation d'un poison comme relique pour lancer le sort, la personne devient également affectée par ce poison.`,
  },
  {
    cost: 8,
    name: `Explosion de vapeur`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    area: `1 m²`,
    additionalEffects: [
      `Effet : le lanceur gagne +1 en Fatigue`,
      `Effet : repousse de 2 à 4 mètres et renverse au sol`,
      `Surcharge : repousse de 8 à 12 mètres`,
    ],
    description: `Crée une explosion de vapeur dont l'origine est le lanceur, ce qui repousse au loin les personnes proches et les renverse au sol.`,
  },
  {
    cost: 8,
    name: `Grêle`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `relique (un peu d'eau solide)`,
    ],
    area: `1 m²`,
    damage: `7 + 1d4 (contondants)`,
    description: `Invoque des grêlons qui tombent dans la zone alentour.`,
    additionalEffects: [
      `Surcharge : devient un Blizzard et change les blessures à 13 + 1d4 (perforants)`,
    ],
  },
  {
    cost: 8,
    name: `Invocation d'eau`,
    types: [
      Spells.Types.SUMMON,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    additionalEffects: [
      `Attention : un corps aura assimilé l'eau 5 minutes après l'ingestion, l'invocation se relâche automatiquement après ce délai et l'eau ne disparaît pas de la personne qui l'a ingéré`,
      `Surcharge : l'eau est pure`,
    ],
    description: `Invoque magiquement jusqu'à un litre d'eau sale.`,
  },
  {
    cost: 8,
    name: `Statue de givre`,
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `relique (statuette)`,
      `relique (un peu d'eau solide)`,
    ],
    summon: [
      `Toucher : 90 %`,
      `Taille : 160 cm`,
      `Poids : 70 kg`,
    ],
    damage: `8 (contondants + tranchants)`,
    description: `Invoque une statue faite de givre aux côtés du lanceur.`,
  },
  {
    cost: 8,
    name: `Tir d'eau`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    additionalEffects: [
      `Effet : ignore les armures en tissue, peau ou cuir`,
      `Surcharge : augmente les blessures à 13 + 1d6`,
      `Surcharge : ignore tout type d'armure`,
    ],
    damage: `7 + 1d4 (perforants)`,
    description: `Projette une infime quantité d'eau à très grande vitesse pour des dommages maximisés. Cet effet ne peut atteindre que de courtes portées.`,
  },
  {
    cost: 8,
    name: `Tombe aquatique`,
    types: [
      Spells.Types.SUMMON,
      Spells.Types.CHANNEL,
    ],
    schools: [
      { name: Spells.School.WATER_NATURE },
    ],
    requirements: [
      `contact`,
      `contact`,
    ],
    additionalEffects: [
      `Effet : vitesses % 2`,
      `Effet : Initiative -8`,
      `Solidification : change les effets et inflige l'état paralysé`,
      `Vaporisation : devient le sort « Brouillard »`,
    ],
    description: `Créé magiquement une zone remplie d'eau autour d'une personne, ce qui peut la noyer.`,
  },
]);

export default data;
