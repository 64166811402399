import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Gamme YT-O : Assassin`,

    types: [Monsters.Type.ROBOT],
    health: `180`,
    armor: `20`,
    speed: `4 m/s`,
    damageClose: `selon arme`,
    criticalChances: `+5 %`,
    hitClose: `110 %`,
    hitMedium: `80 %`,
    initiative: `+15`,
    actions: `+1`,
    passives: [
      Monsters.Skills.DoubleEsquive,
      `Analyse : reconnaît le membre du groupe à attaquer en premier et quelle partie du corps viser`,
      `Trajectoires assistées : capable de prévisualiser les trajectoires des armes à distance et de les esquiver`,
    ],
    items: [
      `Arme de corps-à-corps exotique`,
      `Armure en cuir renforcé complète`,
      `Armure assistée exotique`
    ],
    skills: [
      {
        skill: `Maîtrise`,
        value: `90 %`
      }
    ]
  },
  {
    name: `Gamme YT-0 : Bourreau`,

    types: [Monsters.Type.ROBOT],
    health: `115`,
    armor: `60`,
    speed: `4 m/s`,
    damageClose: `48 + 15x1d4`,
    criticalChances: `+3 %`,
    hitClose: `120 %`,
    initiative: `+10`,
    actions: `+1`,
    passives: [
      `Aura intimidante : les personnes proches subissent l'état "Fasciné" à chaque tour`,
      `Attaques horrifiques : les personnes qui témoignent des dégâts d'une attaque gagnent un point de folie`,
    ],
  },
  {
    name: `Gamme YT-O : Big Daddy`,

    types: [Monsters.Type.ROBOT],
    health: `225`,
    armor: `35`,
    speed: `3 m/s`,
    damageClose: `56 + 8x1d8`,
    hitClose: `120 %`,
    passives: [
      `Attaques horrifiques : les personnes qui témoignent des dégâts d'une attaque gagnent un point de folie`,
    ],
    show: [
      `40% : effraie les personnes proches`,
    ],
    abilities: [
      Monsters.Skills.Charge(`2`, `4x1d6`),
    ]
  },
  {
    name: `Gamme YT-O : Assemblage T-26`,

    types: [Monsters.Type.ROBOT],
    health: `130`,
    armor: `40`,
    speed: `4 m/s`,
    damageClose: `35 + 4x1d8 (mains nues)`,
    damageMedium: `30 + 6x1d6 (mitraillette)`,
    damageFar: `40 + 10x1d4 (sniper)`,
    criticalChances: `6 %`,
    hitClose: `80 %`,
    hitMedium: `55 %`,
    hitFar: `90 %`,
    initiative: `+8`,
    actions: `+1`
  },
];

export default data;
