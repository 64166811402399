import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    armor: { armRight: `8` },
    description: `Un bracelet qui peut déclencher une protection énergétique circulaire de diamètre d'environ 50 centimètres`,
    name: `Bouclier de protection Plasma`,
    value: 163,
    weight: 2,
    effects: `Nécessite de l'énergie (consomme 100 PE par heure)`,
  },
  {
    armor: { armRight: `4` },
    description: `Un bouclier rond en acier simple d'environ 1 centimètre d'épaisseur`,
    name: `Bouclier en acier`,
    value: 49,
    weight: 12,
  },
  {
    armor: { armRight: `2` },
    description: `Un bouclier rond en bois simple d'environ 2 centimètres d'épaisseur`,
    name: `Bouclier en bois`,
    value: 14,
    weight: 4,
  },
  {
    armor: { armRight: `3` },
    description: `Une carapace de tortue mutante travaillée, une pièce plutôt rare`,
    name: `Carapace de tortue mutante taillée`,
    value: 76,
    weight: 10,
  },
  {
    armor: {
      head: `6`,
      armRight: `6`,
      armLeft: `6`,
      chest: `6`,
      legRight: `6`,
      legLeft: `6`,
    },
    description: `Un pavois extrêmement lourd derrière lequel se cacher
    N'est généralement pas porté mais transporté et déposé`,
    name: `Mur mobile de protection en titanium`,
    value: 850,
    weight: 50,
  },
  {
    armor: {
      head: `4`,
      armRight: `4`,
      armLeft: `4`,
      chest: `4`,
      legRight: `4`,
      legLeft: `4`,
    },
    description: `Une grande plaque en acier avec une vitre au niveau du visage, utilisé comme protection lors du contrôle des émeutes`,
    name: `Pavois antiémeute en acier`,
    value: 124,
    weight: 18,
    effects: `Nécessite de l'énergie (consomme 150 PE par heure)`,
  },
  {
    armor: {
      head: `8`,
      armRight: `8`,
      armLeft: `8`,
      chest: `8`,
      legRight: `8`,
      legLeft: `8`,
    },
    description: `Un bracelet large qui peut déclencher une protection rectangulaire d'environ 150 centimètres de hauteur pour 80 centimètres de largeur`,
    name: `Pavois antiémeute Plasma`,
    value: 326,
    weight: 3,
  },
  {
    armor: {
      head: `2`,
      armRight: `2`,
      armLeft: `2`,
      chest: `2`,
      legRight: `2`,
      legLeft: `2`,
    },
    description: `Une grande planche de bois taillée qui permet de se cacher entièrement derrière`,
    name: `Pavois en bois`,
    value: 25,
    weight: 8,
  },
];


export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Armor.SHIELD,
  type: Type.Item.ARMOR,
}));
