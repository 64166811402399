import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Cochon des bois`,

    types: [Monsters.Type.BEAST, Monsters.Type.PLANT],
    health: `75`,
    armor: `2`,
    speed: `3 m/s`,
    damage: `18 + 2x1d6`,
    hitClose: `65 %`,
    hitMedium: `55 %`,
    initiative: `+2`,
    attacks: [
      `Applique Radiations II`,
      `(PM) 40 % : applique Poison II pour 3 tours, type D`
    ],
    abilities: [Monsters.Skills.Charge(`2`, `4x1d6`)],
    death: [
      `Nuage de poison: libère un nuage empoisonné qui a 60 % de chances d'appliquer Poison II pour 10 tours (catégorie D)`
    ]
  },
  {
    name: `Magicien renégat`,

    types: [Monsters.Type.ELEMENTAL],
    health: `120`,
    armor: `4`,
    mana: `20`,
    damage: `selon arme invoquée`,
    hitClose: `45 %`,
    hitMedium: `55 %`,
    initiative: `+3`,
    magic: [
      {
        school: `Magie du Feu`,
        level: `8`
      },
    ],
    skills: [
      {
        skill: `Sang froid`,
        value: `0 %`
      },
    ]
  },
];

export default data;
