import { Element, Type } from 'data/metadata';

const data = [
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Démangeaisons',
    description: `Une partie du corps tirée gratte en permanence
Contre : Test de Volonté pour tenir 1 heure
Social -10`,
    left: '1-2',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Craquements',
    description: `Craque les articulations en permanence
Contre : Test de Volonté pour tenir 1 heure
Social -10
Intimidation +10`,
    left: '3-4',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Tic de langage',
    description: `Tic de langage, comme tirer la langue ou faire une grimace
Contre : Test de Volonté pour tenir 1 heure
Social -25`,
    left: '5-6',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Cauchemars',
    description: `Cauchemars toutes les nuits sans souvenir
Gêne le repos des autres
${CHARACTER.SKILLS.MASTERY.SUBTLETY} -20 lors du  repos`,
    left: '7-8',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Bégaiements',
    description: `Contre : Test de Volonté pour tenir 1 heure
Social -10
Intimidation -20`,
    left: '9-10',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Echolalie',
    description: `Répète systématiquement les derniers mots ou dernières syllabes prononcées par soit ou des personnes proches
Social -25`,
    left: '11-12',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Inversion du langage',
    description: `Inverse les mots et les phrases
Social -30`,
    left: '13-14',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Coprolalie',
    description: `Jure ou crie parfois, sans raison
Contre : Test de Sang froid pour tenir 1 tour
Social -20`,
    left: '15-16',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Zozotement',
    description: 'Social -20',
    left: '17-18',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Distraction',
    description: `Se déconcentre facilement et perde ses affaires
    Perspicacité -50`,
    left: '19-20',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Somniloquie',
    description: `Parle pendant le sommeil
Gêne le repos des autres
${CHARACTER.SKILLS.MASTERY.SUBTLETY} -40 lors du repos`,
    left: '21-22',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Carpophobie',
    description: `Peur des fruits
« Phobie » lors de la situation`,
    left: '23-24',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Onychrophagie',
    description: `Se ronge les ongles
Contre : Test de Volonté à -20 pour tenir 1 heure
Social -10
Discours -40`,
    left: '25-26',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Boulimie',
    description: `Mange tant qu'il y a de la nourriture disponible
Contre : Test de Volonté à -40 pour tenir 1 heure`,
    left: '27-28',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Ithyphallophobie',
    description: `Peur de voir des pénis en érection
« Phobie » lors de la situation`,
    left: '29-30',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Bave incontrôlée',
    description: `Bave en permanence
Social -60`,
    left: '31-32',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Glossophobie',
    description: `Effrayé de s'exprimer en public
« Phobie » lors de la situation`,
    left: '33-34',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Narcissime',
    description: `Il n'y a plus que sa propre personne qui compte à ses yeux
Contre : Test de Volonté à -60 pour tenir 1 heure`,
    left: '35-36',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Pédiophobie',
    description: `Peur atroce des poupées
« Phobie » lors de la situation`,
    left: '37-38',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Tremblote',
    description: `Tremble en permanence
Contre : Test de Volonté pour tenir 1 heure
Actions avec les mains -40`,
    left: '39-40',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Nosophobie',
    description: `Peur d'être malade ou infecté
« Phobie » lors de la situation`,
    left: '41-42',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Anorexie',
    description: `Incapable de supporter la vue de la nourriture
Contre : Test de Volonté à -40 pour tenir 1 heure`,
    left: '43-44',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Musicophobie',
    description: `Craint la musique ou toute sonorité musicale
« Phobie » lors de la situation`,
    left: '45-46',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Syllogomanie',
    description: `Besoin compulsif de tout collectionner
Contre : Test de Volonté à -60 pour tenir 1 heure`,
    left: '47-48',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Incontinence',
    description: `Impossible de se retenir d'émettre des flatulences ou de déféquer
Contre : Test de Vigueur pour tenir 1 heure`,
    left: '49-50',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Achmophobie',
    description: `Terreur des objets pointus et des aiguilles
« Phobie » lors de la situation`,
    left: '51-52',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Acrophobie',
    description: `Peur bleue des hauteurs
« Phobie » lors de la situation
Provoque des vertiges`,
    left: '53-54',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Introversion',
    description: `Maladroit socialement
Contre : Test de Volonté à -40 pour tenir 1 heure
Social -60`,
    left: '55-56',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Fabulation',
    description: `Impossible de dire la vérité
Contre : Test de Volonté à -60 pour tenir 1 heure`,
    left: '57-58',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Automysophobie',
    description: `Horrifié d'être sale ou de sentir mauvais
« Phobie » lors de la situation`,
    left: '59-60',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Stress constant',
    description: `Tendu, agité et sur le qui-vive
Contre : Test de Volonté à -40 pour tenir 1 heure
Social -40
Initiative +2`,
    left: '61-62',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Achluophobie',
    description: `Peur de l'obscurité et du noir
« Phobie » lors de la situation`,
    left: '63-64',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Astraphobie',
    description: `Effrayé par le tonnerre et la foudre
« Phobie » lors de la situation`,
    left: '65-66',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Soliloquie',
    description: `Marmonne constamment dans un baragouinage inintelligible
Contre : Test de Volonté à -20 pour tenir 1 heure
Social -60
${CHARACTER.SKILLS.MASTERY.SUBTLETY} -60`,
    left: '67-68',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Nécrophobie',
    description: `Les cadavres deviennent le pire cauchemar possible
« Phobie » lors de la situation`,
    left: '69-70',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Aerophobie',
    description: `Peur étonnante de l'air et du vent
« Phobie » lors de la situation`,
    left: '71-72',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Migraines',
    description: `De violentes migraines surviennent après chaque réflexion, arrive après chaque test de Perspicacité
-15 partout pendant 1d10 heures lors de la situation`,
    left: '73-74',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Pyrophobie',
    description: `Peur du feu
« Phobie » lors de la situation`,
    left: '75-76',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Catatonie',
    description: `Lorsque d'une surprise ou d'une peur, après un échec sur un test de Sang froid, perd tout contact avec la réalité
Paralysé pendant 1d10 heures lors de la situation`,
    left: '77-78',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Aquaphobie',
    description: `Peur de l'eau
« Phobie » lors de la situation`,
    left: '79-80',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Cleptomanie',
    description: `La vie n'a plus qu'un but, voler
Contre : Test de Volonté à -40 pour tenir 1 heure
Doit voler tout ce qui est possible`,
    left: '81',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Hématophobie',
    description: `La perception du sang paralyse d'effroi
« Phobie » lors de la situation`,
    left: '82',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Aphenphosmophobie',
    description: `L'idée d'être touché être insupportable, et l'être réellement encore moins
« Phobie » lors de la situation`,
    left: '83',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Mégalomanie',
    description: `La soif de pouvoir n'a aucune limite et le montre aux yeux de tous
Devient hostile à toute manifestation d'autorité extérieure`,
    left: '84',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Claustrophobie',
    description: `Se sent oppressé par les endroits clos
Sang froid -60 dans ces lieux`,
    left: '85',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Ochlophobie',
    description: `Effrayé par la foule
Contre : Tests de Sang froid à -40 pour tenir 1 heure
Social -60 dans cette situation`,
    left: '86',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Agoraphobie',
    description: `Se sent en insécurité dans les lieux publics et les espaces ouverts
Sang froid -60 dans ces lieux`,
    left: '87',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Stasophobie',
    description: `Peur d'avoir à rester debout trop longtemps
Contre : Tests de Sang froid à -60 pour tenir 1 heure
Impossible de se lever sans savoir le chemin rempli d'assises`,
    left: '88',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Basophobie',
    description: `Peur de marcher
Contre : Tests de Sang froid à -60 pour tenir 1 heure
Incapable de marcher`,
    left: '89',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Dysmorphophobie',
    description: `L'idée ni soutenir la vue des anomalies physiques est insupportable
« Phobie » lors de la situation`,
    left: '90',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Témérité',
    description: `Ne connaît plus la peur et fonce quel que soit le danger d'une situation
Sang froid +80
Incapable d'hésiter`,
    left: '91',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Thanatophobie',
    description: `Peur de la mort, comme Jack Sparrow face à Davy Jones
Contre : Test de Sang froid à -60 pour 1 heure
Impossible de tuer ni de voir la mort`,
    left: '92',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Dépersonalisation',
    description: `La moralité et l'alignement changent en permanence en fonction de petites variations comme une personne qui passe devant, ou une mouche`,
    left: '93',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Cyclothymie',
    description: `L'humeur varie de la joie la plus intense à la plus profonde déprime ou vers une irritabilité excessive par un petit changement comme le décor ou la météo`,
    left: '94',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Amnésie',
    description: `Oublie le passé irrémédiablement
Contre : Tout les jours sous un test de Volonté à -60, il est possible de retrouver la mémoire avant l'incident mais oublie tout de la vie post-traumatique
Les compétences sont conservées mais sans mémoire de les avoir`,
    left: '95',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Dépression',
    description: `N'éprouve plus aucun intérêt pour la vie et n'a plus d'espoirs en l'avenir
Social -80`,
    left: '96',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Algophobie',
    description: `La grande crainte est la douleur
Contre : Tests de Sang froid à -60 pour tenir 1 heure
Impossible d'infliger des douleurs à qui que ce soit, et toute perception de cette dernière pétrifie d'effroi`,
    left: '97',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Pantophobie',
    description: `Peur de tout
Sang froid -100`,
    left: '98',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Schizophrénie',
    description: `La perception de la réalité semble distordue aux yeux des autres par l'incapacité à la partager et le comportement face aux différentes situations s'en retrouve bien souvent disproportionné
Toutes les heures, adopte une des folies entre :
Amnésie temporaire, Dépression, Fabulation, Introversion, Cleptomanie, Manie, Mégalomanie ou Témérité`,
    left: '99',
  },
  {
    element: Element.RULE,
    type: Type.Rule.MADNESSES,
    name: 'Psychopathie',
    description: `La perception émotive est réduite à presque néant, les sentiments de crainte et d'empathie ne sont qu'un souvenir et les réactions d'égocentrisme, d'impulsivité, d'irresponsabilité et de manipulation deviennent maintenant la routine
Adopte généralement un comportement antisocial avec un mode de vie criminel et instable`,
    left: '100',
  },
];

export default data;
