import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';

import { PageRootLayout } from 'layout/PageRootLayout';
import { SectionLayout } from 'layout/SectionLayout';

import {
  ArmorPage,
  BestiaryPage,
  CharacterGeneratorPage,
  CharacterPage,
  DamagePage,
  DrugsPage,
  EnvironmentPage,
  GiftPage,
  HomePage,
  ItemPage,
  MadnessPage,
  MagicPage,
  ManualPage,
  MeleePage,
  ModifiersPage,
  MonsterPage,
  NotFoundPage,
  PassivesPage,
  RacePage,
  RangedPage,
  RoundPage,
  ShopGeneratorPage,
  SkillsPage,
  SpecialPage,
  SpellPage,
  StatesPage,
  StatisticsPage,
  TabletopRPGPage,
  WarningPage,
  WeaponPage,
} from 'pages';

export function PageRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<PageRootLayout />}>
          <Route index element={<HomePage />} />

          <Route path="intro/" element={<SectionLayout />}>
            <Route index element={<TabletopRPGPage />} />
          </Route>

          <Route path="universe/" element={<SectionLayout />}>
            <Route index element={<WarningPage />} />
          </Route>

          <Route path="manual/" element={<SectionLayout />}>
            <Route index element={<ManualPage />} />

            <Route path="character/">
              <Route path="creation" element={<CharacterPage />} />
              <Route path="race" element={<RacePage />} />
              <Route path="skills" element={<SkillsPage />} />
              <Route path="passives" element={<PassivesPage />} />
              <Route path="statistics" element={<StatisticsPage />} />
              <Route path="magic" element={<MagicPage />} />
            </Route>

            <Route path="fight/">
              <Route path="melee" element={<MeleePage />} />
              <Route path="modifiers" element={<ModifiersPage />} />
              <Route path="ranged" element={<RangedPage />} />
              <Route path="round" element={<RoundPage />} />
              <Route path="special" element={<SpecialPage />} />
              <Route path="damage" element={<DamagePage />} />
            </Route>

            <Route path="other/">
              <Route path="environment" element={<EnvironmentPage />} />
              <Route path="drugs" element={<DrugsPage />} />
              <Route path="states" element={<StatesPage />} />
              <Route path="madness" element={<MadnessPage />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="data/" element={<SectionLayout />}>
            <Route path="gifts" element={<GiftPage />} />
            <Route path="spells" element={<SpellPage />} />
            <Route path="items" element={<ItemPage />} />
            <Route path="weapons" element={<WeaponPage />} />
            <Route path="armors" element={<ArmorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="generators/" element={<SectionLayout />}>
            <Route path="character" element={<CharacterGeneratorPage />} />
            <Route path="shop" element={<ShopGeneratorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="bestiary/">
            <Route index element={<BestiaryPage />} />
            <Route path="*" element={<MonsterPage />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
          {/* <Route path=":teamId" element={<Team />} /> */}
        </Route>
      </Routes>
    </AnimatePresence>
  );
}
