export default Object.freeze({
  FULL: [
    'Parler ou crier (10-12 mots)',
    'Se déplacer de 8-9m',
    'Attaquer',
    'Incanter un sort',
    'Fouiller un sac',
    'Se reposer',
  ],
  FAST: [
    'Parler ou crier (5-6 mots)',
    'Se déplacer de 4-5m',
    'Engainer',
    'Dégainer',
    'Recharger',
    'Viser',
    'Préparer un sort',
    'Parer',
    'Esquiver',
    'Prendre un objet à portée de main',
    'Utiliser un objet',
  ],
  FREE: [
    'Parler ou crier (1-2 mots)',
    'Se décaler (environ 1 mètre)',
    'Analyser une situation',
    'Maintenir un sort'
  ],
});
