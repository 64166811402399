import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Rad-cafard`,

    types: [Monsters.Type.BEAST],
    health: `25`,
    armor: `1 (Dos)`,
    speed: `2 m/s`,
    damage: `2x1d6`,
    hitClose: `40 %`,
    attacks: [
      `25 % : applique Radiations II`
    ],
    abilities: [Monsters.Skills.Envol(40)]
  },
  {
    name: `Mante géante`,

    types: [Monsters.Type.BEAST],
    health: `30`,
    speed: `2 m/s`,
    damage: `6 + 1d6`,
    initiative: `+2`,
    hitClose: `50 %`,
    attacks: [
      `25 % : applique Poison I pour 6 tours, type M-`
    ]
  },
  {
    name: `Rad-moustique`,

    types: [Monsters.Type.BEAST],
    health: `15`,
    speed: `3 m/s`,
    damage: `4 + 1d4`,
    hitClose: `65 %`,
    initiative: `+4`,
    attacks: [
      `15 % : applique Saignements I pour 6 tours`
    ],
    passives: [Monsters.Skills.Virevoltant(25)]
  },
  {
    name: `Rad-crabe`,

    types: [Monsters.Type.BEAST],
    health: `30`,
    armor: `10 partout sauf la tête`,
    speed: `2 m/s`,
    damage: `6 + 1d6`,
    hitClose: `45 %`,
    attacks: [
      `15 % : applique Radiations I et Poison I pour 3 tours, type D`
    ],
    abilities: [Monsters.Skills.Enfouissement]
  },
  {
    name: `Brahmine`,

    types: [Monsters.Type.BEAST],
    health: `80`,
    speed: `2 m/s`,
    damage: `6 + 2x1d6`,
    hitClose: `35 %`,
    abilities: [Monsters.Skills.Charge(`2`, `4x1d6`)]
  },
  {
    name: `Rad-Bélier`,

    types: [Monsters.Type.BEAST],
    health: `45`,
    armor: `4 à la tête`,
    speed: `3 m/s`,
    damage: `4 + 1d8`,
    initiative: `+1`,
    hitClose: `55 %`,
    abilities: [Monsters.Skills.Charge(`2`, `4x1d6`)]
  },
  {
    name: `Rataupe`,

    types: [Monsters.Type.BEAST],
    health: `35`,
    armor: `1`,
    speed: `4 m/s`,
    damage: `6 + 2x1d6`,
    hitClose: `45 %`,
    initiative: `+4`,
    abilities: [Monsters.Skills.Tunnel]
  },
];

export default data;
