import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Amyrexia`,

    types: [Monsters.Type.PLANT],
    health: `30`,
    damage: `6 + 2x1d6`,
    hitClose: `45 %`,
    hitMedium: `25 %`,
    attacks: [
      `Applique Poison II pour 3 tours, type D`
    ],
    passives: [Monsters.Skills.RégénérationRadioactive],
    abilities: [
      `Spores : libère des spores aux alentours qui ont 60 % de chances d'appliquer Poison pour 20 tours, type H`
    ]
  },
  {
    name: `Mycognon`,

    types: [Monsters.Type.PLANT],
    health: `45`,
    armor: `2`,
    hitClose: `55 %`,
    speed: `2 m/s`,
    attacks: [
      `Applique Poison III pour 3 tours, type D`
    ],
    abilities: [
      `Nuage de poison : libère un vaste nuage qui a 70% de chances d'appliquer Poison III pour 10 tours, type D+`,
    ],
    death: [
      `Spores empoisonnées : des spores transparentes ont 50% de chances d'appliquer Poison H à chaque tour pendant 10 tours`
    ]
  },
];

export default data;
