import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Goule des bois`,

    types: [Monsters.Type.MUTANT],
    health: `110`,
    mana: `20`,
    armor: `4`,
    speed: `3 m/s`,
    damageClose: `24 + 2x1d6`,
    damageMedium: `16 + 4x1d4`,
    hitClose: `75 %`,
    hitMedium: `55 %`,
    initiative: `+3`,
    attacks: [
      `Applique Saignement II pour 3 tours`
    ],
    magic: [
      {
        school: `Magie de Terre`,
        level: `4`
      },
      {
        school: `Magie de Vie`,
        level: `8`
      },
      {
        school: `Magie de Mort`,
        level: `2`
      }
    ]
  },
  {
    name: `Mutant sylvestre`,

    types: [Monsters.Type.MUTANT],
    health: `120`,
    armor: `2`,
    speed: `3 m/s`,
    damage: `24 + 2x1d8`,
    hitClose: `70 %`,
    attacks: [
      `applique Radiations II`
    ],
    abilities: [Monsters.Skills.HurlementStrident]
  },
  {
    name: `Goule du desert`,

    types: [Monsters.Type.MUTANT],
    health: `110`,
    mana: `15`,
    armor: `4`,
    speed: `3 m/s`,
    damage: `32 + 3x1d8`,
    hitClose: `80 %`,
    initiative: `+2`,
    attacks: [
      `Applique Saignements II permanent`
    ],
    magic: [
      {
        school: `Magie de Vie`,
        level: `10`
      }
    ]
  },
  {
    name: `Super-mutant`,

    types: [Monsters.Type.MUTANT],
    health: `175`,
    armor: `4`,
    speed: `4 m/s`,
    hitClose: `65 %`,
    hitMedium: `50 %`,
    hitFar: `25 %`,
    initiative: `+2`,
    items: [
      `Arme lourde simple ou exotique`,
      `Armure de cuir partielle`
    ],
    skills: [
      {
        skill: `Intimidation`,
        value: `55 %`
      }
    ],
    attacks: [
      `(PC) 80 % : renverse la cible au sol`
    ]
  },
  {
    name: `Roi fangeux`,

    types: [Monsters.Type.MUTANT],
    health: `105`,
    armor: `5`,
    speed: `2 m/s`,
    damage: `30 + 3x1d6`,
    hitClose: `65 %`,
    hitMedium: `45 %`,
    initiative: `+2`,
    attacks: [
      `Applique Radiations III`,
      `(PM) 35 % : paralyse pendant 2 tours`
    ],
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 5 Fangeux [1 + 1d4]`,
      Monsters.Skills.Chef('Fangeux', 2),
    ]
  },
];

export default data;
