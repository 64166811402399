import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Pestenfer`,

    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
    health: `80`,
    armor: `8`,
    speed: `4 m/s`,
    damage: `18 + 3x1d6`,
    hitClose: `90 %`,
    initiative: `+6`,
    attacks: [
      `Rend fou pendant 1 heure`,
      `Applique Radiations IV`,
      `Applique Poison IV pour 6 tours, type M-`,
      `50% : transmets le Petit Mal`,
      `25% : transmets la Peste Bubonique`,
    ],
    abilities: [Monsters.Skills.Envol(30)]
  },
  {
    name: `Rad-scorpion alpha`,

    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
    health: `125`,
    armor: `16 partout sauf au tronc et à la tête`,
    speed: `5 m/s`,
    damage: `30 + 5x1d6`,
    hitClose: `85 %`,
    initiative: `+3`,
    items: [
      `Arme de corps à corps exotique`
    ],
    attacks: [
      `Applique Radiations IV`,
      `70 % : applique Poison III pour 6 tours (catégorie D+)`,
    ],
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 7 Radscorpions [1 + 1d6]`,
      Monsters.Skills.Chef('Radscorpions', 4),
      `Regard pétrifiant : après un tour de contact oculaire, transforme la cible en pierre`,
    ],
    abilities: [
      `Regard envoûtant : force la cible à regarder dans les yeux pendant 3 tours, contré avec un test de Volonté`,
    ]
  },
  {
    name: `Fourmante alpha`,

    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
    health: `140`,
    armor: `10`,
    speed: `4 m/s`,
    damage: `24 + 4x1d8`,
    hitClose: `80 %`,
    initiative: `+4`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 4 à 9 Fourmantes [3 + 1d6]`,
      Monsters.Skills.Chef('Fourmantes', 6),
    ]
  },
  {
    name: `Tréant`,

    types: [Monsters.Type.PLANT, Monsters.Type.ELEMENTAL],
    health: `220`,
    mana: `40`,
    armor: `10`,
    speed: `2 m/s`,
    damage: `40 + 4x1d8`,
    hitClose: `80 %`,
    abilities: [Monsters.Skills.Tremblement],
    magic: [
      {
        school: `Magie de la nature`,
        level: `12`
      },
    ]
  },
  {
    name: `Moréant`,

    types: [Monsters.Type.PLANT, Monsters.Type.ELEMENTAL],
    health: `170`,
    mana: `50`,
    armor: `2`,
    speed: `2 m/s`,
    damage: `28 + 6x1d8`,
    hitClose: `75 %`,
    attacks: [
      `60% : transmets la Putride`
    ],
    abilities: [
      `Odeur putride : les personnes proches tombent inconscientes si elles échouent un test de Volonté`
    ],
    magic: [
      {
        school: `Magie de la mort`,
        level: `12`
      },
    ]
  },
  {
    name: `Vase mutante`,

    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
    health: `70`,
    speed: `2 m/s`,
    damage: `12 + 2x1d6`,
    hitClose: `65 %`,
    attacks: [
      `Applique Radiations IV`,
      `Applique Poison IV pour 6 tours (catégorie D+)`
    ],
    passives: [
      Monsters.Skills.Flasque,
      Monsters.Skills.AuraRadioactive('III'),
      Monsters.Skills.RégénérationRadioactive,
    ],
    abilities: [
      `Nova de poison : les personnes proches ont 35 % de chances de se voir appliquer Poison V pour 3 tours (catégorie D)`
    ]
  },
  {
    name: `Techno-mutant`,

    types: [Monsters.Type.MUTANT, Monsters.Type.ROBOT],
    health: `200`,
    armor: `25`,
    speed: `2 m/s`,
    damage: `48 + 4x1d8`,
    hitClose: `70 %`,
    attacks: [
      `60 % : renverse la cible au sol`
    ],
    show: [
      `80 % : effraie les individus proches`
    ]
  },
];

export default data;
