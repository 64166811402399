import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import styles from './Label.module.css';

export function Label({ children, color, ...restProps }) {
  return (
    <Box className={`${styles.box} ${styles[color]}`} component="span" {...restProps}>
      {children}
    </Box>
  );
}

Label.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['darkgreen', 'lightgreen', 'darkgray', 'lightgray']),
};

Label.defaultProps = {
  color: 'darkgreen',
};
