const aptitudes = [
  {
    name: `Anthopologue`,
    effect: [`Chances de succès critique lors d'attaques contre des humanoïdes +10%`],
  },
  {
    name: `Commercial`,
    effect: [`Réduit le prix des objets achetés d'environ 30%`],
  },
  {
    name: `Dur à cuire`,
    effect: [`Résistance aux effets de froid et de chaud augmentée de 3 degrés`],
  },
  {
    name: `Entraînement intense`,
    effect: [`La limite de points dépensés dans une compétence sélectionnée est doublée`],
    usable: 3,
  },
  {
    name: `Éponge à radiations`,
    effect: [`Les gains de radiations soignent les blessures`],
  },
  {
    name: `Esprit entraîné`,
    effect: [`Magie +5`],
  },
  {
    name: `Expert en chimie`,
    effect: [`Double la durée des substances chimiques injectées`],
  },
  {
    name: `Expert en démolition`,
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.THROWING} +4`,
      `Feu et Terre +5`,
    ]
  },
  {
    name: `Fana d'armes`,
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.LIGHT} +4`,
      `Vent et Foudre +5`
    ]
  },
  {
    name: `Généticien`,
    effect: [`Chances de succès critique lors d'attaques contre des mutants +10%`],
  },
  {
    name: `Internement`,
    effect: [
      `Supprime une folie < 51`,
      `Supprime les points de Psychose`,
    ],
  },
  {
    name: `La taille compte`,
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.HEAVY} +4`,
      `Lumière et Vie +5`
    ]
  },
  {
    name: `Nécrophile`,
    effect: [
      `Seuil de Psychose +3`,
      `Ombre et Mort +5`,
    ],
  },
  {
    name: `Optimisme infaillible`,
    effect: [
      `Il n'est plus nécessaire de faire de tests de Volonté pour dépasser sa Fatigue`,
      `Moral +3`,
    ],
  },
  {
    name: `Poings de fer`,
    effect: [
      `${CHARACTER.SKILLS.WEAPONS.MELEE} +4`,
      `Eau et Nature +5`,
    ]
  },
  {
    name: `Professeur de Yoga`,
    effect: [
      `Double la Magie récupérée par l'action de Méditation`,
      `Le premier tour de maintien de sort ne coûte pas de Magie`,
    ]
  },
  {
    name: `Reins solides`,
    effect: [`Charge transportable doublée`]
  },
  {
    name: `Roboticien`,
    effect: [`Chances de succès critique lors d'attaques contre des robots +10%`],
  },
  {
    name: `Veinard`,
    effect: [`Points de chance +3`],
    usable: 2,
  },
  {
    name: `Zoologue`,
    effect: [`Chances de succès critique lors d'attaques contre des bêtes +10%`],
  },
];

export default aptitudes.map((a) => ({ ...a, level: 2 }));
