import React from 'react';
import styled from "@emotion/styled";

const EmptyMenuTitle = styled.div`
  color: white;
  font-family: 'Scorchedearth';
  font-size: 1.2em;
  letter-spacing: 14px;
  text-shadow: 0px 0px 4px white;
`;

export function EmptyMenu() {
  return (
    <EmptyMenuTitle>
      AFTERWORLD
    </EmptyMenuTitle>
  );
}
