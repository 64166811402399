import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Rad-araignée`,

    types: [Monsters.Type.BEAST],
    health: `60`,
    armor: `1`,
    speed: `4 m/s`,
    damage: `15 + 3x1d6`,
    hitClose: `65 %`,
    initiative: `+2`,
    attacks: [
      `75 % : applique Poison III pour 20 tours, type M-`
    ],
    abilities: [
      `Jet de soie : 50 % de chances d'aveugler la cible`,
      `Sol gluant : réduit la vitesse de déplacement de la cible dans la zone affectée de moitié`,
    ]
  },
  {
    name: `Rad-scorpion géant`,

    types: [Monsters.Type.BEAST],
    health: `90`,
    armor: `16 partout sauf 8 à la tête`,
    speed: `4 m/s`,
    damage: `24 + 3x1d6`,
    hitClose: `70 %`,
    initiative: `+4`,
    attacks: [
      `55 % : applique Poison III pour 30 tours, type D`
    ]
  },
  {
    name: `Rataupe alpha`,

    types: [Monsters.Type.BEAST],
    health: `85`,
    armor: `2`,
    speed: `3 m/s`,
    damage: `20 + 4x1d4`,
    hitClose: `70 %`,
    initiative: `+2`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 7 Rataupe [1 + 1d6]`,
      Monsters.Skills.Chef('Rataupes', 2),
    ],
    abilities: [Monsters.Skills.Tunnel]
  },
  {
    name: `Yao guai`,

    types: [Monsters.Type.BEAST],
    health: `140`,
    armor: `3`,
    speed: `5 m/s`,
    damage: `32 + 3x1d8`,
    hitClose: `75 %`,
    show: [
      `40 % de chances d'effrayer les individus proches`,
      `Chances d'effrayer augmentées de 20 % si furtif`
    ]
  },
  {
    name: `Rad-mouche géante`,

    types: [Monsters.Type.BEAST],
    health: `30`,
    armor: `1`,
    speed: `3 m/s`,
    damage: `16 + 1d8`,
    hitClose: `95 %`,
    hitMedium: `85 %`,
    hitFar: `60 %`,
    passives: [Monsters.Skills.Virevoltant(40)],
    attacks: [
      `45% : applique Poison IV pour 10 tours, type M-`
    ],
  },
  {
    name: `Rad-tortue`,

    types: [Monsters.Type.BEAST],
    health: `90`,
    armor: `35 partout sauf à la tête`,
    speed: `2 m/s`,
    damage: `12 + 3x1d6`,
    hitClose: `85 %`,
    hitMedium: `60 %`,
    initiative: `+2`,
    attacks: [
      `55 % : applique Radiations III`
    ],
    abilities: [Monsters.Skills.Enfouissement]
  },
  {
    name: `Traqueur nocturne`,

    types: [Monsters.Type.BEAST],
    health: `75`,
    armor: `2`,
    speed: `4 m/s`,
    damage: `24 + 2x1d6`,
    hitClose: `75 %`,
    initiative: `+3`,
    attacks: [
      `40 % : applique Poison III pour 6 tours, type D`
    ],
    passives: [
      `Nyctalope : voit aussi bien de nuit`
    ],
    abilities: [Monsters.Skills.Saut(`5x1d6`)]
  },
];

export default data;
