import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';

import monsters from 'data/monsters';
import { Image, Title, Table } from 'components/semantic';
import { Label, PageHeading } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';

const mappingStatToIcon = {
  health: 'heart-pulse',
  mana: 'meteor',
  armor: 'shield-halved',
  speed: 'person-running',
  initiative: 'gauge-high',
  actions: 'stopwatch',
  hitClose: 'hand-fist',
  damageClose: 'gun',
  hitMedium: 'crosshairs',
  damageMedium: 'gun',
  hitFar: 'location-crosshairs',
  damageFar: 'gun',
  criticalChances: 'explosion',
  damage: 'gun',
};

export function MonsterPage() {
  const params = useParams();
  const monsterName = atob(params['*']);
  const monster = monsters.find((datum) => datum.name === monsterName);
  const {
    abilities,
    attacks,
    death,
    items,
    magic,
    name,
    passives,
    show,
    skills,
    types,
  } = monster;

  const base64name = btoa(name);

  const monsterData = Object.keys(mappingStatToIcon)
    .filter((stat) => !!monster[stat])
    .map((stat) => ([
      <FontAwesomeIcon key="health" icon={['fa', mappingStatToIcon[stat]]} />,
      monster[stat],
    ]));

  const monsterCapabilities = [];

  if (show) {
    show.forEach((s) => {
      monsterCapabilities.push([<Label color="lightgreen" key={s}>Apparition</Label>, s]);
    });
  }

  if (death) {
    death.forEach((d) => {
      monsterCapabilities.push([<Label color="lightgreen" key={d}>Decès</Label>, d]);
    });
  }

  if (passives) {
    passives.forEach((passive) => {
      monsterCapabilities.push([<Label color="lightgreen" key={passive}>Passif</Label>, passive]);
    });
  }

  if (attacks) {
    attacks.forEach((attack) => {
      monsterCapabilities.push([<Label color="lightgreen" key={attack}>Attaque</Label>, attack]);
    });
  }

  if (abilities) {
    abilities.forEach((ability) => {
      monsterCapabilities.push([<Label color="lightgreen" key={ability}>Technique</Label>, ability]);
    });
  }

  if (skills) {
    skills.forEach(({ skill, value }) => {
      monsterCapabilities.push([<Label color="lightgreen" key={skill}>{`Compétence - ${skill}`}</Label>, value]);
    });
  }

  if (magic) {
    magic.forEach(({ school, level }) => {
      monsterCapabilities.push([<Label color="lightgreen" key={school}>{`École - ${school}`}</Label>, level]);
    });
  }

  if (items) {
    items.forEach((item) => {
      monsterCapabilities.push([<Label color="lightgreen" key={item}>Objet</Label>, item]);
    });
  }

  return (
    <PageRootContainer target="/bestiary">
      <PageHeading backButton={(<Link to="/bestiary">
        < FontAwesomeIcon icon={['fa', 'arrow-left']} />
      </Link>)
      }>
        {name}
      </PageHeading >

      <Title>{types.join(' ')}</Title>

      <Image size="large" src={`/images/monsters/${base64name}.webp`}></Image>

      <Title>Statistiques</Title>

      <Table data={monsterData}></Table>

      {!!monsterCapabilities.length && (
        <>
          <Title>Competences</Title>
          <Table data={monsterCapabilities}></Table>
        </>
      )}
    </PageRootContainer >
  );
}
