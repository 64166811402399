export default Object.freeze([
  [`Cible mobile`, `-15 %`],
  [`Cible en course`, `-30 %`],
  [`Tir en hauteur`, `+15 %`],
  [`Tir en contrebas`, `-30 %`],
  [`Tir en cloche`, `-60 %`],
  [`Cible plus grande`, `+10 %`],
  [`Cible plus petite`, `-10 %`],
  [`Tir localisé`, `-25 %`],
]);
