import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Cérébrobot`,

    types: [Monsters.Type.ROBOT],
    health: `35`,
    armor: `6 partout sauf à la tête`,
    speed: `2 m/s`,
    damage: `12 + 1d6`,
    hitClose: `60 %`,
    hitMedium: `25 %`
  },
  {
    name: `Eyebot`,

    types: [Monsters.Type.ROBOT],
    health: `50`,
    armor: `10`,
    speed: `1 m/s`,
    damage: `10 + 1d8`,
    hitClose: `60 %`,
    hitMedium: `30 %`,
    initiative: `+2`,
    passives: [Monsters.Skills.Virevoltant(20)]
  },
  {
    name: `Spiderbot`,

    types: [Monsters.Type.ROBOT],
    health: `20`,
    armor: `6`,
    speed: `3 m/s`,
    damage: `9 + 1x6`,
    hitClose: `60 %`,
    initiative: `+4`,
    passives: [Monsters.Skills.MouvementsErratiques(40)]
  },
];

export default data;
