import Box from '@mui/material/Box';
import React from 'react';

import { PageHeading } from 'components/elements';
import { SwipeBackToMenu } from 'layout/animations/SwipeBackToMenu';

import styles from './NotFoundPage.module.css';

export function NotFoundPage() {
  return (
    <SwipeBackToMenu>
      <PageHeading>
        Travaux
      </PageHeading>

      <Box className={styles.text}>
        Cette page est encore en travaux, desole !
      </Box>
    </SwipeBackToMenu>
  );
}
