import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Érudit vagabond`,

    types: [Monsters.Type.ELEMENTAL],
    health: `130`,
    mana: `40`,
    speed: `3 m/s`,
    initiative: `+2`,
    magic: [
      {
        school: `Magie d'un élément`,
        level: `12`
      },
      {
        school: `Magie de la mort`,
        level: `8`
      }
    ]
  },
  {
    name: `Goliath`,

    types: [Monsters.Type.ELEMENTAL],
    health: `140`,
    mana: `30`,
    armor: `20`,
    speed: `3 m/s`,
    damage: `24 + 4x1d8`,
    hitClose: `75 %`,
    magic: [
      {
        school: `Magie de Terre`,
        level: `8`
      },
      {
        school: `Magie de l'Air`,
        level: `6`
      }
    ]
  },
  {
    name: `Golem de glace irradiée`,

    types: [Monsters.Type.ELEMENTAL],
    health: `80`,
    mana: `30`,
    armor: `12`,
    speed: `3 m/s`,
    damage: `18 + 5x1d4`,
    hitClose: `80 %`,
    magic: [
      {
        school: `Magie de l'eau`,
        level: `12`
      },
    ],
    attacks: [
      `50% : applique Radiations III`,
      `50% : applique Saignements III permanent`,
    ]
  }
];

export default data;
