import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import items from 'data/items';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { Subtype } from 'data/metadata';
import { Item } from 'components/afterworld/data/Item';
import { PageHeading } from 'components/elements';

export function ItemPage() {
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const filteredData = items
    .filter((datum) =>
      (!filter || datum.subtype === filter)
      && (!search || datum.name.toLowerCase().includes(search))
    );

  return (
    <>
      <PageHeading>
        Objets
      </PageHeading>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <InputLabel id="afterworld-items__list-filter__label">
          Type d&apos;objet
        </InputLabel>

        <Select
          id="afterworld-items__list-filter"
          label="Type d'objet"
          labelId="afterworld-items__list-filter__label"
          onChange={(e) => { setFilter(e.target.value); }}
          size="small"
          value={filter}
        >
          <MenuItem value=""><em>Aucun filtre</em></MenuItem>
          {Object.values(Subtype.General).map((itemType) => (
            <MenuItem key={itemType} value={itemType}>{itemType}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-items__list-search"
          label="Rechercher l'objet"
          onChange={(e) => { setSearch(e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        mobileComponent={Item}
      />
    </>
  );
}
