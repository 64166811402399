import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { debounce } from 'lodash';

/* Must be first so the other imports do have the global data */
import 'data/globalData';

import App from 'App';
import reportWebVitals from 'reportWebVitals';

import store, { saveState } from './store';

import './fontawesome';
import './styles/main.scss';

store.subscribe(
  debounce(() => {
    saveState(store.getState());
  }, 1000),
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
