import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    effect: `Produit de la lumière et un peu de chaleur
    Consomme 5 PE par heure`,
    name: `Ampoule`,
    note: `Nécessite une source d'énergie électrique`,
    value: 2,
    weight: 0.05,
  },
  {
    name: `Argent d'avant-guerre`,
    note: `Une liasse de billets avec des nombres inscrits dessus`,
    weight: 0.01,
  },
  {
    effect: `Capacité énergétique de 2400 PE
    Alimente jusqu'à 120 PE par heure`,
    name: `Batterie de voiture`,
    note: `Une batterie lourde qui permet d'alimenter de gros appareils électriques`,
    value: 32,
    weight: 20,
  },
  {
    effect: `Capacité énergétique de 400 PE
    Alimente jusqu'à 40 PE par heure`,
    name: `Batterie Lithium-ion portable`,
    note: `Une batterie portable qui permet d'alimenter des petits appareils électriques`,
    value: 18,
    weight: 0.5,
  },
  {
    effect: `Nécessite de l'énergie pour fonctionner - en cas de panne, les objets sont perdus
    Consomme 10 PE par heure`,
    name: `Boîte de stockage quantique`,
    note: `Une petite boîte de stockage où il est possible de ranger une quantité infinie d'objets
    Dispose d'un petit écran qui permet de consulter les objets présents à l'intérieur
    La version 10³ est la plus courante et mesure 10 centimètres de longueur, largeur et hauteur
    Il existe des version 15³ et 20³ mais beaucoup plus rares et onéreuses`,
    value: 6,
    weight: 0.2,
  },
  {
    effect: `Maintient du feu et produit un peu de lumière`,
    name: `Bougie`,
    note: `Nécessite du feu pour l'allumer`,
    value: 6,
    weight: 0.2,
  },
  {
    effect: `Contient jusqu'à 2 en objets`,
    name: `Bourse ou banane`,
    note: `Idéal pour y ranger ses capsules, peut contenir jusqu'à 200 capsules`,
    value: 3,
    weight: 0.2,
  },
  {
    effect: `Produit du feu`,
    name: `Briquet`,
    note: `Nécessite du carburant pour fonctionner`,
    value: 8,
    weight: 0.02,
  },
  {
    name: `Brosse`,
    note: `Pour rester coiffé·e`,
    value: 1,
    weight: 0.1,
  },
  {
    name: `Capsule de bouteille`,
    note: `Monnaie dans le monde`,
    value: 1,
    weight: 0.01,
  },
  {
    name: `Ceinture en cuir`,
    note: `Pour maintenir des pantalons trop grands`,
    value: 7,
    weight: 0.2,
  },
  {
    effect: `Endort quelqu'un qui le respise`,
    name: `Chloroforme`,
    note: `Le parfum qui fait tomber les demoiselles!
    Dans une petite bouteille en plastique`,
    value: 21,
    weight: 0.3,
  },
  {
    name: `Corde`,
    note: `Un mètre de corde robuste`,
    value: 4,
    weight: 0.1,
  },
  {
    name: `Crayon ou stylo`,
    note: `Permet d'écrire ou dessiner`,
    value: 3,
    weight: 0.01,
  },
  {
    effect: `Réduit les méfaits du désarmement`,
    name: `Dragonne`,
    note: `Il faut l'attacher sur l'arme ou l'objet et l'enrouler autour de son poignet`,
    value: 14,
    weight: 0.05,
  },
  {
    name: `Étui pour arme`,
    note: `Permet d'y ranger une arrme à feu pour l'avoir à portée de main`,
    value: 8,
    weight: 0.4,
  },
  {
    name: `Ferraille`,
    note: `Des morceaux de ferraille divers et variés`,
    value: 3,
    weight: 1,
  },
  {
    name: `Fourreau`,
    note: `Permet d'y ranger une arme comme une épée`,
    value: 10,
    weight: 0.2,
  },
  {
    name: `Fusée éclairante`,
    note: `Déclenche une fusée colorée qui permet d'indiquer une position`,
    value: 16,
    weight: 1,
  },
  {
    name: `Grappin`,
    note: `Attaché à une corde et envoyé en hauteur, permet d'y grimper`,
    value: 8,
    weight: 2,
  },
  {
    effect: `Pour voir très loin !
    ${CHARACTER.SKILLS.MASTERY.ACUITY} +20 (vue) lors de son utilisation de jour`,
    name: `Jumelles`,
    value: 24,
    weight: 1,
  },
  {
    effect: `Produit de la lumière
    Consomme 10 PE par heure`,
    name: `Lampe torche`,
    note: `Fonctionne généralement avec des piles chimiques`,
    value: 32,
    weight: 0.2,
  },
  {
    name: `Loupe`,
    note: `Permet de brûler des fourmis avec le soleil … ou de mieux voir`,
    value: 3,
    weight: 0.2,
  },
  {
    name: `Lunettes infra-rouge ou ultra-violet`,
    note: `Permet de voir au delà du spectre de lumière naturel des yeux`,
    value: 75,
    weight: 0.5,
  },
  {
    name: `Marteau`,
    note: `Pour taper sur des choses`,
    value: 2,
    weight: 2,
  },
  {
    effect: `Donne l'heure de la journée`,
    name: `Montre`,
    note: `Fonctionne généralement à l'énergie solaire`,
    value: 7,
    weight: 0.1,
  },
  {
    name: `Morceau de papier`,
    note: `Pratique pour y écrire des choses`,
    value: 2,
    weight: 0.05,
  },
  {
    effect: `Capacité énergétique illimité en cas d'ensoleillement
    Alimente jusqu'à 10 PE par heure`,
    name: `Panneau photo-voltaïque portable`,
    note: `Un petit panneau à déployer qui permet d'alimenter des petits appareils électriques`,
    value: 65,
    weight: 10,
  },
  {
    name: `Paquet de cigarettes`,
    note: `Celles d'avant-guerre sont plus rares`,
    value: 28,
    weight: 0.04,
  },
  {
    effect: `Permet de chuter en sécurité mais plus lentement`,
    name: `Parachute`,
    note: `Les versions d'avant-guerre sont plus sécurisées mais plus rares`,
    value: 68,
    weight: 10,
  },
  {
    effect: `Protège de la pluie`,
    name: `Parapluie`,
    value: 3,
    weight: 0.5,
  },
  {
    effect: `Capacité énergétique de 8000 PE
    Alimente jusqu'à 500 PE par heure`,
    name: `Pile à fission`,
    note: `Une lourde pile qui fonctionne à l'énergie nucléaire et permet d'alimenter de gros appareils électriques`,
    value: 85,
    weight: 4,
  },
  {
    effect: `Capacité énergétique de 80 PE
    Alimente jusqu'à 10 PE par heure`,
    name: `Pile chimique`,
    note: `Une petite pile à réactif chimique qui permet d'alimenter des petits appareils électriques`,
    value: 12,
    weight: 0.01,
  },
  {
    name: `Pompe`,
    note: `Permet de déplacer un fluide`,
    value: 7,
    weight: 1,
  },
  {
    name: `Poulie`,
    note: `Permet de créer un mécanisme de tractation avec une corde`,
    value: 4,
    weight: 0.5,
  },
  {
    effect: ``,
    name: `Roue de vélo`,
    note: `C'est une forme très circulaire`,
    value: 2,
    weight: 2,
  },
  {
    effect: `Contient jusqu'à 25 en objets`,
    name: `Sac à dos de ville`,
    note: `Un petit sac à dos de ville`,
    value: 18,
    weight: 0.5,
  },
  {
    effect: `Contient jusqu'à 50 en objets`,
    name: `Sac à dos de randonnée`,
    note: `Un gros sac à dos de randonnée, pas très pratique pour y retrouver ses affaires`,
    value: 23,
    weight: 2,
  },
  {
    effect: `Pour passer une bonne nuit`,
    name: `Sac de couchage`,
    note: ``,
    value: 11,
    weight: 2,
  },
  {
    effect: `Contient jusqu'à 5 en objets`,
    name: `Sacoche`,
    note: `Une petite sacoche de ville à porter en bandouillère`,
    value: 6,
    weight: 0.5,
  },
  {
    effect: `Aide à faire un feu`,
    name: `Silex`,
    note: `Une petite roche aiguisée`,
    value: 1,
    weight: 0.1,
  },
  {
    name: `Tasse ou verre`,
    note: `Un récipent qui peut contenir du liquide`,
    value: 2,
    weight: 0.3,
  },
  {
    name: `Tente`,
    note: `Du confort pour passer une très bonne nuit de sommeil`,
    value: 16,
    weight: 4,
  },
  {
    effect: `Aide au crochetage`,
    name: `Tournevis`,
    note: `Permet de visser ou dévisser ... entre autres.`,
    value: 5,
    weight: 0.1,
  },
  {
    effect: `Aide au crochetage`,
    name: `Trombone`,
    note: `Maintien du papier ensemble ... ou permet de crocheter des serrures`,
    value: 2,
    weight: 0.001,
  },
  {
    name: `Tube médical`,
    note: `Tube qui permet de faire se déplacer des liquides`,
    value: 19,
    weight: 0.05,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.General.MISCELLANEOUS,
  type: Type.Item.GENERAL,
}));
