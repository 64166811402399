import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

import './ArmorData.scss';

import { ArmorDataArea } from 'components/afterworld/data/ArmorDataArea';

export function ArmorData({
  armor,
  border,
}) {
  if (!Object.keys(armor).length) return null;

  const {
    armLeft,
    armRight,
    chest,
    head,
    legLeft,
    legRight,
  } = armor;

  return (
    <Box className={`armor-data-box ${border ? 'bordered' : ''}`}>
      <Box className="armor-data">
        <ArmorDataArea value={head}>Tête</ArmorDataArea>
      </Box>

      <Box className="armor-data">
        <ArmorDataArea value={armLeft}>BG</ArmorDataArea>
        <ArmorDataArea value={chest}>Torse</ArmorDataArea>
        <ArmorDataArea value={armRight}>BD</ArmorDataArea>
      </Box>

      <Box className="armor-data">
        <ArmorDataArea value={legLeft}>JG</ArmorDataArea>
        <ArmorDataArea value={legRight}>JD</ArmorDataArea>
      </Box>
    </Box>
  );
}

ArmorData.propTypes = {
  armor: PropTypes.shape({
    armLeft: PropTypes.string,
    armRight: PropTypes.string,
    chest: PropTypes.string,
    head: PropTypes.string,
    legLeft: PropTypes.string,
    legRight: PropTypes.string,
  }),
  border: PropTypes.bool,
};

ArmorData.defaultProps = {
  armor: {},
  border: false,
};
