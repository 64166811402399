export const Subtype = Object.freeze({
  General: {
    DRINK: 'Boisson',
    FOOD: 'Nourriture',
    MEDICAL: 'Médical',
    MISCELLANEOUS: 'Divers',
    UTILITIES: 'Utilitaires',
  },
  Weapon: {
    AMMUNITION: 'Munitions',
    EXPLOSIVES: 'Explosifs & Lancer',
    HEAVY: 'Armes lourdes',
    LIGHT: 'Armes légères',
    MELEE: 'Corps-à-corps',
  },
  Armor: {
    PROTECTION: 'Protection',
    SHIELD: 'Bouclier',
    SPECIAL: 'Spécial',
  },
});
