import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Cyborg sauvage`,

    types: [Monsters.Type.ROBOT],
    health: `125`,
    armor: `8`,
    speed: `3 m/s`,
    hitClose: `40 %`,
    hitMedium: `70 %`,
    hitFar: `50 %`,
    initiative: `+6`,
    items: [
      `Arme légère à énergie simple`,
      `Armure de tissu ou de cuir complète`
    ],
    skills: [
      {
        skill: `Sciences`,
        value: `60 %`
      },
    ]
  },
  {
    name: `Protectron`,

    types: [Monsters.Type.ROBOT],
    health: `55`,
    armor: `18`,
    speed: `2 m/s`,
    damage: `12 + 2x1d8`,
    hitClose: `70 %`,
    hitMedium: `30 %`,
    initiative: `+2`,
    attacks: [
      `30% : paralyse pour 3 tours`
    ]
  },
  {
    name: `Sentinelle`,

    types: [Monsters.Type.ROBOT],
    health: `55`,
    armor: `15`,
    speed: `2 m/s`,
    damage: `24 + 3x1d6`,
    hitClose: `65 %`,
    hitMedium: `45 %`,
    abilities: [Monsters.Skills.Alarme]
  },
  {
    name: `Tourelle Mark II`,

    types: [Monsters.Type.ROBOT],
    health: `60`,
    armor: `6`,
    damage: `16 + 2x1d8`,
    hitClose: `85 %`,
    hitMedium: `60 %`,
    hitFar: `25 %`,
    initiative: `+8`,
    abilities: [Monsters.Skills.Feinte]
  }
];

export default data;
