import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Bulbe à sommeil`,

    types: [Monsters.Type.PLANT],
    health: `20`,
    hitClose: `60 %`,
    hitMedium: `60 %`,
    attacks: [
      `Applique Poison I pour 20 tours, type H`,
    ],
    death: [
      `Nuage de poison : libère un nuage de poison qui a 30% de chances d'appliquer Poison I pour 20 tours, type H`,
    ]
  },
  {
    name: `Spéléla venimeux`,

    types: [Monsters.Type.PLANT],
    health: `30`,
    armor: `1`,
    speed: `1 m/s`,
    damage: `4 + 1d8`,
    hitClose: `40 %`,
    attacks: [
      `Applique Radiations I`,
      `35% : applique Poison II pour 10 tours, type D`,
    ],
  },
];

export default data;
