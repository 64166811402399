import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Danseur`,

    types: [Monsters.Type.ELEMENTAL],
    health: `25`,
    speed: `2 m/s`,
    damage: `3 + 1d6`,
    hitClose: `50 %`,
    skills: [
      {
        skill: `Souplesse`,
        value: `120 %`
      }
    ],
    passives: [
      Monsters.Skills.DoubleEsquive,
      `Être magique : façonne un sort à l'aide d'une danse`
    ]
  },
  {
    name: `Tempête vivante`,

    types: [Monsters.Type.ELEMENTAL],
    health: `30`,
    mana: `20`,
    speed: `3 m/s`,
    passives: [Monsters.Skills.Intangible]
  },
  {
    name: `Marée mouvante`,

    types: [Monsters.Type.ELEMENTAL],
    health: `70`,
    mana: `15`,
    speed: `2 m/s`,
    damage: `1d6`,
    hitClose: `40 %`,
    magic: [
      {
        school: `Magie de l'eau`,
        level: `6`
      }
    ],
    passives: [Monsters.Skills.Flasque]
  }
];

export default data;
