import React from 'react';

import { PageHeading } from 'components/elements';
import { Table, Text, Title } from 'components/semantic';
import lightingModifiers from 'data/rules/lightingModifiers';
import weatherModifiers from 'data/rules/weatherModifiers';
import coverModifiers from 'data/rules/coverModifiers';

export function ModifiersPage() {
  return (
    <>
      <PageHeading>
        Modificateurs
      </PageHeading>

      <Text>
        {`Certains paramètres sont également à prendre en compte lors des combats car ils peuvent impacter l'issue des actions.`}
      </Text>

      <Title>La luminosite</Title>
      <Table data={lightingModifiers}></Table>

      <Title>La meteo</Title>
      <Table data={weatherModifiers}></Table>

      <Title>Cible a couvert</Title>
      <Table data={coverModifiers}></Table>
    </>
  );
}
