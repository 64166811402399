import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    cost: 2,
    name: `Démon mineur`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Figurine démoniaque)`,
      `composant (Extrait de bulbe à sommeil)`,
      `composant (Crâne de mante géante)`,
      `composant (Oeil de Rataupe)`,
    ],
    description: `Force un démon mineur à venir dans ce plan depuis le chaos magique qui règne entre les plans.`,
  },
  {
    cost: 2,
    name: `Dieu inférieur`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Figurine héroïque)`,
      `composant (Foie de Yao Guai)`,
      `composant (Antenne de Rad-cafard)`,
      `composant (Corne de Rad-bélier)`,
    ],
    description: `Force un dieu inférieur à venir dans ce plan depuis le plan divin.`,
  },
  {
    cost: 2,
    name: `Élémentaire inférieur`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Bouteille pleine de l'élément concerné)`,
      `composant (Pétale de Spéléla)`,
      `composant (Crâne de mante géante)`,
      `composant (Glande de Rad-moustique)`,
    ],
    description: `Force un élémentaire inférieur à venir dans ce plan depuis le plan élémentaire, il sera du même élément que la relique utilisée.`,
  },
  {
    cost: 2,
    name: `Esprit paisible`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Pensée en bouteille)`,
      `composant (Corne de Rad-cerf)`,
      `composant (Antenne de Rad-cafard)`,
      `composant (Pince de Rad-crabe)`,
    ],
    description: `Force un esprit paisible à venir dans ce plan depuis le plan des esprits.`,
  },
  {
    cost: 2,
    name: `Fée inférieure`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Bouteille pleine de poussière de météorite)`,
      `composant (Patte de Rad-gecko)`,
      `composant (Glande de Rad-moustique)`,
      `composant (Pince de Rad-crabe)`,
    ],
    description: `Force une fée inférieure à venir dans ce plan depuis le plan astral.`,
  },
  {
    cost: 2,
    name: `Résilience planaire`,
    types: [
      Spells.Types.CHANNEL,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
    ],
    description: `Permet à une personne de conserver son intégrité lorsqu'elle visite un autre plan, et donc de ne pas souffrir des transformations du séjour planaire.`,
  },
  {
    cost: 2,
    name: `Ombre mineure`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Petite ombre en bouteille)`,
      `composant (Queue de Skaven de l'ombre)`,
      `composant (Oeil de Rataupe)`,
      `composant (Sang de Brahmine)`,
    ],
    description: `Force une ombre mineure à venir dans ce plan depuis le plan des ombres.`,
  },
  {
    cost: 2,
    name: `Retour d'un mort paisible`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Organe pourri conservé)`,
      `composant (Fiole de vase)`,
      `composant (Corne de Rad-bélier)`,
      `composant (Sang de Brahmine)`,
    ],
    description: `Force un mort paisible à venir dans ce plan depuis le plan des morts.`,
  },
  {
    cost: 4,
    name: `Démon majeur`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Statue démoniaque)`,
      `composant (Extrait d'Amyrexia)`,
      `composant (Dent de chien)`,
      `composant (Pince de Pestenfer)`,
    ],
    description: `Force un démon majeur à venir dans ce plan depuis le chaos magique qui règne entre les plans.`,
  },
  {
    cost: 4,
    name: `Dieu supérieur`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Statue héroïque)`,
      `composant (Rein de cochon des bois)`,
      `composant (Mandibule de fourmante)`,
      `composant (Queue de Rad-scorpion)`,
    ],
    description: `Force un dieu supérieur à venir dans ce plan depuis le plan divin.`,
  },
  {
    cost: 4,
    name: `Élémentaire supérieur`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Tonneau plein de l'élément concerné)`,
      `composant (Feuille de Neothelid)`,
      `composant (Dent de chien)`,
      `composant (Soie de Rad-araignée)`,
    ],
    description: `Force un élémentaire supérieur à venir dans ce plan depuis le plan élémentaire, il sera du même élément que la relique utilisée.`,
  },
  {
    cost: 4,
    name: `Esprit hostile`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Rêve en bouteille)`,
      `composant (Racine de tréant)`,
      `composant (Écaille de Rad-poisson)`,
      `composant (Mandibule de fourmante)`,
    ],
    description: `Force un esprit hostile à venir dans ce plan depuis le plan des esprits.`,
  },
  {
    cost: 4,
    name: `Fée supérieure`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Bouteille pleine de poussière de lune)`,
      `composant (Patte de Mycognon)`,
      `composant (Écaille de Rad-poisson)`,
      `composant (Soie de Rad-araignée)`,
    ],
    description: `Force une fée supérieure à venir dans ce plan depuis le plan astral.`,
  },
  {
    cost: 4,
    name: `Ombre moyenne`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Grande ombre en bouteille)`,
      `composant (Pétale de lotus noir)`,
      `composant (Carapace de Rad-tortue)`,
      `composant (Pince de Pestenfer)`,
    ],
    description: `Force une ombre moyenne à venir dans ce plan depuis le plan des ombres.`,
  },
  {
    cost: 4,
    name: `Retour d'un mort hostile`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Cadavre pourri conservé)`,
      `composant (Racine de moréant)`,
      `composant (Carapace de Rad-tortue)`,
      `composant (Queue de Rad-scorpion)`,
    ],
    description: `Force un mort hostile à venir dans ce plan depuis le plan des morts.`,
  },
  {
    cost: 4,
    name: `Transplanage`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `composant (Carapace de Rad-tortue)`,
      `composant (Dent de chien)`,
      `composant (Écaille de Rad-poisson)`,
      `composant (Mandibule de fourmante)`,
      `composant (Pince de Pestenfer)`,
      `composant (Queue de Rad-scorpion)`,
      `composant (Soie de Rad-araignée)`,
    ],
    description: `Voyage vers un autre plan adjacent au vôtre.`,
  },
  {
    cost: 8,
    name: `Avatar élémentaire`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Conteneur plein de l'élément concerné)`,
      `composant (Croc de Basilisk)`,
      `composant (Gelée de ver de vase)`,
      `composant (Oeil de Rad-requin)`,
    ],
    description: `Force un avatar élémentaire à venir dans ce plan depuis le plan élémentaire, il sera du même élément que la relique utilisée.`,
  },
  {
    cost: 8,
    name: `Dieu du panthéon`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Monument héroïque)`,
      `composant (Cerveau de Rad-mouche)`,
      `composant (Estomac de Rad-crocodile)`,
      `composant (Museau de Rad-ours)`,
    ],
    description: `Force un dieu du panthéon à venir dans ce plan depuis le plan divin.`,
  },
  {
    cost: 8,
    name: `Esprit agité`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Psychose en bouteille)`,
      `composant (Épine de Cactopus)`,
      `composant (Croc de Rad-serpent)`,
      `composant (Estomac de Rad-crocodile)`,
    ],
    description: `Force un esprit agité à venir dans ce plan depuis le plan des esprits.`,
  },
  {
    cost: 8,
    name: `Portail planaire`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `composant (Cerveau d'écorcheur)`,
      `composant (Croc de Rad-serpent)`,
      `composant (Estomac de Rad-crocodile)`,
      `composant (Gelée de ver de vase)`,
      `composant (Museau de Rad-ours)`,
      `composant (Oeil de Rad-requin)`,
      `composant (Queue de Rad-garou)`,
    ],
    description: `Crée un portail vers un autre plan adjacent au vôtre.`,
  },
  {
    cost: 8,
    name: `Reine fée`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Bouteille pleine de poussière de 7 planètes)`,
      `composant (Patte de Bergaul)`,
      `composant (Croc de Rad-serpent)`,
      `composant (Gelée de ver de vase)`,
    ],
    description: `Force une reine fée à venir dans ce plan depuis le plan astral.`,
  },
  {
    cost: 8,
    name: `Retour d'un mort dérangé`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Cimetière de cadavres pourris conservés)`,
      `composant (Main de Clampignon)`,
      `composant (Cerveau d'écorcheur)`,
      `composant (Museau de Rad-ours)`,
    ],
    description: `Force un mort dérangé à venir dans ce plan depuis le plan des morts.`,
  },
  {
    cost: 8,
    name: `Seigneur démon`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Monument démoniaque)`,
      `composant (Extrait de Syla cracheuse de venin)`,
      `composant (Oeil de Rad-requin)`,
      `composant (Queue de Rad-garou)`,
    ],
    description: `Force un seigneur démon à venir dans ce plan depuis le chaos magique qui règne entre les plans.`,
  },
  {
    cost: 8,
    name: `Ombre majeure`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.PLANS },
    ],
    requirements: [
      `relique (Ténèbres en bouteille)`,
      `composant (Griffe d'épouvantail de l'ombre)`,
      `composant (Cerveau d'écorcheur)`,
      `composant (Queue de Rad-garou)`,
    ],
    description: `Force une ombre majeure à venir dans ce plan depuis le plan des ombres.`,
  },
]);

export default data;
