import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Homme-rat`,

    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
    health: `80`,
    armor: `2`,
    speed: `4 m/s`,
    damage: `6 + 1x1d6`,
    hitClose: `40 %`,
    initiative: `+2`,
    skills: [
      {
        skill: 'Maîtrise',
        value: `65 %`
      },
    ]
  },
  {
    name: `Wido errant`,

    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
    health: `55`,
    armor: `1`,
    speed: `2 m/s`,
    damage: `9 + 3x1d6`,
    hitClose: `55 %`,
    attacks: [
      `Applique Radiations II`,
      `40% : applique Poison II pour 6 tours, type D`,
    ],
  },
  {
    name: `Vase collante`,

    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
    health: `25`,
    speed: `1 m/s`,
    abilities: [
      `Absorption : la vase s'enroule autour d'une personne et ne peut plus être ciblée, jusqu'à 5 des dégâts infligés à la personne sont infligés à la vase à la place, draine 20 de santé à la personne à la fin de chaque tour`,
    ],
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
  },
  {
    name: `Cyborg rouillé`,

    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
    health: `90`,
    armor: `4`,
    speed: `1 m/s`,
    damage: `4 + 2x1d8`,
    hitClose: `55 %`,
    attacks: [
      `Dégâts doublés contre les cyborgs`,
      `Provoque un dysfonctionnement de ce qui est électrique ou électronique`
    ],
  },
];

export default data;
