export default Object.freeze([
  [`Degré`, `Intervalle`],
  [`+1`, `24 heures`],
  [`+2`, `12 heures`],
  [`+3`, `6 heures`],
  [`+4`, `3 heures`],
  [`+5`, `90 minutes`],
  [`+6`, `45 minutes`],
  [`+7`, `20 minutes`],
  [`+8`, `10 minutes`],
  [`+9`, `5 minutes`],
  [`+10`, `2 minutes`],
  [`+11`, `1 minutes`],
  [`+12`, `30 secondes`],
  [`+13`, `15 secondes`],
  [`+14`, `5 secondes`],
]);
