import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Cultiste`,

    types: [Monsters.Type.HUMAN],
    health: `160`,
    armor: `2`,
    speed: `3 m/s`,
    hitClose: `90 %`,
    hitMedium: `70 %`,
    hitFar: `50 %`,
    initiative: `+4`,
    items: [
      `Arme de corps à corps simple ou exotique`,
      `Armure de tissu ou de cuir complète`
    ],
    skills: [
      {
        skill: `Perspicacité`,
        value: `0 %`
      },
      {
        skill: `Défenses`,
        value: `50 %`
      },
    ]
  },
  {
    name: `Pillard des terres dévastées`,

    types: [Monsters.Type.HUMAN],
    health: `150`,
    armor: `4`,
    speed: `3 m/s`,
    hitClose: `75 %`,
    hitMedium: `60 %`,
    hitFar: `35 %`,
    initiative: `+5`,
    items: [
      `Arme de corps à corps simple`,
      `Arme à distance simple`,
      `Armure de cuir complète`,
      `5% de chances d'avoir un véhicule`,
    ],
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
    skills: [
      {
        skill: `Social`,
        value: `60 %`
      },
      {
        skill: `Défenses`,
        value: `60 %`
      },
    ]
  },
  {
    name: `Dissident`,

    types: [Monsters.Type.HUMAN],
    health: `180`,
    armor: `4`,
    speed: `3 m/s`,
    hitClose: `60 %`,
    hitMedium: `75 %`,
    hitFar: `60 %`,
    initiative: `+2`,
    items: [
      `Arme légère simple ou exotique`,
      `Armure assistée partielle ou complète`
    ],
    skills: [
      {
        skill: `Social`,
        value: `50 %`
      },
      {
        skill: `Défenses`,
        value: `40 %`
      },
    ]
  },
  {
    name: `Récupérateur`,

    types: [Monsters.Type.HUMAN],
    health: `160`,
    armor: `3`,
    speed: `3 m/s`,
    hitClose: `70 %`,
    hitMedium: `65 %`,
    hitFar: `75 %`,
    items: [
      `Arme quelconque, simple ou exotique`,
      `Armure de tissu ou de cuir partielle ou complète`
    ],
    skills: [
      {
        skill: `Armes légères`,
        value: `50 %`
      },
      {
        skill: `Sciences`,
        value: `60 %`
      },
    ]
  },
];

export default data;
