export default Object.freeze({
  WEAPONS: [
    [`${CHARACTER.SKILLS.WEAPONS.LIGHT}`, `La précision lors d'attaques avec des armes légères`],
    [`${CHARACTER.SKILLS.WEAPONS.HEAVY}`, `La précision lors d'attaques avec des armes lourdes`],
    [`${CHARACTER.SKILLS.WEAPONS.MELEE}`, `La précision lors d'attaques avec des armes de corps-à-corps, des armes de poing ou à mains nues`],
    [`${CHARACTER.SKILLS.WEAPONS.THROWING}`, `La capacité à manipuler des mines et des pièges, à lancer des grenades et à crocheter des serrures`],
  ],
  KNOWLEDGE: [
    [`${CHARACTER.SKILLS.KNOWLEDGE.BIOLOGY}`, `Le savoir et savoir-faire en médecine, botanique et l'ensemble des sciences du vivant`],
    [`${CHARACTER.SKILLS.KNOWLEDGE.CULTURE}`, `Le niveau de connaissance générale, de l'histoire et la maîtrise des langues`],
    [`${CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING}`, `Le niveau dans les domaines de l'informatique, de l'électronique ou de la mécanique`],
    [`${CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL}`, `Le savoir en matière de survie en extérieur : pister, s'orienter, faire un feu, cuisiner, ...`],
  ],
  SOCIAL: [
    [`${CHARACTER.SKILLS.SOCIAL.EMPATHY}`, `La capacité à faire passer ou à percevoir un message par la gestuelle, la communication non-verbale et la perception des manipulations psychologiques`],
    [`${CHARACTER.SKILLS.SOCIAL.INTIMIDATION}`, `La capacité à effrayer un individu pour le forcer à agir précipitamment`],
    [`${CHARACTER.SKILLS.SOCIAL.NEGOTIATE}`, `La capacité à obtenir de bons prix lors du commerce, à négocier une libération d'otages ou une bonne place de parking`],
    [`${CHARACTER.SKILLS.SOCIAL.TALKING}`, `Aisance d'élocution qu'il s'agisse d'une personne en tête-à-tête ou à une foule, permet de manipuler quelqu'un ou de le remettre à sa place`],
  ],
  DEFENSES: [
    [`${CHARACTER.SKILLS.DEFENSES.FOCUS}`, `La capacité du personnage à rester concentré, par exemple lors de la lecture d'un livre dans une bibliothèque bruyante, ou lors de l'incantation d'un sort en pleine bagarre`],
    [`${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD}`, `La capacité du personnage à éviter de se faire dépasser par les événements qui l'entoure et de garder son calme`],
    [`${CHARACTER.SKILLS.DEFENSES.VIGOR}`, `La capacité à endurer des efforts sur la durée, à résister aux drogues ou aux effets biologiques`],
    [`${CHARACTER.SKILLS.DEFENSES.WILL}`, `La volonté du personnage dans des situations telles que la torture, le manque de sommeil ou le manque de drogue`],
  ],
  MASTERY: [
    [`${CHARACTER.SKILLS.MASTERY.ACUITY}`, `L'ensemble des sens du personnage: la vue, l'ouïe, le toucher, ...`],
    [`${CHARACTER.SKILLS.MASTERY.AGILITY}`, `La souplesse et les réflexes du personnage lors de situations d'esquives, de pilotage, de discrétion, ...`],
    [`${CHARACTER.SKILLS.MASTERY.STRENGTH}`, `La force physique du personnage qui détermine sa capacité à sprinter, porter des charges lourdes, grimper à une corde, ...`],
    [`${CHARACTER.SKILLS.MASTERY.SAGACITY}`, `La capacité du personnage à réfléchir et à raisonner`],
  ],
});
