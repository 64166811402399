import level1 from './level1';
import level2 from './level2';
import level5 from './level5';
import level10 from './level10';
import level15 from './level15';

const all = [
  ...level1,
  ...level2,
  ...level5,
  ...level10,
  ...level15,
];

export default all;
