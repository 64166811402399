import React from 'react';
import PropTypes from 'prop-types';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import styled from "@emotion/styled";

const MUIListStyled = styled(MUIList)(() => ({
  border: 'dotted 1px DarkSlateGray',
  borderRadius: 6,
  marginBottom: 16,
  overflow: 'hidden',
  padding: 0,
}));

const MUIListItemStyled = styled(MUIListItem)(() => ({
  borderBottom: 'dotted 1px DarkSlateGray',
  fontFamily: 'Luciole',
  fontSize: 14,

  '&:last-child': {
    borderBottom: 0,
  },
  '&:nth-of-type(even)': {
    background: '#F0F0F0',
  },
  '&:nth-of-type(odd)': {
    background: '#FAFAFA',
  },
}));

export function List({ data }) {
  return (
    <MUIListStyled>
      {data.map((element) => (
        <MUIListItemStyled key={element}>
          {element}
        </MUIListItemStyled>
      ))}
    </MUIListStyled>
  );
}

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};
