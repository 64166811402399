export default Object.freeze([
  [`1`, `Les muscles du joueur pourrissent`],
  [`2`, `Les os du joueur fondent`],
  [`3`, `Les organes internes du joueur fondent`],
  [`4`, `Un membre du corps se détache et tombe`],
  [`5`, `Le joueur vomit ses organes`],
  [`6`, `Une nouvelle tête pousse, test de localisation`],
  [`7`, `Un nouveau bras pousse, test de localisation`],
  [`8`, `Un tentacule pousse, test de localisation`],
  [`9`, `Mute en monstre, personnage injouable`],
  [`10`, `Mute en horreur, personnage injouable`],
]);
