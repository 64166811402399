const aptitudes = [
  {
    name: `Abondance magique`,
    effect: [`Réduit le coût en Magie par tour des canalisations de sort de 2`],
  },
  {
    name: `Acrobate`,
    effect: [`Une fois par tour, se relever est une action libre`],
  },
  {
    name: `Ambidextrie`,
    effect: [`Il n'y a plus de malus lors de l'utilisation de la main inhabituelle`],
  },
  {
    name: `Athlète`,
    effect: [
      `Double la vitesse de nage`,
      `Double la durée en apnée et la durée de course`,
      `Seuil de hauteur avant les dégâts lors d'une chute +2 mètres`,
    ],
  },
  {
    name: `Course féline`,
    effect: [
      `Double la vitesse de déplacement en Discrétion`,
      `Ne déclenche plus les mines et pièges au sol`,
    ],
  },
  {
    name: `Éducation`,
    effect: [`Octroie 15 points de compétences supplémentaires à dépenser`],
    usable: 3,
  },
  {
    name: `Finesse`,
    effect: [`Chances de succès critique lors des combats +5%`],
  },
  {
    name: `Fouine`,
    effect: [`Chercher un objet dans son sac est une action rapide`],
  },
  {
    name: `Incantation discrète`,
    effect: [`Les sorts ne nécessitent plus de composante gestuelle ou verbale`],
  },
  {
    name: `Introspection`,
    effect: [
      `Double l'expérience des succès critique pour les compétences et la magie`,
      `Les gains d'expérience de compétence peuvent désormais être dépensés dans une compétence au choix`,
      `Les gains d'expérience de sort peuvent désormais être dépensés dans un sort au choix`,
    ],
  },
  {
    name: `Magie tenace`,
    effect: [`Les sorts d'invocation réduisent la Magie de 2 points de moins`],
  },
  {
    name: `Moine`,
    effect: [`Il n'est plus possible d'être distrait lors d'une incantation`],
  },
  {
    name: `Négociateur`,
    requirements: [`Aptitude 'Commercial'`],
    effect: [`Augmente le prix des objets vendus de 75% environ`],
  },
  {
    name: `Nyctalope`,
    effect: [`Annule les malus liés à l'obscurité`],
  },
  {
    name: `Pyromane`,
    effect: [
      `Augmente le score du dé de blessures du degré de Brûlure sur la cible`,
      `Dépendance au feu`,
    ],
  },
  {
    name: `Résistance organique`,
    effect: [`Chances d'être dépendant réduites de 35%`],
  },
  {
    name: `Robustesse`,
    effect: [`Armure naturelle +2`],
  },
  {
    name: `Sadomasochiste`,
    effect: [
      `Augmente le score du dé de blessures du degré de Saignements sur la cible`,
      `Dépendance aux dégâts subit`,
    ],
  },
  {
    name: `Mentaliste`,
    effect: [
      `Lors de la phase de préparation en combat, il est possible de regarder l'action d'un autre participant afin de préparer la sienne`,
    ],
  },
  {
    name: `Séducteur diabolique`,
    effect: [`Rhétorique +50 envers les personnes de même race du sexe opposé`],
  },
]

export default aptitudes.map((a) => ({ ...a, level: 5 }));
