import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Kobold`,

    types: [Monsters.Type.ELEMENTAL],
    health: `220`,
    mana: `60`,
    armor: `20`,
    speed: `4 m/s`,
    damage: `48 + 6x1d8`,
    hitClose: `85 %`,
    initiative: `+2`,
    actions: `+1`,
    magic: [
      {
        school: `Magie du Feu`,
        level: `12`
      },
      {
        school: `Magie de la Terre`,
        level: `10`
      },
      {
        school: `Magie du Vent`,
        level: `4`
      }
    ],
    attacks: [
      `Applique Brûlure III pour 3 tours`
    ]
  }
];

export default data;
