import ammunitions from './ammunitions';
import explosives from './explosives';
import heavy from './heavy';
import light from './light';
import melee from './melee';

const all = [
  ...ammunitions,
  ...explosives,
  ...heavy,
  ...light,
  ...melee,
];

export default all;

export const Weapons = all;
