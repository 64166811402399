import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Chaxye`,

    types: [Monsters.Type.PLANT],
    health: `20`,
    abilities: [
      `"Nuage de poison": libère un vaste nuage qui a 80 % de chances d'appliquer Poison III pendant 30 tours (catégorie H)`
    ]
  },
  {
    name: `Lotus noir`,

    types: [Monsters.Type.PLANT],
    health: `10`,
    passives: [
      `"Nuage de poison" : à la mort, libère un nuage de poison qui a 80% de chances d'appliquer Poison IV pour 30 tours, type H`,
      Monsters.Skills.AuraRadioactive('IV'),
    ],
  },
  {
    name: `Arracheur de coeurs`,

    types: [Monsters.Type.PLANT],
    health: `60`,
    armor: `4`,
    speed: `2 m/s`,
    damage: `22 + 6x1d4`,
    hitClose: `50 %`,
    initiative: `+2`,
    attacks: [
      `65% de chances d'appliquer Saignements III pour 6 tours`,
    ],
    abilities: [
      `"Explosion de fumée": dégage une épaisse fumée verdâtre opaque`
    ],
    skills: [
      {
        skill: `Subtilité en forêt`,
        value: `150 %`
      },
    ]
  },
];

export default data;
