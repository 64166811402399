import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './GridDisplay.module.scss';

export function DataGridDisplay({
  data,
  mobileComponent,
}) {
  const Component = mobileComponent;
  return (
    <Grid container spacing={2} className={styles.data}>
      {data.map((datum) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={datum.id || datum.name}>
          <Component {...datum} />
        </Grid>
      ))}
    </Grid>
  );
}

DataGridDisplay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mobileComponent: PropTypes.func.isRequired,
};
