import React from 'react';

import { Image, Table } from 'components/semantic';
import { PageHeading } from 'components/elements';
import madnessesData from 'data/rules/madness'

const madnessDataFormatted = madnessesData.map((madness) => ([madness.left, madness.name, madness.description]));

export function MadnessPage() {
  return (
    <>
      <PageHeading>
        Folie
      </PageHeading>

      <Table data={madnessDataFormatted} headers={[`Dé`, `Nom`, `Effet`]} sizes={[1, 1, 3]}></Table>

      <Image size="large" src="/images/manual/other/madness.webp" />
    </ >
  );
}
