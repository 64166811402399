import PropTypes from 'prop-types';
import React from 'react';
import { motion } from 'framer-motion';

import styles from './PageTransitionAnimation.module.css';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.1,
};

export function PageTransitionAnimation({ children }) {
  return (
    <motion.div
      animate="in"
      className={styles.animatedPage}
      exit="out"
      initial="initial"
      transition={pageTransition}
      variants={pageVariants}
    >
      {children}
    </motion.div>
  );
}

PageTransitionAnimation.propTypes = {
  children: PropTypes.node.isRequired,
};
