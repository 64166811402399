import React from 'react';

import { List, Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function RangedPage() {
  return (
    <>
      <PageHeading>
        A distance
      </PageHeading>

      <Title>Armes à feu</Title>

      <Text>
        {`Quelques notions sont importantes à comprendre pour maîtriser le combat à distance.`}
      </Text>

      <Text>
        {`Premièrement, les armes disposent généralement d'une capacité : c'est le nombre de munitions que contient un chargeur entier.`}
      </Text>

      <Text>
        {`Ensuite, une valeur un peu abstraite est la “BPA”, abréviation de “Balles Par Action”. Il s'agit du nombre de balles qui sont tirées lors d'une action d'attaque avec cette arme.`}
      </Text>

      <Text>
        {`Donc si une arme a une capacité de 12 et un BPA de 6, il faut recharger après 2 actions d'attaque.`}
      </Text>

      <Text>
        {`Les dégâts d'une arme à distance sont considérés pour le nombre de balles tirées avec le score de BPA. Si moins de balles sont tirées, les dégâts seront moindres.`}
      </Text>

      <Subtitle>Tirer moins de balles</Subtitle>

      <Text>
        {`Cette action n'est réalisable qu'avec des armes qui ne sont ni automatiques ni semi-automatiques.`}
      </Text>

      <Text>
        {`Cela consiste à ne tirer que la moitié des balles que la cadence de l'arme permettrait. Faire ainsi permet de bénéficier de certains bonus sur l'action d'attaque.`}
      </Text>

      <List data={[`BPA réduits de moitié`, `Dégâts réduits de moitié`, `Toucher +30 %`, `Localisation gratuite`]}></List>

      <Subtitle>Viser</Subtitle>

      <Text>
        {`Viser avec une arme est une action rapide. Faire ainsi octroie des bonus pour la prochaine attaque :`}
      </Text>

      <List data={[`Toucher +15 %`, `Localisation gratuite`]}></List>

      <Text>
        {`Lors d'une action de visée, il n'est pas possible d'esquiver ou de parer une attaque. Si un événement perturbateur arrive durant cette action, il faut réussir un test de Concentration pour continuer à viser.`}
      </Text>

      <Title>Les explosifs</Title>

      <Text>
        {`Le combat avec des explosifs peut paraître un peu particulier, c'est une méthode de combat qui relève davantage du placement et de la tactique comparée aux autres. Voici quelques détails de règles.`}
      </Text>

      <Text>
        {`De manière générale, les mines ou grenades doivent être à portée de main pour être saisies et utilisées. Si elles se trouvent dans un sac, une sacoche ou une poche, une action pleine ou une action rapide peut être nécessaire afin de les récupérer. Le test principal pour ces actions se fait sous la compétence Lancer et Pièges.`}
      </Text>

      <Subtitle>Les mines et pieges</Subtitle>

      <Text>
        {`C'est ici le plus simple, il n'y a qu'à parvenir à amorcer son piège.`}
      </Text>

      <Text>
        {`Faire ainsi demande uniquement un test sous la compétence Lancer et Pièges.`}
      </Text>

      <Text>
        {`Sur un échec, le personnage peut très bien penser l'avoir amorcé avec succès alors que ce n'est pas le cas.`}
      </Text>

      <Subtitle>Grenades et armes de jet</Subtitle>

      <Text>
        {`Pour le lancer, qu'il s'agisse de grenades ou d'armes de jet, cela se passe avec un test de la même compétence : Lancer et Pièges.`}
      </Text>

      <Text>
        {`Il y a toutefois une petite particularité avec les grenades : le joueur peut décider de la dégoupiller un certain temps avant de la lancer, afin de tenter que l'explosion ait lieu exactement au contact de sa cible.`}
      </Text>

      <Text>
        {`Faire ainsi nécessite un test d'Intelligence supplémentaire avant le test de l'arme : attention aux échecs ! Si le joueur estime mal le temps avant l'explosion, cela peut très mal se terminer pour lui.`}
      </Text>

      <Subtitle>Le renvoi</Subtitle>

      <Text>
        {`Lorsqu'une grenade n'a pas été envoyée de sorte qu'elle explose directement au contact, elle peut être renvoyée à son lanceur d'origine !`}
      </Text>

      <Text>
        {`Premièrement, le joueur doit effectuer un test de ${CHARACTER.SKILLS.MASTERY.AGILITY} (ou alors avoir conservé une action rapide et s'être préparé à cette situation).`}
      </Text>

      <Text>
        {`Ensuite, deux méthodes sont possibles : soit tenter de la renvoyer à la main avec un test sous Lancer et Pièges également, ou alors frapper dedans avec un objet avec un test sous la compétence Corps-à-corps, et avec potentiellement des malus selon la situation.`}
      </Text>
    </>
  );
}
