import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Luminescent`,

    types: [Monsters.Type.MUTANT],
    health: `135`,
    armor: `5`,
    speed: `3 m/s`,
    damage: `30 + 4x1d6`,
    hitClose: `65 %`,
    initiative: `+3`,
    attacks: [
      `Applique Radiations IV`
    ],
    passives: [
      `Zone radioactive : les alentours continuent à appliquer Radiations III à la fin de chaque tour`
    ],
    abilities: [
      `Explosion de radiations : applique Radiations V aux personnes proches`
    ]
  },
  {
    name: `Mutant de siège`,

    types: [Monsters.Type.MUTANT],
    health: `140`,
    armor: `3`,
    speed: `3 m/s`,
    damage: `30 + 6x1d10`,
    hitClose: `55 %`,
    hitMedium: `75 %`,
    initiative: `+2`,
    attacks: [
      `Renverse la cible au sol`
    ]
  },
  {
    name: `Mutant XE-12`,

    types: [Monsters.Type.MUTANT],
    health: `155`,
    armor: `3`,
    speed: `4 m/s`,
    damage: `30 + 4x1d6`,
    hitClose: `75 %`,
    initiative: `+4`,
    abilities: [
      `Feindre la mort : seuil de décès à -50, redevient conscient au bout de 3 tours après chaque décès non-létal`
    ]
  },
  {
    name: `Couveuse`,

    types: [Monsters.Type.MUTANT],
    health: `170`,
    speed: `2 m/s`,
    damage: `24 + 6x1d6`,
    hitClose: `75 %`,
    attacks: [
      `Touche toutes les cibles sur 180° dans la direction attaquée (frappe de taille)`,
      `75 % : renverse au sol`
    ],
    passives: [
      `Récupération radioactive : soigne 30 blessures à la fin de chaque tour`
    ],
    abilities: [
      `Couvée : frappe une cible d'estoc et pond une larve dans son corps si l'attaque touche`,
      `Explosion de larves : provoque l'explosion d'une ou plusieurs larves proches, ce qui inflige 3x1d20 blessures et applique Saignements d'un rang de 1 + 1d4`
    ]
  },
  {
    name: `Mange-esprit`,

    types: [Monsters.Type.MUTANT],
    health: `25`,
    speed: `3 m/s`,
    attacks: [
      `Rend fou pendant 1 heure`,
      `Gagne un point de folie`
    ],
    passives: [Monsters.Skills.Group(`4 à 9 [3 + 1d6]`)],
  },
  {
    name: `Inquisiteur des profondeurs`,

    types: [Monsters.Type.MUTANT],
    health: `140`,
    armor: `4`,
    speed: `2 m/s`,
    damage: `40 + 6x1d4`,
    hitClose: `70 %`,
    initiative: `+2`,
    attacks: [
      `80% : applique Saignements II permanent`,
      `60% : rend fou pendant 10 minutes`,
      `40% : gagne un point de folie`,
      `20% : transmets la Putride`,
    ],
    show: [
      `Gagne un point de folie`,
      `60 % : rend fou pendant 10 minutes`,
    ]
  },
];

export default data;
