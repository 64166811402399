import React from 'react';
import { Outlet } from 'react-router-dom';

import { PageTransitionAnimation } from 'layout/animations/PageTransitionAnimation';

export function PageRootLayout() {
  return (
    <PageTransitionAnimation>
      <Outlet />
    </PageTransitionAnimation>
  );
}
