import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import replaceAccentedCharacters from 'lib/normalizeString';

const InnerTitle = styled.h2`
  animation: irradiatedPulse 3s infinite;
  background-color: DarkSlateGray;
  border-radius: 8px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  color: transparent;
  font-family: DemonSker;
  font-size: 1.4em;
  font-weight: 400;
  letter-spacing: 0.2em;
  margin-bottom: 12px;
  margin-top: 12px;
  padding-bottom: 6px;
  padding-top: 8px;
`;

export function Title({
  children,
}) {
  return <InnerTitle>{replaceAccentedCharacters(children)}</InnerTitle>;
}

Title.propTypes = {
  children: PropTypes.string.isRequired,
};
