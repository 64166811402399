import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import './CardPin.scss';

export function CardPin({
  children,
  placement,
  tooltip,
}) {
  if (tooltip) {
    return (
      <Tooltip
        enterTouchDelay={50}
        leaveTouchDelay={1500}
        placement="top"
        title={tooltip}
      >
        <Box className={`pin pin-${placement}`}>
          {children}
        </Box>
      </Tooltip>
    );
  }

  return (
    <Box className={`pin pin-${placement}`}>
      {children}
    </Box>
  );
}

CardPin.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['default', 'left', 'right']),
  tooltip: PropTypes.string,
};

CardPin.defaultProps = {
  placement: 'default',
  tooltip: '',
};
