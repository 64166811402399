import drink from './drink';
import food from './food';
import medical from './medical';
import utilities from './utilities';
import miscellaneous from './miscellaneous';

const all = [
  ...drink,
  ...food,
  ...medical,
  ...miscellaneous,
  ...utilities,
];

export default all;

export const Items = all;
