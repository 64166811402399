import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Politicien`,

    types: [Monsters.Type.HUMAN],
    health: `120`,
    armor: `2`,
    speed: `3 m/s`,
    hitClose: `100 %`,
    items: [
      `Arme de corps-à-corps exotique`,
      `Arme en tissu ou en cuir complète`,
    ],
    skills: [
      {
        skill: `Social`,
        value: `140 %`
      },
      {
        skill: `Défenses`,
        value: `90 %`
      }
    ]
  },
  {
    name: `Vétéran entraîné`,

    types: [Monsters.Type.HUMAN],
    health: `180`,
    armor: `10`,
    speed: `3 m/s`,
    hitClose: `110 %`,
    hitMedium: `110 %`,
    hitFar: `110 %`,
    initiative: `+6`,
    items: [
      `Arme lourde exotique`,
      `Arme légère exotique`,
      `Armure assistée exotique`
    ],
    skills: [
      {
        skill: `Armes`,
        value: `110 %`
      },
      {
        skill: `Sciences`,
        value: `75 %`
      },
      {
        skill: `Défenses`,
        value: `80 %`
      },
      {
        skill: `Maîtrise`,
        value: `90 %`
      }
    ]
  },
  {
    name: `Rôdeur solitaire`,

    types: [Monsters.Type.HUMAN],
    health: `140`,
    armor: `4`,
    speed: `3 m/s`,
    hitClose: `130 %`,
    hitMedium: `130 %`,
    initiative: `+12`,
    actions: `+2`,
    items: [
      `Arme de corps-à-corps exotique x2`,
      `Arme de jet exotique`,
      `Armure en cuir exotique`
    ],
    attacks: [
      `Applique poisons ou maladies`
    ],
    skills: [
      {
        skill: `Armes`,
        value: `130 %`
      },
      {
        skill: `Relationnel`,
        value: `80 %`
      },
      {
        skill: `Maîtrise`,
        value: `110 %`
      }
    ]
  },
];

export default data;
