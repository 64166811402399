export const Type = Object.freeze({
  Item: {
    ARMOR: 0,
    GENERAL: 1,
    WEAPON: 2,
  },
  Mob: {
    BEAST: 0,
    ELEMENTARY: 1,
    HUMANOID: 2,
    MUTANT: 3,
    PLANT: 4,
    ROBOT: 5,
  },
  Rule: {
    CHARACTER: 0,
    ENVIRONMENT: 1,
    FIGHT: 2,
    MADNESSES: 3,
    MISC: 4,
    SPELLS: 5,
  },
});
