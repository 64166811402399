import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';

import { usePrevious } from 'utils/usePrevious';

let destination = null;

export function UniverseMenu() {
  const navigate = useNavigate();

  const [anchors, setAnchors] = useState({ history: false, location: false, knowledge: false });
  const previousHistory = usePrevious(anchors.history);
  const previousLocation = usePrevious(anchors.location);
  const previousKnowledge = usePrevious(anchors.knowledge);
  const historyOpened = Boolean(anchors.history);
  const locationOpened = Boolean(anchors.location);
  const knowledgeOpened = Boolean(anchors.knowledge);

  const handleOpenHistory = (event) => {
    setAnchors((anchors) => ({ ...anchors, history: event.currentTarget }));
  };
  const handleOpenLocation = (event) => {
    setAnchors((anchors) => ({ ...anchors, location: event.currentTarget }));
  };
  const handleOpenKnowledge = (event) => {
    setAnchors((anchors) => ({ ...anchors, knowledge: event.currentTarget }));
  };

  const handleCloseAll = () => {
    setAnchors({ history: null, location: null, knowledge: null });
  };

  const navigateTo = (target) => {
    destination = target;
    handleCloseAll();
  };

  /* Delaying navigation so the menu can close, avoiding the "lagging" effect */
  useEffect(() => {
    if (!anchors.history && previousHistory && destination) {
      navigate(destination);
      destination = null;
    }
    if (!anchors.location && previousLocation && destination) {
      navigate(destination);
      destination = null;
    }
    if (!anchors.knowledge && previousKnowledge && destination) {
      navigate(destination);
      destination = null;
    }
  }, [navigate, anchors.history, anchors.location, anchors.knowledge, previousHistory, previousLocation, previousKnowledge]);

  return (
    <>
      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'warning']} />}
        to="/universe"
      >
        Attention
      </Button>

      <Button
        endIcon={<FontAwesomeIcon icon={['fa', 'book-bookmark']} />}
        onClick={handleOpenHistory}
      >
        Histoire
      </Button>

      <Menu
        anchorEl={anchors.history}
        open={historyOpened}
        onClose={handleCloseAll}
        className="header__menu"
      >
        <MenuItem onClick={() => navigateTo('/manual/character/creation')}>
          <FontAwesomeIcon icon={['fa', 'plane-arrival']} />
          {`L'effondrement`}
        </MenuItem>
      </Menu>

      <Button
        endIcon={<FontAwesomeIcon icon={['fa', 'book-atlas']} />}
        onClick={handleOpenLocation}
      >
        Lieux
      </Button>

      <Menu
        anchorEl={anchors.location}
        open={locationOpened}
        onClose={handleCloseAll}
        className="header__menu"
      >
        <MenuItem onClick={() => navigateTo('/manual/fight/round')}>
          <FontAwesomeIcon icon={['fa', 'earth-americas']} />
          Amerique
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/round')}>
          <FontAwesomeIcon icon={['fa', 'earth-africa']} />
          Afrique
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/round')}>
          <FontAwesomeIcon icon={['fa', 'earth-europe']} />
          Europe
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/round')}>
          <FontAwesomeIcon icon={['fa', 'earth-asia']} />
          Asie
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/round')}>
          <FontAwesomeIcon icon={['fa', 'earth-oceania']} />
          Oceanie
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/fight/round')}>
          <FontAwesomeIcon icon={['fa', 'globe']} />
          Les plans
        </MenuItem>
      </Menu>

      <Button
        endIcon={<FontAwesomeIcon icon={['fa', 'book']} />}
        onClick={handleOpenKnowledge}
      >
        Savoirs
      </Button>

      <Menu
        anchorEl={anchors.knowledge}
        open={knowledgeOpened}
        onClose={handleCloseAll}
        className="header__menu"
      >
        <MenuItem onClick={() => navigateTo('/manual/other/environment')}>
          <FontAwesomeIcon icon={['fa', 'robot']} />
          Les races
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/other/environment')}>
          <FontAwesomeIcon icon={['fa', 'yin-yang']} />
          Les religions
        </MenuItem>

        <MenuItem onClick={() => navigateTo('/manual/other/environment')}>
          <FontAwesomeIcon icon={['fa', 'wand-sparkles']} />
          La magie
        </MenuItem>
      </Menu>

    </>
  );
}
