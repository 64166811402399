import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import replaceAccentedCharacters from 'lib/normalizeString';

import styles from './PageHeading.module.scss';

export function PageHeading({
  backButton = '',
  children,
}) {
  const location = useLocation();
  const text = replaceAccentedCharacters(children);

  return (
    <Box component="h1" className={styles.titleBox}>
      <Box className={styles.sideLeft}>
        {location.pathname !== '/' && !backButton && (
          <Link to="/">
            <FontAwesomeIcon icon={['fa', 'house-chimney-crack']} />
          </Link>
        )}
        {location.pathname !== '/' && backButton}
      </Box>

      <Box className={styles.text}>
        {text}
      </Box>

      <Box className={styles.sideRight}>
        {' '}
      </Box>
    </Box>
  );
}

PageHeading.propTypes = {
  backButton: PropTypes.node,
  children: PropTypes.string.isRequired,
};

PageHeading.defaultProps = {
  backButton: ``,
};
