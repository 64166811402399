import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    cost: 2,
    name: `Berceuse`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.DEFENSES.WILL} -25 face au sommeil`,
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} -15 (écoute)`,
      `Effet : ${CHARACTER.SKILLS.DEFENSES.FOCUS} -15`,
      `Surcharge : endort la cible`,
    ],
    description: `Façonne le vent près d'une personne afin de lui faire entendre une berceuse, ce qui va la rendre somnolente.`,
  },
  {
    cost: 2,
    name: `Brise tranchante`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère venteuse)`,
    ],
    additionalEffects: [
      `Surcharge : inflige 7 + 1d4 de blessures tranchants`,
    ],
    description: `Une légère brise tellement fine qu'elle est capable de petites coupures. Pratique pour ouvrir une lettre ou sectionner une bourse.`,
  },
  {
    cost: 2,
    name: `Coup de vent`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    additionalEffects: [
      `Surcharge : le vent devient soudain et permet de désarmer une cible`,
    ],
    description: `Effectue quelques poussées dans l'air afin d'ouvrir ou fermer une porte ou une fenêtre.`,
  },
  {
    cost: 2,
    name: `Décharge électrostatique`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère orageuse)`,
    ],
    additionalEffects: [
      `Effet : désactive les appareils électroniques pour 1 tour`,
      `Surcharge : désactive les appareils électroniques pour 12 tours (1 minute)`,
    ],
    description: `Crée une légère décharge, assez puissante pour mettre hors d'état de nuire des circuits électroniques.`,
  },
  {
    cost: 2,
    name: `Étouffer les sons`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `contact`,
    ],
    additionalEffects: [
      `Attention : canaliser un sort ne permet pas de se déplacer naturellement`,
      `Surcharge : plus aucun bruit n'est transporté dans la zone environnante, l'effet est très perturbant`,
    ],
    description: `Réduit les bruits produits par les pas d'une personne.`,
  },
  {
    cost: 2,
    name: `Porté par le vent`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    additionalEffects: [
      `Surcharge : permet de ramener un objet distant à portée de main`,
    ],
    range: `10m`,
    description: `Transporte les odeurs, gaz ou poisons dans l'air vers une personne ou un lieu.`,
  },
  {
    cost: 2,
    name: `Sons illusoires`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    additionalEffects: [
      `Surcharge : assourdi la cible, ${CHARACTER.SKILLS.MASTERY.ACUITY} -30 (écoute)`,
    ],
    range: `5 m`,
    description: `Permet de chuchoter des messages à l'oreille d'une personne à condition de canaliser le sort assez longtemps.`,
  },
  {
    cost: 1,
    name: `Sphère venteuse ou orageuse`,
    types: [
      Spells.Types.SUMMON,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    description: `Invoque magiquement une petite boule de vent ou d'orage à proximité. Cette dernière reste statique et sert de composant pour lancer certains sorts.`,
  },
  {
    cost: 4,
    name: `Bulle de vide`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    area: `1 m²`,
    description: `Crée une zone de vide autour d'une personne. En l'absence totale d'air, cette dernière va finir par suffoquer si elle nécessite de l'oxygène pour vivre.`,
  },
  {
    cost: 4,
    name: `Bulle d'air`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère venteuse)`,
    ],
    description: `Crée une zone d'air autour d'une personne, ce qui protège des autres fluides. Utile pour se protéger temporairement des gaz et poisons aéroportés ou pour respirer sous l'eau.`,
  },
  {
    cost: 4,
    name: `Charge électrique`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère orageuse)`,
    ],
    description: `Charge un objet positivement ou négativement. Ce dernier sera attiré par les autres objets qui sont de même charge sera repoussé par les objets de charge inverse.`,
  },
  {
    cost: 4,
    name: `Chute lente`,
    types: [
      Spells.Types.CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `relique (plume)`,
      `composant (sphère venteuse)`,
    ],
    additionalEffects: [
      `Effet : dégâts de chute ÷ 2`,
      `Surcharge : dégâts de chute ÷ 3`,
    ],
    description: `Augmente la résistance de l'air afin d'accroître la force de frottement et de réduire une chute.`,
  },
  {
    cost: 4,
    name: `Explosion sonique`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    area: `10 m²`,
    additionalEffects: [
      `Effet : ${CHARACTER.SKILLS.MASTERY.ACUITY} -30 (écoute) pour 3 tours`,
      `Surcharge : ${CHARACTER.SKILLS.MASTERY.ACUITY} -50 (écoute) pour 12 tours (1 minute)`,
    ],
    description: `Crée une gigantesque explosion sonique dont la puissance peut briser les vitres.`,
  },
  {
    cost: 4,
    name: `Lévitation`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `contact`,
      `relique (plume)`,
      `composant (sphère venteuse)`,
    ],
    additionalEffects: [
      `Surcharge : permet de faire voler la cible sur plusieurs mètres de hauteur, attention à la chute !`,
    ],
    description: `Fait légèrement léviter la personne, ce qui lui évite de faire du bruit en marchant ainsi que de déclencher les pièges et mines au sol. Cependant la cible ne peut plus se déplacer d'elle-même.`,
  },
  {
    cost: 4,
    name: `Sirocco`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère venteuse)`,
    ],
    additionalEffects: [
      `Effet : renverse une personne au sol`,
      `Surcharge : après un test de Vigueur échoué, la personne tombe inconsciente pour 1d4 tours`,
    ],
    description: `Provoque une violente bourrasque de vent sec et chaud vers une personne, ce qui va la renverser au sol.`,
  },
  {
    cost: 8,
    name: `Arcs électriques`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère orageuse)`,
    ],
    additionalEffects: [
      `Effet : le lanceur subit la moitié du montant des blessures sur ses mains`,
      `Effet : les blessures sont augmentées à tour de canalisation (4 à la première incantation, 8 après un tour de canalisation, puis 12, ...)`,
      `Surcharge : les blessures sont doublées à chaque tour de canalisation à la place (commence à 4, puis 8, puis 16, ...)`,
    ],
    damage: `4`,
    description: `Des arcs électriques jaillissent des mains du lanceur vers jusqu'à 3 personnes qui seront brûlés par l'électrocution. Chaque tour de canalisation augmente l'effet des blessures.`,
  },
  {
    cost: 8,
    name: `Cartographie de la pièce`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère venteuse)`,
    ],
    area: `4 m³`,
    description: `Crée des courants aériens dont la présence est connectée, ce qui permet d'explorer les moindres recoins d'une pièce et de détecter l'existence de passages secrets.`,
  },
  {
    cost: 8,
    name: `Échos du passé`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `contact`,
      `lieu clos`,
    ],
    description: `Amplifie des sons infiniment petits afin de les rendre audibles de nouveau et d'entendre une scène du passé dans un endroit clos, pour savoir ce qui s'y est déroulé.`,
  },
  {
    cost: 8,
    name: `Électrocution`,
    types: [
      Spells.Types.CAST,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère orageuse)`,
    ],
    additionalEffects: [
      `Effet : 50% de chances de paralyser la personne pour 1 tour`,
      `Surcharge : augmente les blessures à 10 + 1d10 et paralyse pendant 3 tours`,
    ],
    damage: `6 + 1d6`,
    description: `Charge la pression atmosphérique et la température de l'air autour d'une personne afin de la prendre dans une intense décharge électrique.`,
  },
  {
    cost: 8,
    name: `Orage`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère orageuse)`,
    ],
    additionalEffects: [
      `Surcharge : augmente les blessures à 10 + 1d10`,
    ],
    area: `10 m²`,
    damage: `6 + 1d6`,
    description: `Invoque un orage dans la zone alentour, ce qui perturbe les appareils électroniques proches et peut frapper par la foudre les personnes dans les environs.`,
  },
  {
    cost: 8,
    name: `Ralentissement des projectiles`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère venteuse)`,
    ],
    area: `10 m²`,
    additionalEffects: [
      `Effet : vitesse des projectiles ÷ 2`,
      `Surcharge : vitesse des projectiles ÷ 10 à la place`,
    ],
    description: `Créé une zone alentour qui ralentit les projectiles à l'intérieur.`,
  },
  {
    cost: 8,
    name: `Tornade`,
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
    schools: [
      { name: Spells.School.WIND_THUNDER },
    ],
    requirements: [
      `composant (sphère venteuse)`,
    ],
    additionalEffects: [
      `Surcharge : double la hauteur et la puissance de la tornade`,
    ],
    high: `8 mètres`,
    description: `Invoque magiquement une petite tornade qui a une chance d'emporter les personnes à l'intérieur.`,
  },
]);

export default data;
