import styled from "@emotion/styled";

const Text = styled.p`
  margin-bottom: 8px;
  margin-top: 8px;
  text-align: justify;
  font-family: Luciole;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
`;

export { Text };
