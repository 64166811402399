import { Monsters } from 'data/metadata';

const data = [
  {
    name: `Avatar de la radioactivité`,

    types: [Monsters.Type.MUTANT],
    health: `160`,
    armor: `4`,
    speed: `3 m/s`,
    damage: `48 + 6x1d8`,
    hitClose: `90 %`,
    initiative: `+6`,
    attacks: [
      `Applique Radiations V`
    ],
    show: [
      `40% : effraie les personnes proches`
    ],
    passives: [
      Monsters.Skills.RégénérationRadioactive,
      Monsters.Skills.AuraRadioactive('V'),
    ],
    death: [`L'aura radioactive continue à faire effet`]
  },
  {
    name: `Flotteur de radiations`,

    types: [Monsters.Type.MUTANT],
    health: `20`,
    speed: `4 m/s`,
    abilities: [
      `Vol : vole dans le ciel en haute altitude, difficile à détecter`,
    ],
    death: [
      `Pluie radioactive : applique Radiations V à la fin de chaque tour pendant 3 tours`
    ],
    passives: [Monsters.Skills.AuraRadioactive('III')]
  },
  {
    name: `Reine fourmi`,

    types: [Monsters.Type.MUTANT],
    health: `250`,
    armor: `16`,
    speed: `3 m/s`,
    damage: `60 + 8x1d6`,
    hitClose: `90 %`,
    hitMedium: `60 %, ignore l'armure`,
    initiative: `+4`,
    attacks: [
      `Applique Poison V pour 30 tours, type D+`
    ],
    passives: [
      `Gestation : peu après la mort ou lors de blessures infligées dans le ventre, libère 1d4 monstres « Fourmi géante », ne fonctionne qu'une seule fois`
    ]
  },
  {
    name: `Roi des centaures`,

    types: [Monsters.Type.MUTANT],
    health: `205`,
    armor: `6`,
    speed: `2 m/s`,
    damage: `30 + 5x1d6`,
    hitClose: `110 %`,
    hitMedium: `80 %`,
    hitFar: `65 %`,
    initiative: `+4`,
    attacks: [
      `Réduit l'Initiative de la cible de 1 pendant 6 tours`,
      `Applique Radiations V et laisse une flaque au sol qui applique Radiations III par tour pendant 12 tours`
    ],
    passives: [
      `Groupe: se trouve généralement avec un groupe de 4 à 9 Centaures [3 + 1d6]`,
      Monsters.Skills.Chef('Centaures', 6),
    ],
    death: [
      `Provoque une explosion de gelée qui applique Radiations V aux individus dans un rayon de 3 mètres`,
    ]
  },
  {
    name: `Goule cellulaire`,

    types: [Monsters.Type.MUTANT],
    health: `150`,
    armor: `2`,
    speed: `4 m/s`,
    damage: `30 + 6x1d6`,
    criticalChances: `+3 %`,
    hitClose: `100 %`,
    initiative: `+4`,
    attacks: [
      `Applique Radiations III`
    ],
    passives: [
      `Récupération radioactive: soigne 50 blessures à la fin de chaque tour`,
      `Gestation: peu après la mort ou lors de blessures infligées dans le ventre, libère 1d4 monstres « Parasite », ne fonctionne qu'une seule fois`
    ],
  },
  {
    name: `Mange-mort`,

    types: [Monsters.Type.MUTANT],
    health: `140`,
    armor: `25 dans le dos`,
    speed: `6 m/s`,
    damage: `40 + 8x1d4`,
    hitClose: `95 %`,
    initiative: `+7`,
    attacks: [
      `Applique Saignements IV permanent`
    ],
    abilities: [
      `Odeur putride : les personnes proches tombent inconscientes si elles échouent un test de Volonté`
    ]
  },
  {
    name: `Déchiqueteur`,

    types: [Monsters.Type.MUTANT],
    health: `80`,
    speed: `3 m/s`,
    damage: `40 + 10x1d4`,
    criticalChances: `+3 %`,
    hitClose: `80 %`,
    initiative: `+6`,
    attacks: [
      `Applique Saignements V permanent`
    ],
    show: [
      `Gagne un point de folie`
    ]
  },
  {
    name: `Behemoth super-mutant`,

    types: [Monsters.Type.MUTANT],
    health: `850`,
    armor: `12`,
    speed: `5 m/s`,
    damage: `80 + 5x1d10`,
    hitClose: `55 %`,
    hitMedium: `20 %`,
    items: [
      `Grosse arme quelconque de fortune`,
      `Grosse armure de fortune partielle ou complète`
    ],
    show: [
      `80 % : effraie les individus proches`,
    ],
    abilities: [
      Monsters.Skills.Tremblement,
    ]
  },
];

export default data;
